import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { InviteStatus, Role } from "@pentacode/core/src/model";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import type { PermissionsForm } from "./permissions-form";
import "./permissions-form";
import { alert, confirm } from "./alert-dialog";
import { InviteDialogSingle } from "./invite-dialog-single";
import "./spinner";

@customElement("ptc-employees-permissions-single")
export class EmployeesPermissionsSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<id>\d+)\/permissions/;

    get routeTitle() {
        return `Zugänge & Rechte: ${this._employee && this._employee.name}`;
    }

    get _hasChanged() {
        return this._form?.hasChanged;
    }

    @query("ptc-permissions-form")
    private _form: PermissionsForm;

    @routeProperty({ arg: "id", type: Number })
    private _employeeId: number;

    @state()
    private _loading = false;

    @singleton("ptc-invite-dialog-single")
    private _inviteDialog: InviteDialogSingle;

    private get _employee() {
        return (this._employeeId && app.getEmployee(this._employeeId)) || null;
    }

    private async _sendInvite() {
        const sent = await this._inviteDialog.show(this._employee!);
        if (sent) {
            // update in the background
            void app.fetchCompany();
        }
    }

    private async _submit() {
        this._loading = true;
        try {
            const { filters, permissions, ...rest } = this._form.data;
            await app.updateEmployee(
                this._employee!,
                {
                    ...rest,
                    access: {
                        filters,
                        permissions,
                    },
                },
                true
            );
            this.requestUpdate("_employeeId");
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    private async _revokeAccess() {
        if (
            !(await confirm(
                "Sind Sie Sicher dass Sie dem Mitarbeiter den Zugang zu Ihrem Unternehmen entziehen wollen?",
                "Zugang Entziehen",
                "Abbrechen",
                { title: "Zugang Entziehen", type: "destructive", icon: "ban" }
            ))
        ) {
            return;
        }

        this._loading = true;
        try {
            await app.updateEmployee(this._employee!, { revokeAccess: true }, true);
            this.requestUpdate("_employeeId");
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    updated(changes: Map<string, unknown>) {
        if ((!changes.has("_employeeId") && !changes.has("active")) || !this._form || !this._employee) {
            return;
        }

        this._resetPermissions();
    }

    private _resetPermissions() {
        this._form.filters = this._employee?.access?.filters || [];
        this._form.permissions = this._employee?.access?.permissions || [];
        this._form.accessRole = this._employee!.role;
        this._form.requestUpdate("filters");
        this._form.requestUpdate("permissions");
        this._form.requestUpdate("accessRole");
        this.requestUpdate();
    }

    static styles = [shared, css``];

    render() {
        if (!this._employee) {
            return;
        }
        const emp = this._employee;
        return html`
            ${!!emp.accountId || emp.invite?.status === "sent"
                ? html`
                      <div class="fullbleed vertical layout">
                          ${!emp.invite
                              ? ""
                              : emp.invite.status === InviteStatus.DeliveryFailed
                                ? html`
                                      <div class="margined red padded box spacing start-aligning horizontal layout">
                                          <i class="big exclamation-triangle"></i>
                                          <div class="stretch">
                                              Die an die Emailadresse <strong>${emp.email}</strong> versandte Einladung
                                              konnte leider nicht zugestellt werden. Bitte überprüfen Sie die
                                              Emailadresse des Mitarbeiters und versuchen Sie es erneut.
                                          </div>
                                          <button class="small subtle" @click=${this._sendInvite}>
                                              <i class="envelope"></i> Erneut Versenden
                                          </button>
                                      </div>
                                  `
                                : emp.invite.status === InviteStatus.Created || emp.invite.status === InviteStatus.Sent
                                  ? html`
                                        <div
                                            class="margined orange padded box spacing start-aligning horizontal layout"
                                        >
                                            <i class="big envelope"></i>
                                            <div class="stretch">
                                                Es wurde eine Einladung an die Emailadresse
                                                <strong>${emp.email}</strong> versandt. Sie können die Berechtigungen
                                                des Mitarbeiters bereits jetzt bearbeiten - Ihre Einstellungen finden
                                                Anwendung sobald der Mitarbeiter seinen Zugang eingerichtet hat.
                                            </div>
                                            <button class="small subtle" @click=${this._sendInvite}>
                                                <i class="envelope"></i> Erneut Versenden
                                            </button>
                                        </div>
                                    `
                                  : ""}

                          <ptc-permissions-form
                              class="stretch"
                              ?readonly=${this._employee?.role <= Role.Owner || this._employee?.id === app.profile?.id}
                              @change=${() => this.requestUpdate()}
                              @revoke-access=${this._revokeAccess}
                          ></ptc-permissions-form>

                          <div>
                              <div
                                  class="horizontal padded evenly stretching spacing layout"
                                  ?hidden=${!this._hasChanged}
                                  style="max-width: 50em; margin: 0 auto;"
                              >
                                  <button class="primary" @click=${this._submit}>Speichern</button>
                                  <button class="transparent" type="button" @click=${this._resetPermissions}>
                                      Abbrechen
                                  </button>
                              </div>
                          </div>
                      </div>
                  `
                : html`
                      <div class="fullbleed vertical centering layout">
                          <div class="double-margined">Dieser Mitarbeiter hat noch keinen Pentacode-Account.</div>
                          <button class="primary" @click=${this._sendInvite}>
                              <i class="envelope"></i> Einladung Versenden
                          </button>
                      </div>
                  `}

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
