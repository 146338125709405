import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { Issue, IssueType, getIssueMessage } from "@pentacode/core/src/issues";
import { parseDateString, monthNames, formatDate, toDateString, getRange } from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { app } from "../init";
import { shared, mixins } from "../styles";
import { Checkbox } from "./checkbox";
import { alert, confirm } from "./alert-dialog";
import "./spinner";
import "./issue";
import { GetIssuesParams, IgnoreIssuesParams } from "@pentacode/core";

interface IssueMonth {
    year: number;
    month: number;
    days: IssueDay[];
}

interface IssueDay {
    date: string;
    issues: Issue[];
}

const weekDays = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];

@customElement("ptc-issues")
export class Issues extends Routing(StateMixin(LitElement)) {
    routePattern = /^issues/;

    get routeTitle() {
        return "Problembericht";
    }

    get helpPage() {
        return "/handbuch";
    }

    @query(".main")
    private _main: HTMLDivElement;

    @query("#filterForm")
    private _filterForm: HTMLFormElement;

    @state()
    private _currMonth: string;

    @state()
    private _issues: Issue[] = [];

    @state()
    private _loading = false;

    private get _filteredTypes() {
        return this._filterForm && new FormData(this._filterForm).getAll("types").map((t: string) => Number(t));
    }

    handleRoute() {
        void this._load();

        setTimeout(() => this._scroll(), 500);
    }

    updated() {
        this._scroll();
    }

    private async _load() {
        this._loading = true;

        try {
            this._issues = await app.api.getIssues(new GetIssuesParams({ includeIgnored: false }));
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _scroll() {
        if (!this._main) {
            return;
        }
        const { top, left, width } = this._main.getBoundingClientRect();
        const month = this.shadowRoot!.elementsFromPoint(left + width / 2, top + 30).find(
            (el: HTMLElement) => !!el.dataset.month
        );
        this._currMonth = (month && (month as HTMLElement).dataset.month) || "";
    }

    private _scrollToMonth(m: IssueMonth) {
        const month = this.renderRoot.querySelector(`[data-month="${m.year}-${m.month}"]`) as HTMLElement;
        month && month.scrollIntoView();
    }

    private async _ignoreIssues(issues: Issue[]) {
        const confirmed = await confirm(
            issues.length > 1
                ? "Wollen Sie diese Probleme wirklich verwerfen?"
                : "Wollen Sie dieses Problem wirklich verwerfen?",
            issues.length > 1 ? `${issues.length} Probleme Vewerfen` : "Problem Verwerfen",
            "Abbrechen",
            { title: issues.length > 1 ? "Probleme Verwerfen" : "Problem Verwerfen", icon: "eye-slash" }
        );

        if (confirmed) {
            await app.api.ignoreIssues(new IgnoreIssuesParams({ issues }));
            await this._load();
        }
    }

    private _goToIssue(issue: Issue) {
        switch (issue.type) {
            case IssueType.UnbalancedDailyCash:
            case IssueType.PendingDailyRevenues:
                this.go(`revenues/daily`, { venue: issue.details.venueId, date: issue.date });
                break;
            default: {
                const now = toDateString(new Date());
                if (issue.date > now) {
                    this.go("roster", getRange(issue.date, "week"));
                } else {
                    this.go(`employees/${issue.details.employeeId}/time`, getRange(issue.date, "month"));
                }
            }
        }
    }

    static styles = [
        shared,
        Checkbox.styles,
        css`
            :host {
                position: relative;
                display: flex;
            }

            .sub-menu {
                width: 15em;
                ${mixins.scroll()};
            }

            .issue-count {
                border-radius: calc(2 * var(--border-radius));
                color: var(--red);
                margin-bottom: 0.5em;
                border: solid 1px;
                font-size: var(--font-size-small);
                --color-highlight: var(--red);
                --color-highlight-bg: var(--red-bg);
            }

            .issue-count button {
                padding: 0.5em;
            }

            .main {
                ${mixins.scroll()};
                flex: 1;
                padding-bottom: 100%;
            }

            .month {
                position: relative;
                margin-bottom: 1em;
                padding: 0.5em;
            }

            .month-header {
                position: sticky;
                top: 0;
                background: var(--color-bg);
                font-size: var(--font-size-huge);
                z-index: 1;
                border-radius: calc(2 * var(--border-radius));
                padding: 0.5em;
                color: var(--color-primary);
                font-weight: 300;
            }

            .day {
                position: relative;
            }

            .day-header {
                font-size: var(--font-size-large);
                padding: 0.5em 0.8em;
                /*
                background: var(--color-bg);
                z-index: 1;
                border-radius: calc(2 * var(--border-radius));
                position: sticky;
                top: 2.6em;
                */
            }

            ptc-issue {
                margin: 0 0.5em;
                border-radius: calc(2 * var(--border-radius));
            }

            .placeholder {
                color: var(--color-highlight);
            }

            .placeholder i {
                font-size: 5rem;
            }

            .placeholder .msg {
                font-size: var(--font-size-large);
                width: 300px;
                text-align: center;
            }

            .issue-button {
                border: solid 1px;
                margin-left: 0.5em;
                padding: 0.6em;
            }

            .issue-button.ignore {
                color: var(--color-negative);
            }

            .issue-button.solve {
                color: var(--color-primary);
            }

            .issue:not(:hover) .issue-button {
                display: none;
            }
        `,
    ];

    render() {
        const issues = this._issues;

        if (!issues.length) {
            return this._loading
                ? html`
                      <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                          <ptc-spinner active></ptc-spinner>
                      </div>
                  `
                : html`
                      <div class="center-aligning center-justifying fullbleed vertical layout green placeholder">
                          <i class="check-circle"></i>
                          <div class="msg">Es liegen momentan keine Probleme vor!</div>
                      </div>
                  `;
        }

        const months: IssueMonth[] = [];

        let currMonth: IssueMonth | null = null;
        let currDay: IssueDay | null = null;

        const issueCount = new Map<IssueType, number>();
        const filteredTypes = this._filteredTypes;

        for (const issue of issues) {
            const date = parseDateString(issue.date)!;
            if (!currMonth || currMonth.year !== date.getFullYear() || currMonth.month !== date.getMonth()) {
                currMonth = {
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    days: [],
                };
                months.push(currMonth);
            }

            if (!filteredTypes || !filteredTypes.length || filteredTypes.includes(issue.type)) {
                if (!currDay || currDay.date !== issue.date) {
                    currDay = {
                        date: issue.date,
                        issues: [],
                    };
                    currMonth.days.push(currDay);
                }

                currDay.issues.push(issue);
            }

            if (!issueCount.has(issue.type)) {
                issueCount.set(issue.type, 0);
            }

            issueCount.set(issue.type, issueCount.get(issue.type)! + 1);
        }

        return html`
            <div class="sub-menu">
                <form id="filterForm">
                    <div class="padded subtle"><i class="filter"></i> Problemarten</div>
                    ${[...issueCount.entries()].map(
                        ([type, count]) => html`
                            <ptc-checkbox-button
                                buttonClass="small slim transparent"
                                ?hidden=${!count}
                                name="types"
                                .value=${type.toString()}
                                labelPosition="after"
                                .label=${html`
                                    <div class="horizontal spacing center-aligning layout">
                                        <div class="stretch collapse ellipsis">${getIssueMessage(type)}</div>
                                        <div class="smaller red pill">${count}</div>
                                    </div>
                                `}
                                checked
                                @input=${() => this.requestUpdate()}
                            >
                            </ptc-checkbox-button>
                        `
                    )}
                </form>

                <div class="top-margined padded subtle"><i class="clock"></i> Zeitraum</div>

                <div class="vertical tabs">
                    ${months.map(
                        (m) => html`
                            <button
                                class="center-aligning horizontal layout"
                                ?active=${this._currMonth === `${m.year}-${m.month}`}
                                @click=${() => this._scrollToMonth(m)}
                            >
                                <div class="stretch">${monthNames[m.month]} ${m.year}</div>
                                <div class="tiny red pill">
                                    ${m.days.reduce((total, day) => total + day.issues.length, 0)}
                                </div>
                            </button>
                        `
                    )}
                </div>
            </div>

            <div class="main" @scroll=${this._scroll}>
                ${months.map(({ year, month, days }) => {
                    const issues = days.flatMap((d) => d.issues);
                    return html`
                        <div class="month" data-month="${year}-${month}">
                            <div class="month-header horizontal center-aligning layout">
                                <div class="stretch">${monthNames[month]} ${year}</div>

                                <button
                                    class="small transparent issue-button ignore"
                                    title="Alle Verwerfen"
                                    ?hidden=${!issues.length}
                                    @click=${() => this._ignoreIssues(issues)}
                                >
                                    <i class="eye-slash"></i>
                                    Alle Verwerfen
                                </button>
                            </div>

                            ${days.map(({ date, issues }) => {
                                const d = parseDateString(date)!;
                                return html`
                                    <div class="day">
                                        <div class="day-header">${weekDays[d.getDay()]}, ${formatDate(d)}</div>

                                        ${issues.map((issue) => this._renderIssue(issue))}
                                    </div>
                                `;
                            })}
                        </div>
                    `;
                })}
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }

    private _renderIssue(issue: Issue) {
        return html`
            <div class="horizontal spacing center-aligning layout issue">
                <ptc-issue .issue=${issue}></ptc-issue>

                <div class="stretch"></div>

                <button class="transparent small issue-button solve" @click=${() => this._goToIssue(issue)}>
                    <i class="pen"></i>
                    Beheben
                </button>

                ${issue.type !== IssueType.UnbalancedDailyCash && issue.type !== IssueType.PendingDailyRevenues
                    ? html`
                          <button
                              class="transparent small issue-button ignore"
                              @click=${() => this._ignoreIssues([issue])}
                          >
                              <i class="eye-slash"></i>
                              Verwerfen
                          </button>
                      `
                    : ""}
            </div>
        `;
    }
}
