import { Feature } from "@pentacode/core/src/model";
import { toDateString, parseDateString } from "@pentacode/core/src/util";
import { CashbookIssueTypes, Issue, IssueType } from "@pentacode/core/src/issues";
import { LitElement, html, css } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { shared, mixins } from "../styles";
import { app, router } from "../init";
import { contactSupport } from "../lib/help";
import "./revenues-all";
import "./revenues-daily";
import "./revenues-cashbook";
import "./revenues-debtors";
import "./revenues-groups";
import "./revenues-attribution";
import { DatePicker } from "./date-picker";
import { Permission } from "@pentacode/core/src/permissions";
import "./spinner";
import { GetIssuesParams } from "@pentacode/core";

@customElement("ptc-revenues")
export class Revenues extends Routing(StateMixin(LitElement)) {
    static pages = ["all", "daily", "cashbook", "debtors", "groups", "attribution"];

    routePattern = /^revenues(?:\/(?<page>\w+))?/;

    get helpPage() {
        switch (this._page) {
            case "all":
                break;
            case "daily":
                return "/handbuch/umsaetze/tagesabrechnung";
            case "cashbook":
                return "/handbuch/umsaetze/kassenbuch";
            case "groups":
                return "/handbuch/umsaetze/umsatzgruppen";
            case "attribution":
                return "/handbuch/umsaetze/erloeszuordnung";
            default:
                return "/handbuch/umsaetze";
        }
    }

    @routeProperty({ arg: "page" })
    private _page: string;

    @state()
    private _issues: Issue[] = [];

    @query("select")
    private _venueSelect: HTMLSelectElement;

    @query("ptc-date-picker")
    private _datePicker: DatePicker;

    private _selectVenue() {
        this.go(null, { ...this.router.params, venue: this._venueSelect.value });
    }

    handleRoute() {
        const venue = app.getVenue(this.venueId);
        if (!venue || !app.hasAccess({ venue })) {
            const venue = app.venues.find((venue) => app.hasAccess({ venue }));
            if (venue) {
                this.go(null, { ...this.router.params, venue: venue.id.toString() }, true);
            }
            return;
        }

        const permission = this._page === "attribution" ? "groups" : this._page;

        if (!Revenues.pages.includes(this._page) || !app.hasPermission(`manage.revenues.${permission}` as Permission)) {
            const page = Revenues.pages.find((page) => app.hasPermission(`manage.revenues.${page}` as Permission));
            this.redirect(page ? `revenues/${page}` : "");
            return;
        }

        const today = toDateString(new Date());

        //Invalid date
        if (!this.date || !parseDateString(this.date) || this.date > today) {
            this.go(null, { ...this.router.params, date: today }, true);
            return;
        }

        this._datePicker && (this._datePicker.value = this.date);

        void this._loadIssues();
    }

    private async _loadIssues() {
        this._issues = await app.api.getIssues(new GetIssuesParams({ types: CashbookIssueTypes }));
    }

    private _getCalendarBadge(date: string) {
        if (
            this._issues.some((i) => {
                if (i.type === IssueType.UnbalancedDailyCash) {
                    return i.details.venueId.toString() === router.params.venue && i.date === date;
                }
                return false;
            })
        ) {
            return {
                icon: "exclamation-triangle",
                class: "red",
            };
        } else if (
            this._issues.some(
                (i) =>
                    i.type === IssueType.PendingDailyRevenues &&
                    i.details.venueId.toString() === router.params.venue &&
                    i.date === date
            )
        ) {
            return {
                icon: "pencil-alt",
                class: "orange",
            };
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: flex;
            }

            .sub-menu {
                position: relative;
                width: 220px;
                box-sizing: border-box;
            }

            .sub-menu select {
                width: 100%;
                margin-bottom: 1em;
            }

            .main {
                flex: 1;
                min-width: 0;
                position: relative;
            }

            .main > * {
                ${mixins.fullbleed()};
            }

            @media print {
                .main > * {
                    position: static;
                }
            }
        `,
    ];

    render() {
        if (!app.company || !app.company.features.includes(Feature.Revenues)) {
            return html`
                <div class="poster center-aligning center-justifying vertical layout fullbleed">
                    <i class="euro-sign"></i>

                    <div>
                        Dieser Bereich ist für Ihr Unternehmen noch nicht verfügbar! Bitte kontaktieren sie unser
                        Supportteam um zu erfahren, wie Sie dieses Modul freizuschalten können!
                    </div>

                    <button @click=${() => contactSupport("Umsatzmodul Freischalten")}>
                        <i class="user-headset"></i>
                        Support Kontaktieren
                    </button>
                </div>
            `;
        }

        return html`
            <div class="sub-menu noprint">
                <div class="vertical tabs">
                    <button
                        ?active=${this._page === "all"}
                        @click=${() => this.go("revenues/all", this.router.params)}
                        ?disabled=${!app.hasPermission(`manage.revenues`) /**TODO: correct permission? */}
                    >
                        <i class="chart-line"></i>
                        Alle Umsätze
                    </button>
                    <button
                        ?active=${this._page === "daily"}
                        @click=${() => this.go("revenues/daily", this.router.params)}
                        ?disabled=${!app.hasPermission(`manage.revenues.daily`)}
                    >
                        <i class="cash-register"></i>
                        Tagesabrechnung
                    </button>

                    <button
                        ?active=${this._page === "cashbook"}
                        @click=${() => this.go("revenues/cashbook", this.router.params)}
                        ?disabled=${!app.hasPermission(`manage.revenues.cashbook`)}
                    >
                        <i class="book"></i>
                        Kassenbuch
                    </button>

                    <button
                        ?active=${this._page === "debtors"}
                        @click=${() => this.go("revenues/debtors", this.router.params)}
                        ?disabled=${!app.hasPermission(`manage.revenues.debtors`)}
                    >
                        <i class="file-invoice-dollar"></i>
                        Debitoren
                    </button>

                    <button
                        ?active=${this._page === "groups"}
                        @click=${() => this.go("revenues/groups/sales", this.router.params)}
                        ?disabled=${!app.hasPermission(`manage.revenues.groups`)}
                    >
                        <i class="filter-circle-dollar"></i>
                        Umsatzgruppen
                    </button>

                    <button
                        ?active=${this._page === "attribution"}
                        @click=${() => this.go("revenues/attribution", this.router.params)}
                        ?disabled=${!app.hasPermission(`manage.revenues.groups`)}
                    >
                        <i class="arrows-turn-to-dots"></i>
                        Erlöszuordnung
                    </button>
                </div>

                <div class="divider"></div>

                <select @change=${this._selectVenue}>
                    ${app.accessibleVenues.map(
                        (venue) => html`
                            <option
                                .value=${venue.id.toString()}
                                ?selected=${venue.id.toString() === router.params.venue}
                                ?disabled=${!app.hasAccess({ venue })}
                            >
                                ${venue.name}
                            </option>
                        `
                    )}
                </select>

                <ptc-date-picker
                    class="small"
                    mode="day"
                    @change=${(e: CustomEvent) => this.go(null, { ...this.router.params, date: e.detail.date })}
                    .badge=${(date: string) => this._getCalendarBadge(date)}
                    .value=${this.date}
                    ?disabled=${this._page === "groups"}
                ></ptc-date-picker>
            </div>

            <div class="main">
                <ptc-revenues-all ?hidden=${this._page !== "all"}></ptc-revenues-all>

                <ptc-revenues-daily
                    ?hidden=${this._page !== "daily"}
                    @daily-revenues-saved=${this._loadIssues}
                ></ptc-revenues-daily>

                <ptc-revenues-cashbook ?hidden=${this._page !== "cashbook"}></ptc-revenues-cashbook>

                <ptc-revenues-debtors ?hidden=${this._page !== "debtors"}></ptc-revenues-debtors>

                <ptc-revenues-groups ?hidden=${this._page !== "groups"}></ptc-revenues-groups>

                <ptc-revenues-attribution ?hidden=${this._page !== "attribution"}></ptc-revenues-attribution>
            </div>
        `;
    }
}
