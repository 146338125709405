import { html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert, confirm } from "./alert-dialog";
import "./scroller";
import { dateAdd, formatMonth, getRange } from "@pentacode/core/src/util";
import { clone } from "@pentacode/core/src/encoding";
import { DateString } from "packages/openapi/src/units";
import { getMonthsForCommittingTimes } from "../lib/util";
import "./spinner";
import { EmployeeIssueTypes, GetIssuesParams, Issue } from "@pentacode/core";

@customElement("ptc-commit-times-dialog")
export class CommitTimesDialog extends Dialog<void, void> {
    readonly preventDismiss = true;

    @query("form")
    private _form: HTMLFormElement;

    @query("select[name='commitTimeEntriesBefore']")
    private _select: HTMLSelectElement;

    private get _commitTimeEntriesBefore() {
        const data = new FormData(this._form);
        return data.get("commitTimeEntriesBefore") as DateString;
    }

    async show() {
        const promise = super.show();
        await this.updateComplete;
        this._select.value = "";
        return promise;
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        const commitTimeEntriesBefore = this._commitTimeEntriesBefore;

        this.loading = true;
        let issues: Issue[] = [];
        try {
            issues = await app.api.getIssues(
                new GetIssuesParams({ to: commitTimeEntriesBefore, types: EmployeeIssueTypes, includeIgnored: false })
            );
        } catch (e) {
            await alert(e.message, { type: "warning" });
            return;
        }
        this.loading = false;

        this.hide();
        const confirmed = await confirm(
            html`<div>
                    Sind Sie sicher, dass Sie mit der Festschreibung aller Arbeitzeiten, Konten und Lohnabrechnungen
                    <strong>bis einschließlich ${formatMonth(dateAdd(commitTimeEntriesBefore, { days: -1 }))}</strong>
                    fortfahren wollen?
                </div>
                ${issues.length
                    ? html`
                          <div class="top-margined padded orange box">
                              <strong><i class="exclamation-triangle"></i> ACHTUNG:</strong> Es liegen
                              <strong>${issues.length} offene Problemmeldungen</strong> in dem ausgewählten Zeitraum
                              vor. Wenn Sie mit der Festschreibung fortfahren, werden diese verworfen!
                          </div>
                      `
                    : ""}`,
            "Fortfahren",
            "Abbrechen",
            { title: "Festschreibung Bestätigen", optionsLayout: "horizontal", hideIcon: true }
        );
        this._show();

        if (!confirmed) {
            return;
        }

        this.loading = true;
        try {
            const settings = clone(app.company!.settings);
            settings.commitTimeEntriesBefore = commitTimeEntriesBefore;
            await app.updateCompany({ settings });
            this.done();
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this._show();
        }
        this.loading = false;
    }

    static styles = [...Dialog.styles, css``];

    renderContent() {
        const commitBefore = app.company?.settings.commitTimeEntriesBefore;
        const currMonth = getRange(new Date(), "month").from;

        const months: DateString[] = getMonthsForCommittingTimes(app, currMonth);

        return html`
            <div class="padded spacing vertical layout">
                <form @submit=${this._submit}>
                    <div class="big margined text-centering">Arbeitszeiten & Konten Festschreiben...</div>

                    <div class="double-margined">
                        <label>...bis einschließlich</label>
                        <div class="horizontal center-aligning spacing layout">
                            ${commitBefore
                                ? html`<div>${formatMonth(dateAdd(commitBefore, { days: -1 }))}</div>
                                      <i class="arrow-right"></i>`
                                : ""}
                            <select name="commitTimeEntriesBefore" required @change=${() => this.requestUpdate()}>
                                <option value="" disabled selected>Zeitpunkt Wählen...</option>
                                ${months.map(
                                    (month) => html`
                                        <option .value=${month}>${formatMonth(dateAdd(month, { days: -1 }))}</option>
                                    `
                                )}
                            </select>
                        </div>
                    </div>

                    <div class="horizontal spacing evenly stretching layout">
                        <button class="primary">Bestätigen</button>
                        <button type="button" class="transparent" @click=${() => this.done()}>Abbrechen</button>
                    </div>
                </form>
            </div>
        `;
    }
}
