import { EmployeeDay } from "./employee-day";
import "./spinner";
import { LitElement, html, css, nothing } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import {
    TimeEntry,
    TimeEntryType,
    timeEntryTypeColor,
    Absence,
    AbsenceStatus,
    RevenueEntry,
    RevenueType,
    DailyResults,
    BonusType,
} from "@pentacode/core/src/model";
import {
    toDateString,
    toDurationString,
    getRange,
    dateAdd,
    formatDate,
    formatNumber,
    toTimeString,
    parseTimes,
} from "@pentacode/core/src/util";
import { Issue, IssueType, getShiftIssues, getShiftsForIssue } from "@pentacode/core/src/issues";
import { getHolidayForDate } from "@pentacode/core/src/holidays";
import { GetAbsencesParams, GetDailyResultsParams, GetRevenueEntriesParams } from "@pentacode/core/src/api";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import "./month-picker";
import { HourAdjustmentDialog } from "./hour-adjustment-dialog";
import { VacationAdjustmentDialog } from "./vacation-adjustment-dialog";
import { alert, confirm } from "./alert-dialog";
import "./popover";
import { TimeInput } from "./time-input";
import { AbsenceDialog } from "./absence-dialog";
import { live } from "lit/directives/live.js";
import { classMap } from "lit/directives/class-map.js";
import { cache } from "lit/directives/cache.js";
import { isCursorInInput, isSafari } from "../lib/util";
import { formatFraction } from "../lib/lit-dependent-util";
import "./bonus-type-item";
import { getPayrollReport } from "@pentacode/core/src/payroll";
import "./payroll-report";
import { popover } from "../directives/popover";
import { DateRangePicker } from "./date-range-picker";
import { serializeFilters } from "@pentacode/core/src/filters";
import {
    BonusResult,
    DateRange,
    getDailyResults,
    getNominalTime,
    getResultBreakDown,
    getStatutoryBreak,
} from "@pentacode/core/src/time";
import { DateString, Hours, add } from "@pentacode/openapi";
import { virtualize } from "@lit-labs/virtualizer/virtualize.js";

interface Item {
    date: DateString;
    timeEntry?: TimeEntry;
    absence?: Absence;
}

interface DragData {
    entry?: Partial<TimeEntry>;
}

@customElement("ptc-employees-time-single")
export class EmployeesTimeSingle extends Routing(StateMixin(LitElement)) {
    routePattern = /^employees\/(?<id>\d+)\/time$/;

    get routeTitle() {
        return `Arbeitszeiten: ${this._employee && this._employee.name}`;
    }

    @routeProperty({ arg: "id", type: Number })
    employee: number;

    protected defaultRange = () => getRange(new Date(), "month");
    protected rangeChanged = () => this.synchronize();

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _advances: RevenueEntry[] = [];

    @state()
    private _loading = false;

    @state()
    private _absences: Absence[] = [];

    @state()
    private _activeDate: DateString | null = null;

    @state()
    private _activeEntry: string | null = null;

    @state()
    private _activeField: string | null = null;

    @state()
    private _dragData: DragData | null = null;

    @state()
    private _items: Item[] = [];

    @state()
    private _issues: Issue[] = [];

    @state()
    private _prevResults: DailyResults[] = [];

    @singleton("ptc-hour-adjustment-dialog")
    private _hourAdjustmentDialog: HourAdjustmentDialog;

    @singleton("ptc-vacation-adjustment-dialog")
    private _vacationAdjustmentDialog: VacationAdjustmentDialog;

    @singleton("ptc-absence-dialog")
    private _absenceDialog: AbsenceDialog;

    @query("ptc-employee-day")
    private _employeeDayForm: EmployeeDay;

    @query("ptc-date-range-picker")
    private _dateRangePicker: DateRangePicker;

    private get _employee() {
        return (this.employee && app.getEmployee(this.employee)) || null;
    }

    handleRoute() {
        // synchronize in the background
        void this.synchronize(true);
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("dragover", (e: DragEvent) => this._dragover(e));
        this.addEventListener("drop", (e: DragEvent) => e.preventDefault());
        this.addEventListener("drop", (e: DragEvent) => e.preventDefault());
        this.addEventListener("dragend", (e: DragEvent) => this._dragend(e));
        document.addEventListener("keydown", (e: KeyboardEvent) => {
            if (
                !this.active ||
                e.ctrlKey ||
                e.metaKey ||
                (!this._employeeDayForm?.matches(":focus-within") && isCursorInInput())
            ) {
                return;
            }
            const direction = { w: "up", s: "down", a: "left", d: "right" }[e.key] as "up" | "down" | "left" | "right";
            if (direction === "left") {
                this._dateRangePicker.previousRange();
                this._setActive({ date: null });
            } else if (direction === "right") {
                this._dateRangePicker.nextRange();
                this._setActive({ date: null });
            } else if (direction === "down" || direction === "up") {
                e.preventDefault();
                const index =
                    this._activeEntry && this._activeEntry !== "new"
                        ? this._items.findIndex((i) => i.timeEntry?.id === this._activeEntry)
                        : this._activeDate
                          ? this._items.findIndex((i) => i.date === this._activeDate)
                          : direction === "down"
                            ? -1
                            : this._items.length;
                const item =
                    this._items[index + (direction === "down" ? 1 : -1)] ||
                    (direction === "down" ? this._items[0] : this._items[this._items.length - 1]);
                this._setActive({ date: item?.date, entry: item?.timeEntry?.id || null });
            } else if (e.key === "Escape") {
                this._setActive({ date: null, entry: null }, true);
            } else if (e.shiftKey && e.key === "Backspace") {
                const activeEntry = this._timeEntries.find((e) => e.id === this._activeEntry);
                if (activeEntry) {
                    void this._removeTimeEntry(activeEntry);
                }
            } else if (e.key === "n") {
                this._setActive({ entry: "new" });
            }
        });
    }

    async synchronize(showSpinner = false) {
        if (!this.dateRange || !this.employee) {
            return;
        }

        if (showSpinner) {
            // this._items = [];
            this._loading = true;
        }

        const { from, to } = this.dateRange;
        const timeEntriesFrom = dateAdd(from, { months: -1 });

        try {
            const [timeEntries, absences, advances, prevResults] = await Promise.all([
                app.getTimeEntries({
                    from: timeEntriesFrom,
                    to,
                    employee: this.employee,
                    type: [
                        TimeEntryType.Work,
                        TimeEntryType.Vacation,
                        TimeEntryType.Sick,
                        TimeEntryType.HourAdjustment,
                        TimeEntryType.VacationAdjustment,
                        TimeEntryType.CompDay,
                        TimeEntryType.ChildSick,
                        TimeEntryType.SickInKUG,
                        TimeEntryType.Free,
                    ],
                }),
                app.api.getAbsences(new GetAbsencesParams({ from, to, employee: this.employee })),
                app.hasPermission("manage.employees.advances")
                    ? app.api.getRevenueEntries(
                          new GetRevenueEntriesParams({
                              from,
                              to,
                              employee: this.employee,
                              type: [RevenueType.PayAdvance],
                          })
                      )
                    : Promise.resolve([]),
                app.api.getDailyResults(
                    new GetDailyResultsParams({
                        from: dateAdd(from, { days: -1 }),
                        to: from,
                        filters: [{ type: "employeeId", value: this.employee }],
                    })
                ),
            ]);

            this._timeEntries = timeEntries.sort((a, b) => a.start.getTime() - b.start.getTime());
            this._absences = absences;
            this._advances = advances;
            this._prevResults = prevResults;
            this._refresh();
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        if (showSpinner) {
            this._loading = false;
        }
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLElement).classList.add("dragover");
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = !e.altKey ? "link" : "copy";
        }
    }

    private _dragleave(e: DragEvent) {
        (e.target as HTMLElement).classList.remove("dragover");
    }

    private _dragend(e?: DragEvent) {
        this._dragData = null;
        this.classList.remove("dragging");
        for (const el of [this, ...this.renderRoot!.querySelectorAll(".dragging")] as HTMLElement[]) {
            el.classList.remove("dragging");
        }
        e && e.preventDefault();
    }

    private _dropIntoDay(e: DragEvent, date: DateString) {
        e.preventDefault();

        const data = this._dragData;

        if (!data || !this._employee) {
            return;
        }

        if (!data.entry) {
            return;
        }

        const { id, type = TimeEntryType.Work, position, breakPlanned } = data.entry;
        let startPlanned = data.entry.startPlanned;
        let endPlanned = data.entry.endPlanned;
        let startFinal = data.entry.startFinal;
        let endFinal = data.entry.endFinal;

        [startPlanned, endPlanned] = parseTimes(
            date,
            toTimeString(data.entry.startPlanned),
            toTimeString(data.entry.endPlanned)
        );
        [startFinal, endFinal] = parseTimes(
            date,
            toTimeString(data.entry.startFinal),
            toTimeString(data.entry.endFinal)
        );

        const isPast = date < toDateString(new Date());

        if (!data.entry.id || e.altKey) {
            // if the day is in the past and we're adding a new entry,
            // set final times instead of planned
            if (isPast && !startFinal && !endFinal) {
                startFinal = startPlanned;
                endFinal = endPlanned;
                startPlanned = null;
                endPlanned = null;
            }
            void this._addTimeEntry(
                {
                    type,
                    employeeId: this.employee,
                    startPlanned,
                    endPlanned,
                    startFinal,
                    endFinal,
                    position,
                    date,
                    breakPlanned,
                },
                this._activeEntry === data.entry.id || (type === TimeEntryType.Work && !startPlanned)
            );
        } else {
            const entry = this._timeEntries.find((e) => e.id === id);
            if (!entry) {
                return;
            }
            this._updateTimeEntry(entry, { date, startPlanned, endPlanned, startFinal, endFinal });
            if (this._activeEntry === entry.id) {
                this._setActive({
                    date: date,
                });
            }
        }

        (e.target as HTMLElement).classList.remove("dragover");

        setTimeout(() => this._dragend(), 50);
    }

    private _dragstart(e: DragEvent, data: DragData) {
        this._dragData = data;
        const el = e.target as HTMLElement;
        // const imgEl = (el.shadowRoot && (el.shadowRoot.querySelector(".container") as HTMLElement)) || el;
        const dt = e.dataTransfer!;
        dt.setData("text/plain", "42");
        dt.effectAllowed = "copyLink";
        // dt.setDragImage(imgEl, imgEl.offsetWidth / 2, imgEl.offsetHeight / 2);
        this.classList.add("dragging");
        el.classList.add("dragging");
    }

    private _refresh() {
        if (!this._employee || !app.company || !this.dateRange) {
            return;
        }

        const entries = this._timeEntries.filter(
            (entry) => entry.date >= this.dateFrom && entry.date < this.dateTo && !app.isRemoved(entry)
        );

        // mark entries for result recalculation only if they are past the commit time
        const commitBefore = app.company.settings.commitTimeEntriesBefore;
        entries.forEach((entry) => {
            if (!commitBefore || entry.date >= commitBefore) {
                entry.result = null;
            }
        });

        // This mutates all time entries with result = null
        getDailyResults(app.company, this._employee, this._timeEntries, this.dateRange, this._prevResults);

        const { from, to } = this.dateRange;
        const items: Item[] = [];

        let date = from;
        while (date < to) {
            const dayEntries = entries.filter((entry) => entry.date === date);
            const absence = this._absences.find(
                (a) =>
                    [AbsenceStatus.Approved, AbsenceStatus.Inferred].includes(a.status) &&
                    a.start <= date &&
                    a.end > date
            );
            if (dayEntries.length) {
                items.push(
                    ...dayEntries.map((timeEntry) => ({
                        date,
                        timeEntry,
                        absence,
                    }))
                );
            } else {
                items.push({ date, absence });
            }
            date = dateAdd(date, { days: 1 });
        }

        this._items = items;
        this._employeeDayForm && this._employeeDayForm.updateForms();

        this._issues = getShiftIssues(this._employee, app.company!, this._timeEntries, this.dateRange);
    }

    private async _editHourAdjustment(entry: TimeEntry) {
        const edited = await this._hourAdjustmentDialog.show(entry);
        if (edited) {
            // synchronize in the background
            void this.synchronize(true);
        }
    }

    private _addHourAdjustment() {
        return this._editHourAdjustment(
            new TimeEntry({
                employeeId: this.employee!,
                date: this._activeDate || this.dateFrom || undefined,
                type: TimeEntryType.HourAdjustment,
                paid: false,
            })
        );
    }

    private async _editVacationAdjustment(entry: TimeEntry) {
        const edited = await this._vacationAdjustmentDialog.show(entry);
        if (edited) {
            // synchronize in the background
            void this.synchronize(true);
        }
    }

    private _addVacationAdjustment() {
        return this._editVacationAdjustment(
            new TimeEntry({
                employeeId: this.employee!,
                date: this._activeDate || this.dateFrom || undefined,
                type: TimeEntryType.VacationAdjustment,
                paid: false,
            })
        );
    }

    private async _editAbsence(absence: Absence) {
        const edited = await this._absenceDialog.show(absence);
        if (edited) {
            return this.synchronize(true);
        }
    }

    private async _addTimeEntry(vals: Partial<TimeEntry>, setActive = false) {
        if (![TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(vals.type!)) {
            const absence = new Absence({
                type: vals.type,
                employeeId: vals.employeeId!,
                start: vals.date,
                end: dateAdd(vals.date!, { days: 1 }),
            });

            return this._editAbsence(absence);
        }

        const entry = new TimeEntry(vals);
        await app.createOrUpdateTimeEntries(entry, { applyAutoMeals: true, otherEntries: this._timeEntries });
        this._timeEntries.push(entry);
        this._refresh();

        if (setActive) {
            this._setActive({
                date: entry.date,
                entry: entry.id,
                field: "start",
            });
        }
        return;
    }

    private _updateTimeEntry(a: TimeEntry, data: Partial<TimeEntry> = {}) {
        if (app.isRemoved(a)) {
            return;
        }

        Object.assign(a, data);

        // push the changes to the server in the background
        void app.createOrUpdateTimeEntries(a);

        this._refresh();
    }

    private async _removeTimeEntry(entry: TimeEntry) {
        if (
            entry.isPast &&
            (entry.startFinal || entry.endFinal) &&
            !(await confirm("Sind Sie sicher dass Sie diesen Eintrag löschen möchten?", "Löschen", "Abbrechen", {
                title: "Eintrag Löschen",
                type: "destructive",
                icon: "trash",
            }))
        ) {
            return;
        }

        if (entry.id === this._activeEntry) {
            this._setActive({ entry: null });
        }

        // push the changes to the server in the background
        void app.removeTimeEntries(entry);

        // filter out the removed entry in local state and refresh
        this._timeEntries = this._timeEntries.filter((e) => e.id !== entry.id);
        this._refresh();
    }

    // TODO: should just be number in browser, but our types are borked
    private _setActiveTimeout: number | NodeJS.Timeout;
    private _setActive(
        {
            date = this._activeDate,
            entry = this._activeEntry,
            field = this._activeField,
        }: {
            date?: DateString | null;
            entry?: string | null;
            field?: string | null;
        } = {},
        instant = false,
        e?: Event
    ) {
        e && e.stopPropagation();
        if (date && !entry) {
            const first = this._timeEntries.find((e) => e.date === date && !e.deleted);
            entry = (first && first.id) || "new";
        }

        const innerAsyncUpdate = async () => {
            this._activeDate = date;
            this._activeEntry = entry;
            this._activeField = field;
            await this.updateComplete;
            const row = this.renderRoot!.querySelector(".row.selected");
            if (row) {
                try {
                    // @ts-expect-error this is a non-standard method not yet supported by firefox
                    row.scrollIntoViewIfNeeded();
                } catch (e) {
                    row.scrollIntoView({ block: "center" });
                }
            }
        };

        clearTimeout(this._setActiveTimeout);

        if (entry || instant) {
            void innerAsyncUpdate();
        } else {
            this._setActiveTimeout = setTimeout(innerAsyncUpdate, 500);
        }
    }

    static styles = [
        shared,
        TimeInput.styles,
        css`
            :host {
                display: flex;
                position: relative;
                flex-direction: column;
                --virtual-list-padding: 0 0 15em 0;
            }

            ptc-employee-day {
                width: 250px;
                border-left: solid 1px var(--shade-1);
                border-right: solid 1px var(--shade-1);
            }

            .month-picker {
                max-width: 600px;
                padding-left: 0;
                padding-right: 0;
            }

            .summary {
                display: flex;
            }

            .summary > div {
                padding: 0.1em 0.5em;
            }

            .virtual-row:hover {
                z-index: 1;
            }

            .row {
                font-size: var(--font-size-small);
                padding: 0.5em 0.6em;
                cursor: pointer;
                height: 3em;
                box-sizing: border-box;
                width: 100%;
                min-width: 61em;
                box-sizing: border-box;
            }

            .row:hover,
            .row.selected,
            .row.dragover {
                background: var(--color-bg);
                border-radius: 0.5em;
                box-shadow: inset var(--shade-5) 0 0 0 0.1em;
            }

            .row.selected {
                box-shadow: inset var(--color-highlight, var(--color-primary)) 0 0 0 0.1em;
            }

            .row.dragover * {
                pointer-events: none;
            }

            .row.sunday .date {
                color: var(--orange);
            }

            .row.holiday .date {
                color: var(--violet);
            }

            .row.empty:not(:hover):not(.selected):not(.absence) > * {
                opacity: 0.5;
            }

            .row > :not(:first-child) {
                margin-left: 0.5em;
            }

            .row ptc-time-input {
                width: 6em;
            }

            .row .date {
                font-weight: 600;
                width: 7.7em;
            }

            .row .date .weekday {
                width: 1.8em;
            }

            .position {
                width: 10em;
            }

            .position .pill.vacation {
                background: var(--blue-bg);
                color: var(--blue);
            }

            .position .pill.sick {
                background: var(--orange-bg);
                color: var(--orange);
            }

            .row .start ptc-time-input,
            .row .end ptc-time-input {
                width: 5.5em;
            }

            .row .break ptc-time-input {
                width: 5em;
            }

            .row .meals {
                width: 3.5em;
            }

            .row .revenue {
                width: 6.6em;
            }

            .row .duration {
                font-weight: 600;
                line-height: 2.5em;
            }

            .row .status {
                margin-right: 0.5em;
            }

            .row .status.pill i {
                display: inline-block;
            }

            .row.absence .status {
                height: 3em;
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--color-highlight);
                color: var(--color-bg);
                border-color: transparent;
                box-sizing: border-box;
            }

            .row.absence.absence-start .status {
                border-top-left-radius: 0.5em;
                border-top-right-radius: 0.5em;
                height: 2.8em;
                position: relative;
                top: 0.2em;
                padding-top: 0;
            }

            .row.absence.absence-end .status {
                border-bottom-left-radius: 0.5em;
                border-bottom-right-radius: 0.5em;
                height: 2.8em;
                position: relative;
                top: -0.2em;
                padding-bottom: 0;
            }

            .row.absence.absence-start.absence-end .status {
                height: 2.3em;
                top: 0;
            }

            .warn {
                color: var(--color-negative);
                border-color: var(--color-negative);
            }

            .row .duration i {
                margin-right: -0.2em;
            }

            input {
                padding-top: 0.3em;
                padding-bottom: 0.3em;
                padding-right: 0.3em;
                cursor: pointer !important;
            }
        `,
    ];

    private _renderBonuses(bonuses: BonusResult[]) {
        const aggregated: {
            type: Pick<BonusType, "id" | "name">;
            taxed: Hours;
            taxFree: Hours;
        }[] = [];

        for (const bonus of bonuses) {
            if (!bonus.duration) {
                continue;
            }
            let existing = aggregated.find(({ type }) => type.id === bonus.type.id);
            if (!existing) {
                existing = {
                    type: bonus.type,
                    taxed: 0 as Hours,
                    taxFree: 0 as Hours,
                };

                aggregated.push(existing);
            }

            if (bonus.taxFree) {
                existing.taxFree = add(bonus.duration, existing.taxFree);
            } else {
                existing.taxed = add(bonus.duration, existing.taxed);
            }
        }

        return html`
            <table class="simple">
                <thead>
                    <tr>
                        <th></th>
                        <th>Beitragsfrei</th>
                        <th>Beitragspfl.</th>
                    </tr>
                </thead>
                <tbody>
                    ${aggregated.map(
                        ({ type, taxed, taxFree }) => html`
                            <tr>
                                <td><i class="badge-percent"></i> ${type.name}</td>
                                <td>${taxFree ? toDurationString(taxFree) : "-"}</td>
                                <td>${taxed ? toDurationString(taxed) : "-"}</td>
                            </tr>
                        `
                    )}
                </tbody>
            </table>
        `;
    }

    private _renderItem({ date, timeEntry: entry, absence }: Item) {
        const dep = entry?.position && app.getDepartment(entry.position.departmentId);
        const venue = (dep && dep.venue) || app.venues[0];
        const hol = getHolidayForDate(date, { holidays: venue?.enabledHolidays, country: app.company!.country });
        const employee = app.getEmployee(this.employee!)!;
        const contract = employee.getContractForDate(date);

        if (!entry) {
            if (!contract || contract.blocked) {
                return html`<div
                    class="center-aligning horizontal layout empty row border-bottom ${classMap({
                        sunday: new Date(date).getDay() === 0,
                        holiday: !!hol,
                    })}"
                    disabled
                >
                    <div class="pill status"><i class="ban"></i></div>

                    <div class="date horizontal layout">
                        ${hol
                            ? html` <div class="stretch ellipsis">${hol.name}</div> `
                            : html`
                                  <div class="weekday">
                                      ${["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"][new Date(date).getDay()]}
                                  </div>
                                  <div class="stretch">${formatDate(date)}</div>
                              `}
                    </div>

                    <div class="stretch collapse">
                        <div class="comment ellipsis">${(contract && contract.comment) || "Inaktiv"}</div>
                    </div>
                </div>`;
            }

            return html`
                <div
                    class="center-aligning horizontal layout empty row border-bottom ${classMap({
                        sunday: new Date(date).getDay() === 0,
                        holiday: !!hol,
                        absence: !!absence,
                        selected: this._activeDate === date,
                        "absence-start": absence?.start === date,
                        "absence-end": absence?.end === dateAdd(date, { days: 1 }),
                    })}"
                    @click=${() => (absence ? this._editAbsence(absence) : this._setActive({ date, entry: "new" }))}
                    @dragenter=${this._dragenter}
                    @dragleave=${this._dragleave}
                    @dragover=${this._dragover}
                    @drop=${(e: DragEvent) => this._dropIntoDay(e, date)}
                >
                    ${absence
                        ? html`
                              <div class="pill status" style="--color-highlight: ${timeEntryTypeColor(absence.type)}">
                                  <i class="plus invisible"></i>
                              </div>
                          `
                        : html` <div class="pill status"><i class="plus"></i></div> `}

                    <div class="date horizontal layout">
                        ${hol
                            ? html` <div class="stretch ellipsis">${hol.name}</div> `
                            : html`
                                  <div class="weekday">
                                      ${["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"][new Date(date).getDay()]}
                                  </div>
                                  <div class="stretch">${formatDate(date)}</div>
                              `}
                    </div>
                </div>
            `;
        }

        const start = entry.startFinal || entry.endFinal ? entry.startFinal : entry.startPlanned;
        const end = entry.endFinal || entry.endFinal ? entry.endFinal : entry.endPlanned;

        const salary = contract && contract.getSalary(entry);
        const commission = salary && salary.commission;
        const bonuses = entry.result?.bonuses?.filter((b) => !!b.duration) || [];
        const working = entry.type === TimeEntryType.Work;
        const finished = entry.startFinal && entry.endFinal;
        const currBreak =
            entry && entry.startBreak && Math.ceil((new Date().getTime() - entry.startBreak.getTime()) / 60000);
        const active = entry.startFinal && !entry.endFinal;
        const issues = this._issues.filter(
            (issue) => !issue.ignored && getShiftsForIssue(issue).some((shift) => shift.id === entry.id)
        );
        const noShow = issues.some((i) => i.type === IssueType.MissingShiftStart);
        const noLogout = issues.some((i) => i.type === IssueType.MissingShiftEnd);
        const shortBreak = issues.some((i) => i.type === IssueType.DeficientBreak);
        const tooLong = issues.some((i) => i.type === IssueType.ExcessiveWorkDay);
        const statutoryBreak = getStatutoryBreak(entry.durationFinal, app.company!.settings);

        return html`
            <div
                class="center-aligning row border-bottom horizontal layout ${classMap({
                    sunday: new Date(date).getDay() === 0,
                    holiday: !!hol,
                    selected: this._activeDate === date && this._activeEntry === entry.id,
                    absence: !!absence,
                    "absence-start": absence?.start === date,
                    "absence-end": absence?.end === dateAdd(date, { days: 1 }),
                    [entry.status]: true,
                })}"
                id="entry-${entry.id}"
                @click=${() =>
                    entry.type === TimeEntryType.Work
                        ? this._setActive({ date, entry: entry.id })
                        : entry.type === TimeEntryType.HourAdjustment
                          ? this._editHourAdjustment(entry)
                          : entry.type === TimeEntryType.VacationAdjustment
                            ? this._editVacationAdjustment(entry)
                            : absence
                              ? this._editAbsence(absence)
                              : this._setActive({ date, entry: entry.id })}
                @dragenter=${this._dragenter}
                @dragleave=${this._dragleave}
                @dragover=${this._dragover}
                @drop=${(e: DragEvent) => this._dropIntoDay(e, date)}
                draggable="true"
                @dragstart=${(e: DragEvent) => this._dragstart(e, { entry })}
            >
                ${cache(
                    entry.type !== TimeEntryType.Work
                        ? html`<div class="pill status" style="--color-highlight: ${timeEntryTypeColor(entry.type)}">
                              <i class="${app.localized.timeEntryTypeIcon(entry.type)}"></i>
                          </div>`
                        : issues.length
                          ? html`
                                <div class="red pill status">
                                    <i class="exclamation-triangle"></i>
                                </div>
                            `
                          : currBreak
                            ? html`
                                  <div class="orange pill status">
                                      <i class="coffee"></i>
                                  </div>
                              `
                            : finished
                              ? html` <div class="green pill status"><i class="check"></i></div> `
                              : active
                                ? html` <div class="blue pill status"><i class="clock"></i></div> `
                                : html` <div class="pill status"><i class="calendar"></i></div> `
                )}

                <div class="date horizontal layout">
                    ${hol
                        ? html` <div class="stretch ellipsis">${hol.name}</div> `
                        : html`
                              <div class="weekday">
                                  ${["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"][new Date(date).getDay()]}
                              </div>
                              <div class="stretch">${formatDate(date)}</div>
                          `}
                </div>

                <div class="position horizontal center-aligning start-justifying layout">
                    <div
                        class="pill ellipsis fit-horizontally"
                        style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                    >
                        ${entry.position ? entry.position.name : app.localized.timeEntryTypeLabel(entry.type)}
                    </div>
                </div>

                <div class="start" ?hidden=${!working}>
                    <ptc-time-input
                        class="${noShow ? "warn" : ""} ${entry.isPast && !entry.startFinal ? "faded" : ""}"
                        icon="play-circle"
                        name="startFinal"
                        .value=${live(toTimeString(start))}
                        readonly
                        @click=${(e: Event) => this._setActive({ date, entry: entry.id, field: "start" }, undefined, e)}
                    >
                    </ptc-time-input>
                </div>

                <div class="end" ?hidden=${!working}>
                    <ptc-time-input
                        name="endFinal"
                        icon="stop-circle"
                        class="${noShow || noLogout ? "warn" : ""} ${entry.isPast && !entry.endFinal ? "faded" : ""}"
                        .value=${live(toTimeString(end))}
                        readonly
                        @click=${(e: Event) => this._setActive({ date, entry: entry.id, field: "end" }, undefined, e)}
                    >
                    </ptc-time-input>
                </div>

                <div class="break" ?hidden=${!working}>
                    <ptc-time-input
                        name="break"
                        icon="coffee"
                        class="${shortBreak ? "warn" : ""}"
                        .value=${live(
                            typeof entry.break === "number"
                                ? toDurationString(entry.break)
                                : typeof entry.breakPlanned === "number"
                                  ? toDurationString(entry.breakPlanned)
                                  : ""
                        )}
                        readonly
                        .hourDigits=${1}
                        @click=${(e: Event) => this._setActive({ date, entry: entry.id, field: "break" }, undefined, e)}
                        ?disabled=${!entry.final}
                        ${shortBreak
                            ? popover(
                                  html`
                                      Ges. Pause von
                                      <strong>${(statutoryBreak * 60).toFixed(0)} min</strong>
                                      unterschritten!
                                  `,
                                  { trigger: "hover", class: "red tooltip non-interactive" }
                              )
                            : nothing}
                    >
                    </ptc-time-input>
                </div>

                <div class="meals" ?hidden=${!working}>
                    <div class="left icon input">
                        <i class="utensils"></i>
                        <input
                            type="number"
                            min="0"
                            max="3"
                            step="1"
                            name="meals"
                            .value=${(
                                (entry.mealsLunch || 0) +
                                (entry.mealsBreakfast || 0) +
                                (entry.mealsDinner || 0)
                            ).toString()}
                            readonly
                            @click=${(e: Event) =>
                                this._setActive({ date, entry: entry.id, field: "meals" }, undefined, e)}
                        />
                    </div>
                </div>

                <div class="revenue" ?hidden=${!working || !commission} title="Umsatz">
                    <div class="left icon input">
                        <i class="euro-sign"></i>
                        <input
                            type="number"
                            min="0"
                            step="0.01"
                            name="revenue"
                            required
                            .value=${entry.revenue.toFixed(2)}
                            @click=${(e: Event) =>
                                this._setActive({ date, entry: entry.id, field: "revenue" }, undefined, e)}
                        />
                    </div>
                </div>

                <div class="stretch collapse"></div>

                ${entry.comment
                    ? html`
                          <div
                              ${popover(html` <i class="comment"></i> ${entry.comment} `, {
                                  trigger: "hover",
                                  class: "tooltip non-interactive text-left-aligning",
                                  style: "min-width: 20em",
                              })}
                          >
                              <div class="duration">
                                  <i class="semibold comment"></i>
                              </div>
                          </div>
                      `
                    : ""}
                ${bonuses.length
                    ? html`<div
                          ${popover(this._renderBonuses(bonuses), {
                              trigger: "hover",
                              class: "tooltip non-interactive text-left-aligning",
                              style: "min-width: 20em",
                          })}
                      >
                          <div class="duration">
                              <i class="semibold badge-percent"></i>
                          </div>
                      </div>`
                    : ""}

                <div
                    class="duration ${tooLong ? "warn" : ""}"
                    ?hidden=${entry.type === TimeEntryType.VacationAdjustment}
                >
                    <i class="timer"></i>
                    ${toDurationString(entry.result?.base.duration || 0, true)}
                </div>

                <div class="duration" ?hidden=${entry.type !== TimeEntryType.VacationAdjustment}>
                    <i class="${app.localized.timeEntryTypeIcon(TimeEntryType.Vacation)}"></i>
                    ${formatNumber(entry.days || 0, 2)}
                </div>
            </div>
        `;
    }

    private _renderSummary() {
        const displayPay = app.hasPermission("manage.employees.payroll");
        const timeEntries = this._timeEntries.filter(
            (entry) => entry.date >= this.dateFrom && entry.date < this.dateTo
        );
        const nominalHours = getNominalTime(app.company!, this._employee!, this.dateRange!);
        const breakdown = getResultBreakDown(app.company!, timeEntries);
        const payrollReport = getPayrollReport(
            app.company!,
            this._employee!,
            timeEntries,
            this._advances,
            this.dateFrom,
            this.dateTo
        );

        return html`
            <div class="summary">
                <div>
                    <div class="tiny blue colored-text text-centering semibold">Soll</div>

                    <div>${toDurationString(nominalHours)}</div>
                </div>

                <div
                    ${popover(
                        html`
                            <table class="simple fill-horizontally">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="bold">Stunden</th>
                                        <th class="bold">Tage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${breakdown.items.map((item) => {
                                        if (!item.result.base.duration && !item.result.days) {
                                            return;
                                        }
                                        return html`
                                            <tr
                                                style="--color-highlight: ${item.workArea?.position
                                                    ? app.getPositionColor(item.workArea?.position)
                                                    : timeEntryTypeColor(item.type)}"
                                            >
                                                <td class="stretch">
                                                    <div class="colored-text">
                                                        <i class="${app.localized.timeEntryTypeIcon(item.type)}"></i>
                                                        ${item.comment ||
                                                        item.workArea?.position.name ||
                                                        app.localized.timeEntryTypeLabel(item.type)}
                                                    </div>
                                                </td>
                                                <td>${toDurationString(item.result.base.duration)}</td>
                                                <td>${formatFraction(item.result.days)}</td>
                                            </tr>
                                        `;
                                    })}
                                </tbody>
                            </table>
                        `,
                        { trigger: "hover", style: "min-width: 18em;" }
                    )}
                >
                    <div class="tiny blue colored-text text-centering semibold">Ist</div>

                    <div>${toDurationString(breakdown.total.base.duration)}</div>
                </div>

                <div ${popover(this._renderBonuses(breakdown.total.bonuses), { trigger: "hover" })}>
                    <div class="tiny blue colored-text text-centering semibold">Zuschl.</div>

                    <div>
                        ${toDurationString(breakdown.total.bonuses.reduce((total, bonus) => total + bonus.duration, 0))}
                    </div>
                </div>

                ${displayPay && breakdown.total.commission
                    ? html`
                          <div>
                              <div class="tiny blue colored-text text-centering semibold">Umsatz</div>

                              <div>${formatNumber(breakdown.total.commission.revenue)} €</div>
                          </div>
                      `
                    : ""}
                ${displayPay
                    ? html`
                          <div
                              ${popover(
                                  html`
                                      <ptc-payroll-report
                                          .report=${payrollReport}
                                          hideEmployeeInfo
                                          style="min-width: 50em"
                                      ></ptc-payroll-report>
                                  `,
                                  { trigger: "hover" }
                              )}
                          >
                              <div class="tiny blue colored-text text-centering semibold">Lohn</div>

                              <div>${formatNumber(payrollReport.totalPay)} €</div>
                          </div>
                      `
                    : ""}
            </div>
        `;
    }

    private _renderDayMenu() {
        if (!this._employee || !this._activeDate) {
            return;
        }
        return html`
            <ptc-employee-day
                .entries=${this._timeEntries}
                .absences=${this._absences}
                .activeDate=${this._activeDate!}
                .activeEmployee=${this._employee!.id}
                .activeEntry=${this._activeEntry}
                .activeField=${this._activeField}
                .issues=${this._issues}
                .active=${!!this.active && !!this._activeDate}
                hideAvailabilities
                mode="time_sheet"
                @select=${({
                    detail: { date, entry, field, instant },
                }: CustomEvent<{
                    date?: DateString;
                    entry?: string | null;
                    field?: string | null;
                    instant?: boolean;
                }>) =>
                    this._setActive(
                        {
                            date,
                            entry,
                            field,
                        },
                        instant
                    )}
                @addentry=${(e: CustomEvent<Partial<TimeEntry>>) => this._addTimeEntry(e.detail, true)}
                @updateentry=${(e: CustomEvent<TimeEntry>) => this._updateTimeEntry(e.detail)}
                @close=${() => this._setActive({ date: null, entry: null, field: null }, true)}
                @remove=${(e: CustomEvent<{ entry: TimeEntry }>) => this._removeTimeEntry(e.detail.entry)}
                @begindrag=${(e: CustomEvent<{ data: DragData }>) => (this._dragData = e.detail.data)}
            ></ptc-employee-day>
        `;
    }

    render() {
        if (!this._employee) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        return html`
            <div class="fullbleed vertical layout">
                <div class="padded center-aligning horizontal spacing layout">
                    <ptc-date-range-picker
                        @range-selected=${(e: CustomEvent<DateRange>) => this.go(null, e.detail)}
                        .range=${this.dateRange}
                        .maxDays=${70}
                    ></ptc-date-range-picker>

                    <div class="stretch"></div>

                    <button class="transparent slim">
                        <i class="plus"></i>
                    </button>

                    <ptc-popover class="popover-menu" hide-on-click>
                        <button @click=${() => this._addHourAdjustment()} class="small">
                            <i class="book"></i>
                            Zeitbuchung
                        </button>

                        <button @click=${() => this._addVacationAdjustment()} class="small">
                            <i class="calendar"></i>
                            Urlaubsbuchung
                        </button>
                    </ptc-popover>

                    <button
                        class="transparent slim"
                        title="Arbeitszeiten Exportieren"
                        @click=${() =>
                            this.go("exports/time_sheet", {
                                filters: serializeFilters([{ type: "employeeId", value: this._employee!.id }]),
                                ...this.dateRange,
                            })}
                    >
                        <i class="download"></i>
                    </button>

                    ${this._renderSummary()}
                </div>

                <div class="horizontal layout stretch collapse">
                    <ptc-scroller class="stretch collapse">
                        <div class="padded center-aligning vertical layout">
                            <div>
                                ${virtualize({
                                    items: this._items,
                                    renderItem: (item: Item) => this._renderItem(item),
                                })}
                            </div>
                        </div>
                    </ptc-scroller>
                    ${this._renderDayMenu()}
                </div>
            </div>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
