import {
    Venue,
    Issue,
    RosterTab,
    EmployeeStatus,
    toDurationString,
    employeeStatusColor,
    employeeStatusLabel,
    TimeEntry,
    Department,
    Availability,
    dateAdd,
    timeEntryTypeColor,
    getShiftsForIssue,
} from "@pentacode/core";
import { LitElement, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { cache } from "lit/directives/cache.js";
import { classMap } from "lit/directives/class-map.js";
import { keyed } from "lit/directives/keyed.js";
import { popover } from "../directives/popover";
import { app } from "../init";
import { isSafari } from "../lib/util";
import { EmployeeData, DayData, DepartmentData, DragData } from "./roster";

@customElement("ptc-roster-row")
export class RosterRow extends LitElement {
    @property({ attribute: false })
    employee!: EmployeeData;

    @property({ attribute: false })
    days: DayData[] = [];

    @property({ attribute: false })
    department!: DepartmentData;

    @property({ attribute: false })
    venue!: Venue;

    @property({ attribute: false })
    issues!: Issue[];

    @property({ type: Boolean })
    isVisible = false;

    @property()
    activeDate!: string | null;

    @property({ attribute: false })
    activeEmployee!: number | null;

    @property({ attribute: false })
    activeDepartment!: number | null;

    @property()
    activeEntry!: string | null;

    @property({ attribute: false })
    activeTab!: RosterTab;

    @property({ type: Boolean })
    canMoveUp: boolean;

    @property({ type: Boolean })
    canMoveDown: boolean;

    createRenderRoot() {
        return this;
    }

    shouldUpdate(changes: Map<string, unknown>) {
        return changes.has("isVisible") || this.isVisible;
    }

    render() {
        return html`${cache(this.isVisible ? this._render() : "")}`;
    }

    private _render() {
        return html`
            ${this._renderEmployeeHeader(this.employee)} ${this._renderOvertimeBefore()}
            ${this.days.map((day) => this._renderDay(this.department, this.employee, day))}
            ${this._renderOvertimeAfter()}
        `;
    }

    private _dragstart(e: DragEvent, data: DragData) {
        const el = e.target as HTMLElement;
        const imgEl = (el.shadowRoot && (el.shadowRoot.querySelector(".container") as HTMLElement)) || el;
        const dt = e.dataTransfer!;
        dt.setData("text/plain", "42");
        dt.effectAllowed = "all";
        dt.dropEffect = "move";
        data.imageOffset = { x: imgEl.offsetWidth / 2, y: imgEl.offsetHeight / 2 };
        dt.setDragImage(
            imgEl,
            data.imageOffset.x * window.devicePixelRatio,
            data.imageOffset.y * window.devicePixelRatio
        );
        this.classList.add("dragging");
        el.classList.add("dragging");
        this.dispatchEvent(new CustomEvent("begindrag", { detail: { data } }));
    }

    private _dragenter(e: DragEvent) {
        e.preventDefault();
        (e.target as HTMLElement).classList.add("dragover");
    }

    private _dragover(e: DragEvent) {
        e.preventDefault();
        if (!isSafari) {
            e.dataTransfer!.dropEffect = !e.altKey ? "link" : "copy";
        }
    }

    private _dragleave(e: DragEvent) {
        (e.target as HTMLElement).classList.remove("dragover");
    }

    private _renderEmployeeHeader({ employee, timeBalance }: EmployeeData) {
        return html`
            <div
                class="employee-header horizontal start-aligning layout"
                @click=${() => this.dispatchEvent(new CustomEvent("header-clicked"))}
            >
                <div>
                    <ptc-avatar class="small" .employee=${employee}></ptc-avatar>
                </div>

                <div class="stretch" style="padding: 0.35em 0.35em 0.35em 0;">
                    <div class="small employee-name ellipsis">${employee.name}</div>

                    ${employee.status === EmployeeStatus.Active
                        ? html`
                              <ptc-progress
                                  .actual=${timeBalance.actual}
                                  .nominal=${timeBalance.nominal}
                                  class="tiny noprint"
                                  .format=${(n: number) => toDurationString(n, true)}
                              ></ptc-progress>
                          `
                        : html`
                              <div
                                  class="employee-status tiny inverted pill fill-horizontally text-centering ${employeeStatusColor(
                                      employee.status
                                  )}"
                              >
                                  ${employeeStatusLabel(employee.status)}
                              </div>
                          `}
                </div>

                <div class="employee-move-buttons">
                    <button
                        @click=${(e: Event) => {
                            e.stopPropagation();
                            this.dispatchEvent(new CustomEvent("move-up"));
                        }}
                        ?disabled=${!this.canMoveUp}
                    >
                        <i class="caret-up"></i>
                    </button>
                    <button
                        @click=${(e: Event) => {
                            e.stopPropagation();
                            this.dispatchEvent(new CustomEvent("move-down"));
                        }}
                        ?disabled=${!this.canMoveDown}
                    >
                        <i class="caret-down"></i>
                    </button>
                </div>
            </div>
        `;
    }

    private _renderDay(dep: DepartmentData, emp: EmployeeData, day: DayData) {
        const { types } = this.activeTab;
        return day.absence && (!types || types.includes(day.absence.type))
            ? html`
                  <div
                      class=${classMap({
                          "employee-day": true,
                          blocked: day.blocked,
                          today: day.today,
                          active:
                              day.date === this.activeDate &&
                              day.employee === this.activeEmployee &&
                              dep.department.id === this.activeDepartment,
                          sunday: new Date(day.date).getDay() === 0,
                          holiday: !!day.holiday,
                      })}
                      ?disabled=${!app.hasPermission("manage.employees.absences")}
                  >
                      <div
                          class="absence fullbleed centering layout click ${day.absence.start === day.date
                              ? "absence-start"
                              : ""} ${day.absence.end === dateAdd(day.date, { days: 1 }) ? "absence-end" : ""}"
                          style="--color-highlight: ${timeEntryTypeColor(day.absence.type)}"
                          @click=${() =>
                              this.dispatchEvent(
                                  new CustomEvent("edit-absence", { detail: { absence: day.absence! } })
                              )}
                      >
                          ${day.entries.some((e) => e.type === day.absence!.type)
                              ? html` <i class="big ${app.localized.timeEntryTypeIcon(day.absence.type)}"></i> `
                              : ""}
                      </div>
                  </div>
              `
            : html`
                  <div
                      class=${classMap({
                          "employee-day": true,
                          blocked: day.blocked,
                          today: day.today,
                          active:
                              day.date === this.activeDate &&
                              day.employee === this.activeEmployee &&
                              dep.department.id === this.activeDepartment,
                          sunday: new Date(day.date).getDay() === 0,
                          holiday: !!day.holiday,
                      })}
                      @click=${() =>
                          this.dispatchEvent(
                              new CustomEvent("select", {
                                  detail: {
                                      employee: day.employee,
                                      department: dep.department.id,
                                      date: day.date,
                                      entry: null,
                                  },
                              })
                          )}
                      @dragenter=${this._dragenter}
                      @dragleave=${this._dragleave}
                      @dragover=${this._dragover}
                      @drop=${(e: DragEvent) => {
                          if (!day.blocked) {
                              e.preventDefault();
                              this.dispatchEvent(
                                  new CustomEvent("drop-into-day", {
                                      detail: {
                                          dragEvent: e,
                                          employee: emp.employee,
                                          department: dep.department,
                                          date: day.date,
                                      },
                                  })
                              );
                          }
                      }}
                      ?disabled=${day.readonly}
                  >
                      <button
                          ?hidden=${day.blocked || !!day.entries.length || !!day.availabilities.length}
                          class="transparent add-button ${this.activeDepartment === dep.department.id &&
                          this.activeDate === day.date &&
                          this.activeEmployee === day.employee &&
                          this.activeEntry === "new"
                              ? "selected"
                              : ""}"
                          @click=${(e: Event) => {
                              e.stopPropagation();
                              this.dispatchEvent(
                                  new CustomEvent("select", {
                                      detail: {
                                          employee: day.employee,
                                          department: dep.department.id,
                                          date: day.date,
                                          entry: "new",
                                      },
                                  })
                              );
                          }}
                      >
                          <i class="plus"></i>
                      </button>

                      ${day.blocked
                          ? html`
                                <div class="fullbleed centering layout blocked-reason">
                                    <div class="ellipsis stretch padded-light" title="${day.blockedReason}">
                                        ${day.blockedReason}
                                    </div>
                                </div>
                            `
                          : day.availabilities.length && app.settings.rosterDisplayAvailabilities
                            ? this._renderAvailabilities(day)
                            : emp.employee.isBirthDay(day.date)
                              ? html`
                                    <div class="fullbleed centering layout faded">
                                        <i class="birthday-cake big faded"></i>
                                    </div>
                                `
                              : ""}
                      ${day.entries.map((entry) =>
                          this._renderTimeEntry({ entry, department: dep.department, ...day })
                      )}
                  </div>
              `;
    }

    private _renderTimeEntry({
        entry,
        department,
        date,
        blocked,
        stackSize,
        availabilities,
    }: {
        entry: TimeEntry;
        department: Department;
        date: string;
        blocked?: boolean;
        stackSize?: number;
        availabilities?: Availability[];
    }) {
        const departmentId = (entry.position && entry.position.departmentId) || department.id;
        const otherDep = !!entry.position && entry.position.departmentId !== department.id;
        const allowDrag = !otherDep && !blocked;
        const issues = this.issues.filter((issue) => getShiftsForIssue(issue).some((e) => e.id === entry.id));
        const isActive = (this.activeEntry === entry.id && this.activeDepartment === department.id) || entry.preview;

        return app.settings.rosterDisplayMode === "minimal"
            ? html`
                  <div
                      class="minimal-entry small half-padded text-centering ${otherDep
                          ? "colored-text"
                          : "background box"} ${isActive ? "inverted" : ""}"
                      style="--color-highlight: ${app.getTimeEntryColor(entry)};"
                      @click=${(e: MouseEvent) => {
                          e.stopPropagation();
                          this.dispatchEvent(
                              new CustomEvent("select", {
                                  detail: {
                                      date,
                                      employee: entry.employeeId,
                                      department: departmentId,
                                      entry: entry.id,
                                  },
                              })
                          );
                      }}
                  >
                      <i class="${app.localized.timeEntryTypeIcon(entry.type)}"></i>
                  </div>
              `
            : html`
                  <ptc-roster-entry
                      id="entry-${entry.id}-${departmentId}"
                      draggable="${allowDrag ? "true" : "false"}"
                      .entry=${entry}
                      .error=${!!issues.length}
                      .department=${department}
                      .venue=${this.venue}
                      .stackSize=${stackSize}
                      .condensed=${app.settings.rosterDisplayMode === "compact"}
                      class="tiny ${isActive ? "selected" : ""}"
                      @remove=${() => this.dispatchEvent(new CustomEvent("remove-entry", { detail: entry }))}
                      @dragstart=${(e: DragEvent) => this._dragstart(e, { entry })}
                      @select=${({ detail: { field } }: CustomEvent<{ field: string }>) =>
                          this.dispatchEvent(
                              new CustomEvent("select", {
                                  detail: {
                                      date,
                                      employee: entry.employeeId,
                                      department: departmentId,
                                      entry: entry.id,
                                      field,
                                  },
                              })
                          )}
                      style="margin-top: ${Math.min(stackSize || 1, 3) + 2}px; opacity: 0;"
                      .animated=${true}
                      .availabilities=${availabilities}
                  ></ptc-roster-entry>
              `;
    }

    private _renderAvailabilities(day: DayData) {
        const avs = day.availabilities;
        return app.settings.rosterDisplayMode === "minimal"
            ? html`
                  <div class="fullbleed availabilities">
                      ${avs[0]
                          ? this._renderAvailabilityMinimal(
                                avs[0],
                                avs[0].start || avs[0].end ? "cutoff-bottomright" : ""
                            )
                          : ""}
                      ${avs[1]
                          ? this._renderAvailabilityMinimal(avs[1], avs[1].start || avs[1].end ? "cutoff-topleft" : "")
                          : ""}
                  </div>
              `
            : this._renderAvailabilitiesFull(day.availabilities);
    }

    private _renderAvailabilityMinimal(av: Availability, cutoff: "" | "cutoff-bottomright" | "cutoff-topleft") {
        return html`
            <div class="stretching">
                ${keyed(
                    av.id,
                    html`<div
                        class="fullbleed click centering layout availability-icon ${cutoff}"
                        style="--color-highlight: ${av.color}"
                        ${av.comment
                            ? popover(
                                  html`<div class="subtle smaller">
                                      <div class="bold">${av.fullLabel}</div>
                                      <p><i class="comment"></i>${av.comment}</p>
                                  </div>`,
                                  {
                                      trigger: "hover",
                                      alignment: "bottom",
                                  }
                              )
                            : ""}
                    >
                        <div class="fullbleed click centering layout availability-icon">
                            <i class="${av.icon}"></i>
                        </div>
                    </div>`
                )}
            </div>
        `;
    }

    private _renderAvailabilitiesFull(avs: Availability[]) {
        return html`
            <div class="fullbleed vertical stretching layout availabilities">
                ${avs.map(
                    (av) => html`
                        ${keyed(
                            av.id,
                            html`<div
                                class="click stretch relative centering layout availability"
                                style="--color-highlight: ${av.color};"
                                ${av.comment
                                    ? popover(
                                          html`<div class="subtle smaller">
                                              <div class="bold">${av.fullLabel}</div>
                                              <p><i class="comment"></i>${av.comment}</p>
                                          </div>`,
                                          {
                                              trigger: "hover",
                                              alignment: "bottom",
                                          }
                                      )
                                    : ""}
                            >
                                <div class="smaller colored-text">
                                    <i class="${av.icon}"></i> ${av.label}
                                    ${av.comment ? html`<i class="comment"> </i>` : ""}
                                </div>
                            </div>`
                        )}
                    `
                )}
            </div>
        `;
    }

    private _renderOvertimeBefore() {
        return html`
            <div class="balance-before" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                <ptc-balance
                    .value=${this.employee.timeBalance.reset ?? this.employee.timeBalance.carry}
                    .format=${(val: number) => (val >= 10 ? Math.round(val) : toDurationString(val, true))}
                ></ptc-balance>
            </div>
        `;
    }

    private _renderOvertimeAfter() {
        return html`
            <div class="balance-after" ?hidden=${!app.settings.rosterDisplayTimeBalances}>
                <ptc-balance
                    .value=${this.employee.timeBalance.balance}
                    .format=${(val: number) => (val >= 10 ? Math.round(val) : toDurationString(val, true))}
                ></ptc-balance>
            </div>
        `;
    }
}
