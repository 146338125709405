import { Hours, add } from "@pentacode/openapi";
import { getShiftIssues } from "./issues";
import {
    Absence,
    AbsenceStatus,
    Availability,
    AvailabilityStatus,
    Company,
    Contract,
    Employee,
    EmployeeStatus,
    TimeBalance,
    TimeEntry,
    TimeEntryType,
    TimeFilter,
} from "./model";
import { dateAdd } from "./util";
import { DateRange } from "./time";

export interface EmployeeData {
    employee: Employee;
    contract: Contract;
    timeBalance: TimeBalance;
}

export type AutoAssignContext = {
    company: Company;
    employees: EmployeeData[];
    entries: TimeEntry[];
    absences: Absence[];
    availabilities: Availability[];
    range: DateRange;
    departments: number[];
    time?: TimeFilter;
    weights: AutoAssignCriteria;
    reassignEntries: boolean;
};

export type AutoAssignCriteria = {
    assignAll: number;
    fillQuota: number;
    avoidOvertime: number;
    reduceAccumulatedOvertime: number;
    minimizeCosts: number;
    considerAvailabilities: number;
    avoidProblems: number;
};

type AutoAssignScores = AutoAssignCriteria;

function isEmployeeAvailable(
    { employee: e }: EmployeeData,
    entry: TimeEntry,
    { entries, absences, departments }: AutoAssignContext
) {
    // const log = e.id === 29165 && entry.date === "2022-10-24";
    const log = false;

    log &&
        console.log(
            "checking employee ",
            e.name,
            entry.position?.name,
            entry.date,
            entry.startPlanned,
            entry.endPlanned,
            entry
        );

    if (departments && !e.positions.some((p) => departments?.includes(p.departmentId))) {
        log && console.log("employee is not working in this department");
        return false;
    }

    if (e.status !== EmployeeStatus.Active) {
        log && console.log("employee is not active");
        return false;
    }

    if (!e.positions.some((p) => entry.positionId === p.id)) {
        log && console.log("employee is not qualified");
        return false;
    }

    if (e.getContractForDate(entry.date)?.blocked === true) {
        log && console.log("employee does not have a valid contract");
        return false;
    }

    const overlapping = entries.find((en) => !en.deleted && en.employeeId === e.id && en.overlapsWith(entry));
    if (overlapping) {
        log &&
            console.log(
                "employee is already assigned",
                overlapping.date,
                overlapping.startPlanned,
                overlapping.endPlanned,
                overlapping
            );
        return false;
    }

    if (
        absences.some(
            (a) =>
                a.employeeId === e.id &&
                [AbsenceStatus.Approved, AbsenceStatus.Inferred].includes(a.status) &&
                a.start <= entry.date &&
                a.end > entry.date
        )
    ) {
        log && console.log("employee is absent");
        return false;
    }

    // const available = employees.filter(
    //     (e) =>
    //         e.status === EmployeeStatus.Active &&
    //         e.positions.some((p) => entry.positionId === p.id) &&
    //         e.getContractForDate(entry.date)?.blocked === false &&
    //         !entries.some((en) => en.employeeId === e.id && en.overlapsWith(entry)) &&
    //         !absences.some((a) => a.start <= entry.date && a.end > entry.date)
    // );

    return true;
}

function getEmployeeScore(
    entry: TimeEntry,
    emp: EmployeeData,
    context: AutoAssignContext,
    assignableEntries: Map<number, TimeEntry[]>
): AutoAssignScores {
    const weekFactor = context.company.settings.weekFactor || 4.35;
    const scores: AutoAssignScores = {
        assignAll: 0,
        fillQuota: 0,
        avoidOvertime: 0,
        reduceAccumulatedOvertime: 0,
        minimizeCosts: 0,
        considerAvailabilities: 0,
        avoidProblems: 0,
    };

    scores.fillQuota = Math.max(0, emp.timeBalance.nominal - emp.timeBalance.actual);
    scores.avoidOvertime = Math.max(0, emp.timeBalance.actual - emp.timeBalance.nominal);
    scores.assignAll = assignableEntries.get(emp.employee.id)?.filter((e) => e.date === entry.date).length || 0;

    const availabilites = context.availabilities.filter(
        (av) =>
            av.date === entry.date &&
            av.employeeId === emp.employee.id &&
            entry.matchesTimeFilter({
                from: av.start,
                to: av.end,
                fromRule: "open",
                toRule: "open",
            })
    );

    scores.considerAvailabilities = availabilites.reduce((total, av) => {
        switch (av.status) {
            case AvailabilityStatus.Unavailable:
                return total - 2;
            case AvailabilityStatus.Unpreferred:
                return total - 1;
            case AvailabilityStatus.Available:
                return total + 1;
            case AvailabilityStatus.Preferred:
                return total + 2;
        }
    }, 0);

    if (emp.contract) {
        const salary =
            // Try to find position-specific salary first
            (entry.position && emp.contract?.salaries.find((salary) => salary.positionId === entry.position!.id)) ||
            // Otherwise choose default salary (no position attached)
            emp.contract.salaries.find((salary) => salary.positionId === null);

        if (salary) {
            const rate =
                salary.type === "hourly"
                    ? salary.amount
                    : emp.contract.nominalWeeklyHours
                      ? salary.amount / weekFactor / emp.contract.nominalWeeklyHours
                      : 0;
            scores.minimizeCosts = rate;
        }
    }

    const issuesBefore = getShiftIssues(emp.employee, context.company, context.entries, {
        from: entry.date,
        to: dateAdd(entry.date, { days: 1 }),
    });
    entry.employeeId = emp.employee.id;
    const issuesAfter = getShiftIssues(emp.employee, context.company, context.entries, {
        from: entry.date,
        to: dateAdd(entry.date, { days: 1 }),
    });
    scores.avoidProblems = issuesAfter.length - issuesBefore.length;
    entry.employeeId = null;

    return scores;
}

function normalizeScores(scores: Map<number, AutoAssignScores>) {
    const normalized = new Map<number, AutoAssignScores>();

    const min: AutoAssignScores = {
        assignAll: 0,
        fillQuota: 0,
        avoidOvertime: 0,
        reduceAccumulatedOvertime: 0,
        minimizeCosts: 0,
        considerAvailabilities: 0,
        avoidProblems: 0,
    };
    const max: AutoAssignScores = {
        assignAll: 0,
        fillQuota: 0,
        avoidOvertime: 0,
        reduceAccumulatedOvertime: 0,
        minimizeCosts: 0,
        considerAvailabilities: 0,
        avoidProblems: 0,
    };
    for (const score of scores.values()) {
        for (const [key, value] of Object.entries(score) as [keyof AutoAssignScores, number][]) {
            min[key] = Math.min(value, min[key]);
            max[key] = Math.max(value, max[key]);
        }
    }

    for (const [empId, score] of scores.entries()) {
        const norm: AutoAssignScores = {
            assignAll: 0,
            fillQuota: 0,
            avoidOvertime: 0,
            reduceAccumulatedOvertime: 0,
            minimizeCosts: 0,
            considerAvailabilities: 0,
            avoidProblems: 0,
        };
        for (const [key, value] of Object.entries(score) as [keyof AutoAssignScores, number][]) {
            norm[key] = max[key] - min[key] === 0 ? 0 : (value - min[key]) / (max[key] - min[key]);
        }
        normalized.set(empId, norm);
    }

    return normalized;
}

function getFinalScores(scores: Map<number, AutoAssignScores>, weights: AutoAssignCriteria) {
    const final = new Map<number, number>();
    for (const [key, value] of scores.entries()) {
        final.set(
            key,
            Object.entries(value).reduce((total, [k, v]) => total + v * weights[k as keyof AutoAssignCriteria], 0)
        );
    }
    return final;
}

function findEmployee(
    entry: TimeEntry,
    available: EmployeeData[],
    context: AutoAssignContext,
    assignableEntries: Map<number, TimeEntry[]>
): EmployeeData | null {
    const scores = available.reduce(
        (all, emp) => all.set(emp.employee.id, getEmployeeScore(entry, emp, context, assignableEntries)),
        new Map<number, AutoAssignScores>()
    );

    const normalized = normalizeScores(scores);

    const finalScores = getFinalScores(normalized, context.weights);

    available.sort((a, b) => finalScores.get(b.employee.id)! - finalScores.get(a.employee.id)!);

    return available[0];
}

export function autoAssign(context: AutoAssignContext) {
    const entries = context.entries
        .filter(
            (e) =>
                !e.deleted &&
                e.type === TimeEntryType.Work &&
                !e.startFinal &&
                !e.endFinal &&
                (!context.departments ||
                    !e.position?.departmentId ||
                    context.departments.includes(e.position?.departmentId)) &&
                e.date >= context.range.from &&
                e.date < context.range.to &&
                (!context.time || e.matchesTimeFilter(context.time))
        )
        .sort((a, b) => b.duration - a.duration);

    if (context.reassignEntries) {
        entries.forEach((e) => (e.employeeId = null));
        context.employees.forEach((e) => (e.timeBalance.actual = 0 as Hours));
    }

    const unassigned = entries.filter((e) => !e.employeeId);
    const availableEmployees = new Map<string, EmployeeData[]>();
    const availableEntries = new Map<number, TimeEntry[]>();

    for (const entry of unassigned) {
        const avEmp = context.employees.filter((e) => isEmployeeAvailable(e, entry, context));
        availableEmployees.set(entry.id, avEmp);
        for (const emp of avEmp) {
            if (!availableEntries.has(emp.employee.id)) {
                availableEntries.set(emp.employee.id, []);
            }
            availableEntries.get(emp.employee.id)!.push(entry);
        }
    }

    while (unassigned.length) {
        unassigned.sort((a, b) => availableEmployees.get(a.id)!.length - availableEmployees.get(b.id)!.length);
        const entry = unassigned.shift()!;
        const emp = findEmployee(entry, availableEmployees.get(entry.id)!, context, availableEntries);
        if (emp) {
            entry.employeeId = emp.employee.id;
            emp.timeBalance.actual = add(emp.timeBalance.actual, entry.duration);
            const otherAvailableEntries = availableEntries.get(emp.employee.id)!;

            availableEntries.set(
                emp.employee.id,
                otherAvailableEntries.filter((e) => !e.overlapsWith(entry))
            );

            // Remove employee from available employees for overlapping shifts
            for (const overlapping of otherAvailableEntries.filter((e) => e.overlapsWith(entry))) {
                const after = availableEmployees.get(overlapping.id)?.filter((e) => e.employee.id !== emp.employee.id);
                availableEmployees.set(overlapping.id, after || []);
            }
        }
    }
}
