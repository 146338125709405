import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { getIssueMessage, Issue, IssueShiftDetails, IssueType } from "@pentacode/core/src/issues";
import { employmentTypeLabel, TimeEntryType, timeEntryTypeColor } from "@pentacode/core/src/model";
import { formatDateShort, formatNumber, toTimeString } from "@pentacode/core/src/util";
import { app } from "../init";
import { shared } from "../styles";
import "./avatar";
import "./spinner";

@customElement("ptc-issue")
export class IssueEl extends LitElement {
    @property({ attribute: false })
    issue: Issue;

    static styles = [
        shared,
        css`
            :host {
                display: block;
                padding: 0.5em;
            }

            .venue-icon {
                font-size: var(--font-size-large);
                margin: 0 0.3em;
            }

            .venue-name {
                font-weight: 600;
                margin: 0 2em 0 1em;
            }

            .detail.box {
                padding: 0.3em 0.5em 0.1em 0.5em;
            }
        `,
    ];

    render() {
        const issue = this.issue;

        switch (issue.type) {
            case IssueType.PendingDailyRevenues:
            case IssueType.UnbalancedDailyCash: {
                const venue = app.getVenue(issue.details.venueId);
                if (!venue) return;

                return html`
                    <div class="issue center-aligning horizontal layout stretch">
                        <i class="coins venue-icon"></i>

                        <div class="venue-name">${venue.name}</div>

                        <div class="smaller red padded box">${getIssueMessage(issue.type)}</div>
                    </div>
                `;
            }
            case IssueType.DeficientWages:
            case IssueType.ExcessiveWages:
            case IssueType.MissingShiftStart:
            case IssueType.MissingShiftEnd:
            case IssueType.MissingContract:
            case IssueType.DeficientBreak:
            case IssueType.DeficientIdlePeriod:
            case IssueType.ExcessiveWorkDay:
            case IssueType.OverlappingShifts: {
                const employee = app.getEmployee(issue.details.employeeId);
                if (!employee) return null;
                const contract = employee.getContractForDate(issue.date);

                return html`
                    <div class="center-aligning spacing horizontal layout stretch">
                        <ptc-avatar .employee=${employee} class="mediumer right-margined"></ptc-avatar>

                        <div style="width: 10em">
                            <div class="semibold ellipsis">${employee.name}</div>
                            <div class="tinier pill">${contract && employmentTypeLabel(contract.employmentType)}</div>
                        </div>

                        ${this._renderEmployeeIssueDetails(issue)}

                        <i class="arrow-right stretch"></i>

                        <div class="smaller padded red box">${getIssueMessage(issue.type)}</div>
                    </div>
                `;
            }
        }
    }

    private _renderEmployeeIssueDetails(issue: Issue) {
        switch (issue.type) {
            case IssueType.DeficientWages:
                return html`
                    <div class="detail box">
                        <div class="tinier">Gehalt</div>
                        <div>${formatNumber(issue.details.wages)} €</div>
                    </div>

                    <div class="detail box">
                        <div class="tinier">Arbeitszeit</div>
                        <div>${formatNumber(issue.details.hours)} St.</div>
                    </div>

                    <div class="detail box">
                        <div class="tinier">Stundenlohn</div>
                        <div>${formatNumber(issue.details.effectiveHourlyRate)} € / St.</div>
                    </div>
                `;
            case IssueType.ExcessiveWages:
                return html`
                    <div class="detail box">
                        <div class="tinier">Lohngrenze</div>
                        <div>${formatNumber(issue.details.maxWages)} €</div>
                    </div>

                    <div class="detail box">
                        <div class="tinier">Lohn</div>
                        <div>${formatNumber(issue.details.wages)} €</div>
                    </div>
                `;
            case IssueType.MissingShiftStart:
            case IssueType.MissingShiftEnd:
            case IssueType.MissingContract:
            case IssueType.DeficientBreak:
                return this._renderShift(issue.details.shift);
            case IssueType.DeficientIdlePeriod:
            case IssueType.ExcessiveWorkDay:
            case IssueType.OverlappingShifts:
                return issue.details.shifts.map((shift) => this._renderShift(shift));
        }
    }

    private _renderShift(shift: IssueShiftDetails) {
        const position = (shift.positionId && app.getPosition(shift.positionId)?.position) || null;
        return html`
            <div
                class="detail box"
                style="--color-highlight: ${position ? app.getPositionColor(position) : timeEntryTypeColor(shift.type)}"
            >
                ${shift.type === TimeEntryType.Work
                    ? html`
                          <div class="tinier spacing horizontal layout">
                              <div class="stretch collapse ellipsis">${position?.name}</div>
                              <div>${formatDateShort(shift.date, true, false)}</div>
                          </div>
                          <div>
                              ${toTimeString(shift.start) || html` &nbsp;&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp; `} -
                              ${toTimeString(shift.end) || html` &nbsp;&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp; `}
                          </div>
                      `
                    : html` <div class="padded">${app.localized.timeEntryTypeLabel(shift.type)}</div> `}
            </div>
        `;
    }
}
