import "./help";
import {
    Employee,
    Contract,
    Salary,
    VacationIncrement,
    EmploymentType,
    Position,
    Department,
    Venue,
    Benefit,
    NominalHoursMode,
    AbsenceMode,
    employmentTypeLabel,
    getEmploymentTypes,
    Bonus,
    BonusType,
    BenefitType,
    BonusCompType,
} from "@pentacode/core/src/model";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { shared, mixins } from "../styles";
import { app } from "../init";
import { Checkbox } from "./checkbox";
import { DateInput } from "./date-input";
import type { Drawer } from "./drawer";
import "./drawer";
import { formatDate, formatNumber, toDurationString } from "@pentacode/core/src/util";
import "./popover";
import "./select";
import { getMaxPay } from "@pentacode/core/src/salary";
import "./bonus-type-item";
import { DateString, Days, Euros, Hours, Percent, Rate } from "@pentacode/openapi";
import { repeat } from "lit/directives/repeat.js";

@customElement("ptc-contract-form")
export class ContractForm extends LitElement {
    static styles = [
        shared,
        Checkbox.styles,
        DateInput.styles,
        css`
            :host {
                display: block;
                position: relative;
            }

            h2 {
                margin-bottom: 0.5em;
            }

            h2 button {
                line-height: normal;
            }

            .fields {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
                grid-gap: 0 0.5em;
            }

            .fields .field {
                margin: 0;
            }

            .field > input,
            .field .input > input,
            .field select {
                padding-top: 0.8em;
                padding-bottom: 0.8em;
            }

            th,
            td {
                padding: 0.2em;
            }

            th {
                font-weight: 600;
                text-align: center;
            }

            .salary-pay {
                display: flex;
            }

            .salary-pay .input {
                min-width: 8em;
            }

            .add-salary {
                grid-column: span 4;
            }

            .salary-pay input {
                border-radius: var(--border-radius) 0 0 var(--border-radius);
            }

            .salary-type {
                font-weight: 600;
                border-left: none;
                border-radius: 0 var(--border-radius) var(--border-radius) 0;
            }

            .salary-positions-menu {
                max-height: 300px;
                ${mixins.scroll()};
            }

            form .field input[type="checkbox"] {
                margin: 5px;
            }

            .field > input[type="number"],
            .input > input[type="number"] {
                text-align: right;
            }

            input[readonly],
            select[readonly],
            ptc-checkbox[readonly],
            ptc-checkbox-button[readonly] {
                pointer-events: none;
            }

            .workdays.horizontal.layout > .field:not(:last-child) {
                margin-right: 0.2em;
            }

            .workdays .field > input {
                width: 100%;
                text-align: center;
                padding-left: 0.2em;
                padding-right: 0.2em;
            }

            .workdays .field label {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
            }

            .bonus .input,
            .benefit .input {
                width: 8em;
            }
        `,
    ];

    @property({ attribute: false })
    contract: Contract | null = null;

    @property({ attribute: false })
    employee: Employee | null = null;

    @property({ reflect: true, type: Boolean })
    readonly: boolean = false;

    @property({ type: Boolean })
    initial: boolean = false;

    @state()
    private _salaries: Salary[] = [];

    @state()
    private _benefits: Benefit[] = [];

    @state()
    private _bonuses: Bonus[] = [];

    @state()
    private _expandedSections = new Set<string>();

    @query("form")
    private _form: HTMLFormElement;

    get data(): Partial<Contract> & { initialHours: Hours; initialVacationDays: Days } {
        if (!this._form) {
            return { initialHours: 0 as Hours, initialVacationDays: 0 as Days };
        }

        const formData = new FormData(this._form);

        const salaryPositions = formData.getAll("salaryPosition");
        const salaryIds = formData.getAll("salaryId");
        const salaryAmounts = formData.getAll("salaryAmount");
        const salaryTypes = formData.getAll("salaryType");
        const salaryCommissions = formData.getAll("salaryCommission");
        const accountingSalaryConfigs = formData.getAll("accountingSalaryConfig");

        const salaries: Salary[] = [];
        for (const [i, positionId] of salaryPositions.entries()) {
            const ast = accountingSalaryConfigs[i];

            salaries.push(
                Object.assign(new Salary(), {
                    id: salaryIds[i] ? parseInt(salaryIds[i] as string) : undefined,
                    positionId: positionId === "" ? null : parseInt(positionId as string),
                    amount: parseFloat(salaryAmounts[i] as string),
                    type: salaryTypes[i] as "monthly" | "hourly",
                    commission: parseFloat(salaryCommissions[i] as string),
                    accountingSalaryConfigId: ast === "" ? null : parseInt(ast as string),
                })
            );
        }

        const benefitIds = formData.getAll("benefitId") as string[];
        const benefitTypeIds = formData.getAll("benefitTypeId") as string[];
        const benefitAmounts = formData.getAll("benefitAmount") as string[];
        const benefits: Benefit[] = [];

        for (const [i, id] of benefitIds.entries()) {
            benefits.push(
                new Benefit({
                    id: id ? Number(id) : undefined,
                    typeId: Number(benefitTypeIds[i]),
                    amount: Number(benefitAmounts[i]) as Euros,
                })
            );
        }

        const bonusIds = formData.getAll("bonusId");
        const bonusTypeIds = formData.getAll("bonusTypeId");
        const bonusPercentages = formData.getAll("bonusPercent");
        const bonusHourlyRates = formData.getAll("bonusHourlyRate");
        const bonusFixedAmounts = formData.getAll("bonusFixedAmount");
        const bonusPositions = formData.getAll("bonusPosition");

        const bonuses: Bonus[] = [];
        for (const [i, typeId] of bonusTypeIds.entries()) {
            bonuses.push(
                new Bonus({
                    typeId: Number(typeId),
                    id: bonusIds[i] ? Number(bonusIds[i]) : undefined,
                    percent: Number(bonusPercentages[i]) as Percent,
                    hourlyRate: Number(bonusHourlyRates[i]) as Rate<Euros, Hours>,
                    fixedAmount: Number(bonusFixedAmounts[i]) as Euros,
                    positionId: bonusPositions[i] ? Number(bonusPositions[i]) : null,
                })
            );
        }

        const initialHours = parseFloat(formData.get("initialHours") as string) as Hours;
        const initialVacationDays = parseFloat(formData.get("initialVacationDays") as string) as Days;

        const bonusNight1 = formData.get("bonusNight1") as string;
        const bonusNight2 = formData.get("bonusNight2") as string;
        const bonusSunday = formData.get("bonusSunday") as string;
        const bonusHoliday = formData.get("bonusHoliday") as string;
        const bonusSpecial = formData.get("bonusSpecial") as string;

        const absenceHours = formData.get("absenceHours") as string;

        const enableSFNLedger = formData.has("enableSFNLedger");

        return {
            id: this.contract ? this.contract.id : undefined,
            start: formData.get("start") as DateString,
            inclusiveEnd: (formData.get("inclusiveEnd") as DateString) || null,
            employmentType: parseInt(formData.get("employmentType") as string),
            nominalHoursMode: parseInt(formData.get("nominalHoursMode") as string) as NominalHoursMode,
            hoursPerDay: formData.getAll("hoursPerDay").map((day) => (day && Number(day)) || 0) as Hours[],
            hoursPerWeek: Number(formData.get("hoursPerWeek") || 0) as Hours,
            absenceHours: ((absenceHours && parseFloat(absenceHours)) || 0) as Hours,
            absenceMode: parseInt(formData.get("absenceMode") as string) as AbsenceMode,
            annualTimeSheet: formData.has("annualTimeSheet"),
            enableSFNLedger,
            sfnAdvance: enableSFNLedger
                ? (parseFloat((formData.get("sfnAdvance") as string) || "0") as Euros)
                : (0 as Euros),
            maxSalary: parseFloat((formData.get("maxSalary") as string) || "0") as Euros,
            vacationDays: parseFloat(formData.get("vacationDays") as string) as Days,
            vacationIncrement: parseInt(formData.get("vacationIncrement") as string),
            stackBonuses: formData.has("stackBonuses"),
            salaries,
            initialHours: (isNaN(initialHours) ? 0 : initialHours) as Hours,
            initialVacationDays: (isNaN(initialVacationDays) ? 0 : initialVacationDays) as Days,
            bonusNight1: ((bonusNight1 && parseFloat(bonusNight1)) || 0) as Percent,
            bonusNight2: ((bonusNight2 && parseFloat(bonusNight2)) || 0) as Percent,
            bonusSunday: ((bonusSunday && parseFloat(bonusSunday)) || 0) as Percent,
            bonusHoliday: ((bonusHoliday && parseFloat(bonusHoliday)) || 0) as Percent,
            bonusSpecial: ((bonusSpecial && parseFloat(bonusSpecial)) || 0) as Percent,
            comment: formData.get("comment") as string,
            blocked: formData.has("blocked"),
            ignoreIssues: (this.contract && this.contract.ignoreIssues) || [],
            benefits,
            bonuses,
        };
    }

    private get _allPositions() {
        const positions: { position: Position; department: Department; venue: Venue }[] = [];

        for (const venue of (app.company && app.company.venues) || []) {
            for (const department of venue.departments) {
                for (const position of department.positions) {
                    positions.push({ position, department, venue });
                }
            }
        }
        return positions;
    }

    private get _availablePositions() {
        return this._allPositions.filter(
            ({ position }) =>
                // positions that the employee is allowed to work in
                this.employee?.positions.some((p) => p.id === position.id) &&
                // positions that don't have a salary yet
                !this._salaries.some((s) => s.positionId === position.id)
        );
    }

    checkValidity() {
        return this._form.checkValidity();
    }

    reportValidity() {
        return this._form.reportValidity();
    }

    openAtSection(section: string) {
        this._expandedSections.clear();
        this._toggleSection(section);
    }

    private _toggleSection(section: string) {
        this._expandedSections.clear();
        this._expandedSections.add(section);
        this.requestUpdate();
    }

    private _change() {
        this.requestUpdate();
        this.dispatchEvent(new CustomEvent("change"));
    }

    private _addSalary(position: Position) {
        const contract = this.contract!;

        const salary =
            (contract.salaries && contract.salaries.find((s) => s.positionId === position.id)) ||
            Object.assign(new Salary(), { positionId: position.id });
        this._salaries.push(salary);
        this.requestUpdate();
    }

    private _deleteSalary(salary: Salary) {
        this._salaries = this._salaries.filter((s) => s.positionId !== salary.positionId);
    }

    private _submit(e: FocusEvent) {
        e.preventDefault();
    }

    private _addBenefit(type: BenefitType) {
        this._benefits.push(
            new Benefit({
                typeId: type.id,
                amount: 0 as Euros,
            })
        );
        this.requestUpdate();
    }

    private _removeBenefit(i: number) {
        this._benefits.splice(i, 1);
        this.requestUpdate();
    }

    private _hoursPerDayChanged() {
        const { nominalHoursMode, hoursPerDay } = this.data;

        if (
            hoursPerDay &&
            [NominalHoursMode.FixedDays, NominalHoursMode.FixedDaysWithoutHolidays].includes(nominalHoursMode!)
        ) {
            (this.renderRoot.querySelector(`[name="hoursPerWeek"]`) as HTMLInputElement).value = hoursPerDay
                .reduce((total, day) => total + day, 0)
                .toString();
        }
    }

    private _nominalHoursModeChanged() {
        const { absenceMode, nominalHoursMode } = this.data;

        this._hoursPerDayChanged();

        if (
            ![NominalHoursMode.FixedDays, NominalHoursMode.FixedDaysWithoutHolidays].includes(nominalHoursMode!) &&
            absenceMode === AbsenceMode.FixedDays
        ) {
            (this.renderRoot.querySelector(`[name="absenceMode"]`) as HTMLSelectElement).value =
                AbsenceMode.Average.toString();
        }

        this.requestUpdate();
    }

    private _resizeDrawers() {
        for (const drawer of this.renderRoot.querySelectorAll("ptc-drawer") as NodeListOf<Drawer>) {
            drawer.updateInnerSize();
        }
    }

    private async _employmentTypeChanged() {
        this.requestUpdate();
        await this.updateComplete;
        const { employmentType, start } = this.data;
        const maxPay = getMaxPay(employmentType!, start, app.company!);
        (this.renderRoot.querySelector(`[name="maxSalary"]`) as HTMLInputElement).value = maxPay?.toString() || "";
        this._resizeDrawers();
    }

    private _addBonus(type: BonusType) {
        const bonus = new Bonus({
            typeId: type.id,
            percent: type.defaultPercent,
            hourlyRate: type.fixedHourlyRateDefault,
            fixedAmount: type.fixedAmountDefault,
        });

        this._bonuses.push(bonus);
        this.requestUpdate();
    }

    private _removeBonus(i: number) {
        this._bonuses.splice(i, 1);
        this.requestUpdate();
    }

    shouldUpdate() {
        return !!this.contract;
    }

    updated(changes: Map<string, unknown>) {
        [
            ...this.renderRoot.querySelectorAll(
                "input, textarea, select, button, ptc-checkbox, ptc-checkbox-button, ptc-select"
            ),
        ].forEach((el: HTMLElement) => {
            if (this.readonly) {
                el.setAttribute("readonly", "");
            } else {
                el.removeAttribute("readonly");
            }
        });

        this.renderRoot.querySelectorAll("ptc-drawer").forEach((drawer: Drawer) => drawer.updateInnerSize());

        if (!changes.has("contract")) {
            return;
        }

        const { salaries, benefits, bonuses, hoursPerDay, ...rest } = this.contract!;

        const hoursPerDayInputs = [...this.renderRoot.querySelectorAll(`[name="hoursPerDay"]`)] as HTMLInputElement[];

        for (const [prop, value] of Object.entries({ ...rest, initialHours: 0, initialVacationDays: 0 })) {
            const inputs = this.renderRoot.querySelectorAll(`[name=${prop}]`) as NodeListOf<HTMLInputElement>;
            for (const inp of inputs) {
                switch (inp.type) {
                    case "radio":
                        inp.checked = inp.value === (value as any).toString();
                        break;
                    case "checkbox":
                        inp.checked = value as boolean;
                        break;
                    default:
                        inp.value =
                            value === null || value === undefined
                                ? ""
                                : inp.type === "number" && inp.step === "0.01"
                                  ? (value as number).toFixed(2)
                                  : (value as any).toString();
                        break;
                }
            }
        }

        if (hoursPerDay) {
            hoursPerDay.forEach((val, i) => (hoursPerDayInputs[i].value = val.toFixed(2)));
        } else {
            hoursPerDayInputs.forEach((inp) => (inp.value = ""));
        }

        this._salaries = salaries || [];
        this._benefits = benefits || [];
        this._bonuses = bonuses || [];
    }

    render() {
        if (!this.contract) {
            return html``;
        }

        const salaries = this._salaries;

        const defaultSalary = salaries.find((s) => s.positionId === null) || new Salary();
        const otherSalaries = salaries.filter((s) => s.positionId !== null);

        const hideAccountingSalaryConfigField = salaries.every((salary) => !salary.accountingSalaryConfigId);

        const accountingSalaryConfigs = app.company ? app.company.settings.accounting.salaryConfigs : [];
        const data = this.data;

        const fixedDays = [NominalHoursMode.FixedDays, NominalHoursMode.FixedDaysWithoutHolidays].includes(
            data.nominalHoursMode!
        );

        const maxPay = getMaxPay(data.employmentType!, data.start, app.company!);

        const availableBonusTypes =
            app.company?.bonusTypes.filter((t) => !this._bonuses.some((b) => b.typeId === t.id)) || [];

        const availableBenefitTypes =
            app.company?.benefitTypes.filter((t) => !this._benefits.some((b) => b.typeId === t.id)) || [];

        return html`
            <form @submit=${this._submit} @change=${this._change}>
                <div class="border-bottom">
                    <button
                        type="button"
                        class="larger small-caps fill-horizontally transparent text-left-aligning"
                        @click=${() => this._toggleSection("general")}
                    >
                        <div class="horizontal spacing center-aligning layout">
                            <div class="stretch"><i class="info-circle"></i> Allgemein</div>
                            <div class="tiny pills regular-style">
                                <div class="pill">${employmentTypeLabel(this.data.employmentType!)}</div>
                                <div class="pill">
                                    <i class="calendar"></i>${formatDate(this.data.start || new Date())} -
                                    ${this.data.inclusiveEnd ? formatDate(this.data.inclusiveEnd) : "offen"}
                                </div>
                                ${this.data.blocked ? html` <div class="orange pill">Beschäftigungspause</div> ` : ""}
                            </div>
                            <i
                                class="smaller ${this._expandedSections.has("general")
                                    ? "chevron-down"
                                    : "chevron-right"}"
                            ></i>
                        </div>
                    </button>

                    <ptc-drawer .collapsed=${!this._expandedSections.has("general")}>
                        <div class="padded">
                            <div>
                                <label>
                                    Beschäftigungsverhältnis
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#beschäftigungsverhältnis">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>

                                <ptc-select
                                    name="employmentType"
                                    .options=${getEmploymentTypes()}
                                    .renderOption=${((option: { label: string; description: string } | null) => html`
                                        <div class="double-padded">
                                            ${option
                                                ? html`
                                                      <div class="bold bottom-margined">${option.label}</div>
                                                      <div class="smaller subtle">${option.description}</div>
                                                  `
                                                : "Nichts Gewählt"}
                                        </div>
                                    `) as any}
                                    .getValue=${((option: { type: EmploymentType }) => String(option.type)) as any}
                                    @change=${this._employmentTypeChanged}
                                ></ptc-select>
                            </div>

                            <div class="fields">
                                <div class="field">
                                    <label>
                                        Vertragsbeginn
                                        <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#vertragsbeginn-und--ende">
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>
                                    <ptc-date-input
                                        name="start"
                                        min="1950-01-01"
                                        required
                                        @change=${() => this.requestUpdate()}
                                        datePicker="popover"
                                    ></ptc-date-input>
                                </div>

                                <div class="field">
                                    <label>
                                        Vertragsende
                                        <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#vertragsbeginn-und--ende">
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>
                                    <ptc-date-input
                                        name="inclusiveEnd"
                                        .min=${data.start}
                                        @change=${() => this.requestUpdate()}
                                        datePicker="popover"
                                    ></ptc-date-input>
                                </div>

                                <div class="field">
                                    <label>
                                        Verfügbarkeit
                                        <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#verfügbarkeit">
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>
                                    <ptc-checkbox-button
                                        name="blocked"
                                        label="Beschäftigungspause"
                                        buttonClass="ghost"
                                    ></ptc-checkbox-button>
                                </div>

                                <div class="field">
                                    <label>
                                        Bemerkungen
                                        <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#bemerkungen">
                                            <i class="faded question-circle"></i>
                                        </ptc-help-anchor>
                                    </label>
                                    <textarea name="comment"></textarea>
                                </div>
                            </div>
                        </div>
                    </ptc-drawer>
                </div>

                <div class="border-bottom">
                    <button
                        type="button"
                        class="larger small-caps fill-horizontally transparent text-left-aligning"
                        @click=${() => this._toggleSection("time")}
                    >
                        <div class="horizontal spacing center-aligning layout">
                            <div class="stretch"><i class="hourglass-clock"></i> Arbeitszeit & Fehltage</div>
                            <div class="tiny pills regular-style">
                                ${[NominalHoursMode.FixedDays, NominalHoursMode.FixedDaysWithoutHolidays].includes(
                                    this.data.nominalHoursMode!
                                )
                                    ? html`
                                          ${this.data.hoursPerDay?.map((h, i) =>
                                              !h
                                                  ? null
                                                  : html`<div class="pill">
                                                        ${["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"][i]}
                                                        <strong> ${toDurationString(h, true)} </strong>
                                                    </div>`
                                          )}
                                      `
                                    : html`
                                          <div class="pill">${formatNumber(this.data.hoursPerWeek!)} Std. / Woche</div>
                                      `}
                            </div>
                            <i
                                class="smaller ${this._expandedSections.has("time") ? "chevron-down" : "chevron-right"}"
                            ></i>
                        </div>
                    </button>

                    <ptc-drawer .collapsed=${!this._expandedSections.has("time")}>
                        <div class="padded fields">
                            <div class="field">
                                <label>
                                    Sollstundenberechnung
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#sollstundenberechnung">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>

                                <select name="nominalHoursMode" @change=${() => this._nominalHoursModeChanged()}>
                                    <option .value=${NominalHoursMode.WeekFactor.toString()}>Wochenfaktor</option>
                                    <option .value=${NominalHoursMode.Exact.toString()}>Monatsgenau</option>
                                    <option .value=${NominalHoursMode.ExactWithoutHolidays.toString()}>
                                        Monatsgenau abz. Feiertage
                                    </option>
                                    <option .value=${NominalHoursMode.FixedDays.toString()}>Feste Wochentage</option>
                                    <option .value=${NominalHoursMode.FixedDaysWithoutHolidays.toString()}>
                                        Feste Wochentage abz. Feiertage
                                    </option>
                                </select>
                            </div>

                            <div class="field" ?hidden=${fixedDays}>
                                <label>Stunden / Woche</label>
                                <input type="number" name="hoursPerWeek" min="0" max="70" step="0.001" required />

                                <ptc-popover class="tooltip" trigger="focus" non-interactive>
                                    Falls die Stundenanzahl auf Monatsbasis vereinbart ist, ergibt sich die Anzahl der
                                    Wochenstunden über den <a href="#">Wochenfaktor</a>:
                                    <strong><pre>Wochenst. = Monatsst. / 4.35</pre></strong>
                                </ptc-popover>
                            </div>

                            <div class="field horizontal layout workdays" ?hidden=${!fixedDays}>
                                ${["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map(
                                    (day) => html`
                                        <div class="field stretch">
                                            <label>${day}</label>
                                            <input
                                                type="number"
                                                name="hoursPerDay"
                                                min="0"
                                                max="10"
                                                step="0.01"
                                                @change=${this._hoursPerDayChanged}
                                            />
                                        </div>
                                    `
                                )}
                            </div>

                            <div class="field">
                                <label>
                                    Fehlstundenberechnung
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#fehlstundenberechnung">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>

                                <select name="absenceMode" required>
                                    <option .value=${AbsenceMode.Fixed.toString()}>Pauschale Stundenzahl</option>
                                    <option .value=${AbsenceMode.Average.toString()}>13-Wochen Durchschnitt</option>
                                    <option .value=${AbsenceMode.FixedDays.toString()} ?disabled=${!fixedDays}>
                                        Feste Wochentage (Sollstunden)
                                    </option>
                                </select>
                            </div>

                            <div class="field" ?hidden=${this.data.absenceMode !== AbsenceMode.Fixed}>
                                <label>Pauschale Fehlstunden</label>
                                <input type="number" name="absenceHours" min="0" max="24" step="0.01" />
                            </div>

                            <div class="field">
                                <label>
                                    Jahresarbeitszeitkonto
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#jahresarbeitszeitkonto">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>
                                <ptc-checkbox-button
                                    label="Vereinbart"
                                    name="annualTimeSheet"
                                    buttonClass="ghost"
                                ></ptc-checkbox-button>
                            </div>
                        </div>
                    </ptc-drawer>
                </div>

                <div class="border-bottom">
                    <button
                        type="button"
                        class="larger small-caps fill-horizontally transparent text-left-aligning"
                        @click=${() => this._toggleSection("compensation")}
                    >
                        <div class="horizontal spacing center-aligning layout">
                            <div class="stretch"><i class="euro-sign"></i> Bezahlung</div>
                            <div class="tiny pills regular-style">
                                ${data.salaries?.map((pay) => {
                                    const pos = pay.positionId && app.getPosition(pay.positionId);
                                    return html`
                                        <div class="pill">
                                            ${pos ? `${pos.position.name}: ` : ""} ${formatNumber(pay.amount)} € /
                                            ${pay.type === "monthly" ? "Monat" : "Stunde"}
                                        </div>
                                    `;
                                })}
                            </div>
                            <i
                                class="smaller ${this._expandedSections.has("compensation")
                                    ? "chevron-down"
                                    : "chevron-right"}"
                            ></i>
                        </div>
                    </button>

                    <ptc-drawer .collapsed=${!this._expandedSections.has("compensation")}>
                        <div class="padded">
                            <table class="salaries fill-horizontally">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            Lohn / Gehalt (brutto)
                                            <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#lohngehalt-brutto">
                                                <i class="smaller faded question-circle"></i>
                                            </ptc-help-anchor>
                                        </th>
                                        <th>
                                            Provision
                                            <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#provision">
                                                <i class="smaller faded question-circle"></i>
                                            </ptc-help-anchor>
                                        </th>
                                        ${hideAccountingSalaryConfigField
                                            ? null
                                            : html` <th>
                                                  Lohnartensatz
                                                  <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#lohnartensatz">
                                                      <i class="smaller faded question-circle"></i>
                                                  </ptc-help-anchor>
                                              </th>`}
                                    </tr>
                                </thead>
                                <tbody>
                                    ${[defaultSalary, ...otherSalaries].map((salary) => {
                                        const position = this._allPositions.find(
                                            ({ position }) => position.id === salary.positionId
                                        );

                                        return html`
                                            <tr>
                                                <td class="${salary === defaultSalary ? "bold" : ""}">
                                                    <input
                                                        type="hidden"
                                                        .value=${salary.id?.toString() || ""}
                                                        name="salaryId"
                                                    />

                                                    <input
                                                        type="hidden"
                                                        .value=${salary.positionId?.toString() || ""}
                                                        name="salaryPosition"
                                                    />
                                                    ${position
                                                        ? `${position.venue.name} • ${position.department.name} • ${position.position.name}`
                                                        : "Alle Arbeitsbereiche"}
                                                </td>

                                                <td>
                                                    <div class="salary-pay horizontal layout">
                                                        <div class="ui right icon input stretch">
                                                            <input
                                                                type="number"
                                                                name="salaryAmount"
                                                                min="0"
                                                                step="0.01"
                                                                required
                                                                .value=${salary.amount.toFixed(2)}
                                                            />
                                                            <i class="euro-sign"></i>
                                                        </div>

                                                        <select class="salary-type" name="salaryType">
                                                            <option
                                                                value="hourly"
                                                                ?selected=${salary.type === "hourly"}
                                                            >
                                                                Pro Stunde
                                                            </option>
                                                            <option
                                                                value="monthly"
                                                                ?selected=${salary.type === "monthly"}
                                                            >
                                                                Monatlich
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="ui right icon input">
                                                        <input
                                                            type="number"
                                                            name="salaryCommission"
                                                            min="0"
                                                            max="100"
                                                            step="0.01"
                                                            required
                                                            .value=${salary.commission.toFixed(2)}
                                                        />
                                                        <i class="percent"></i>
                                                    </div>
                                                </td>
                                                ${hideAccountingSalaryConfigField
                                                    ? null
                                                    : html`
                                                          <td>
                                                              <select name="accountingSalaryConfig">
                                                                  <option
                                                                      value=""
                                                                      ?selected=${!salary.accountingSalaryConfigId}
                                                                  >
                                                                      [Nichts Gewählt]
                                                                  </option>

                                                                  ${accountingSalaryConfigs.map(
                                                                      (t) => html`
                                                                          <option
                                                                              .value=${t.id!.toString()}
                                                                              ?selected=${salary.accountingSalaryConfigId ===
                                                                              t.id}
                                                                          >
                                                                              ${t.name}
                                                                          </option>
                                                                      `
                                                                  )}
                                                              </select>
                                                          </td>
                                                      `}
                                                <td>
                                                    <button
                                                        class="small transparent icon delete-salary"
                                                        type="button"
                                                        ?hidden=${this.readonly || salary === defaultSalary}
                                                        @click=${() => this._deleteSalary(salary)}
                                                    >
                                                        <i class="trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        `;
                                    })}
                                </tbody>
                            </table>

                            <button
                                type="button"
                                class="transparent add-salary"
                                ?hidden=${this.readonly || !this._availablePositions.length}
                            >
                                <i class="plus"></i> Gehalt Hinzufügen
                            </button>

                            <ptc-popover .anchor=${".add-salary"} hide-on-click>
                                <div class="salary-positions-menu vertical layout">
                                    ${this._availablePositions.map(
                                        ({ position, department, venue }) => html`
                                            <button
                                                class="transparent"
                                                type="button"
                                                @click=${() => this._addSalary(position)}
                                            >
                                                ${venue.name} &bullet; ${department.name} &bullet; ${position.name}
                                            </button>
                                        `
                                    )}
                                </div>
                            </ptc-popover>

                            <div class="fields">
                                <div class="field" ?hidden=${maxPay === undefined}>
                                    <label>Lohngrenze (bei geringf. besch.)</label>
                                    <div class="ui right icon input">
                                        <input
                                            type="number"
                                            name="maxSalary"
                                            step="0.01"
                                            min="0"
                                            .max=${maxPay?.toString() || ""}
                                            ?required=${[EmploymentType.Marginal, EmploymentType.MidiJob].includes(
                                                data.employmentType!
                                            )}
                                        />
                                        <i class="euro-sign"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ptc-drawer>
                </div>

                <div class="border-bottom">
                    <button
                        type="button"
                        class="larger small-caps fill-horizontally transparent text-left-aligning"
                        @click=${() => this._toggleSection("vacation")}
                    >
                        <div class="horizontal spacing center-aligning layout">
                            <div class="stretch"><i class="island-tropical"></i> Urlaub</div>
                            <div class="tiny pills regular-style">
                                <div class="pill">${formatNumber(this.data.vacationDays!)} Tage / Jahr</div>
                            </div>
                            <i
                                class="smaller ${this._expandedSections.has("vacation")
                                    ? "chevron-down"
                                    : "chevron-right"}"
                            ></i>
                        </div>
                    </button>

                    <ptc-drawer .collapsed=${!this._expandedSections.has("vacation")}>
                        <div class="padded fields">
                            <div class="field">
                                <label>
                                    Urlaubsanspruch (pro Jahr)
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#urlaubsanspruch">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>
                                <div class="input">
                                    <input type="number" name="vacationDays" min="0" max="100" step="0.01" required />
                                </div>
                            </div>

                            <div class="field">
                                <label>
                                    Urlaubszuwachs
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#urlaubszuwachs">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>
                                <select name="vacationIncrement">
                                    <option .value=${VacationIncrement.Monthly.toString()}>Monatlich</option>
                                    <option .value=${VacationIncrement.Hourly.toString()}>Nach Arbeitstunden</option>
                                </select>
                            </div>
                        </div>
                    </ptc-drawer>
                </div>

                <div class="border-bottom">
                    <button
                        type="button"
                        class="larger small-caps fill-horizontally transparent text-left-aligning"
                        @click=${() => this._toggleSection("bonus")}
                    >
                        <div class="horizontal spacing center-aligning layout">
                            <div class="stretch"><i class="badge-percent"></i> Zuschläge</div>
                            <div class="tiny pills regular-style">
                                ${data.bonuses?.map((bonus) => {
                                    const type = app.company?.bonusTypes.find((t) => t.id === bonus.typeId);
                                    if (!type) {
                                        return html``;
                                    }

                                    const bonusTypePopover = html` <ptc-popover
                                        class="non-interactive"
                                        trigger="hover"
                                        style="padding: 0"
                                    >
                                        <ptc-bonus-type-item class="small" .bonusType=${type}></ptc-bonus-type-item>
                                    </ptc-popover>`;

                                    switch (type.compType) {
                                        case BonusCompType.FixedAmount:
                                            return html`
                                                <div class="pill">
                                                    <i class="euro"></i>
                                                    ${bonus.fixedAmount}
                                                </div>
                                                ${bonusTypePopover}
                                            `;
                                        case BonusCompType.FixedHourlyRate:
                                            return html`
                                                <div class="pill">
                                                    <i class="euro"></i>
                                                    ${bonus.hourlyRate}
                                                </div>
                                                ${bonusTypePopover}
                                            `;
                                        default:
                                            return html`<div class="pill">
                                                    <i class="badge-percent"></i>
                                                    ${bonus.percent}
                                                </div>
                                                ${bonusTypePopover}`;
                                    }
                                })}
                            </div>
                            <i
                                class="smaller ${this._expandedSections.has("bonus")
                                    ? "chevron-down"
                                    : "chevron-right"}"
                            ></i>
                        </div>
                    </button>

                    <ptc-drawer .collapsed=${!this._expandedSections.has("bonus")}>
                        <div class="padded">
                            <div class="margined box">
                                ${this._bonuses.length
                                    ? this._bonuses.map((bonus, i) => {
                                          const type = app.company?.bonusTypes.find((t) => t.id === bonus.typeId);
                                          if (!type) {
                                              return;
                                          }
                                          return html`
                                              <input type="hidden" name="bonusId" .value=${bonus.id?.toString()} />
                                              <input
                                                  type="hidden"
                                                  name="bonusTypeId"
                                                  .value=${bonus.typeId.toString()}
                                              />
                                              <div
                                                  class="right-padded border-bottom spacing horizontal center-aligning layout bonus"
                                              >
                                                  <div class="stretch">
                                                      <ptc-bonus-type-item .bonusType=${type}></ptc-bonus-type-item>
                                                  </div>
                                                  <select name="bonusPosition">
                                                      <option value="" ?selected=${!bonus.positionId}>
                                                          Alle Positionen
                                                      </option>
                                                      ${this.employee?.positions.map(
                                                          (pos) =>
                                                              html`<option
                                                                  .value=${pos.id.toString()}
                                                                  ?selected=${bonus.positionId === pos.id}
                                                              >
                                                                  ${pos.name}
                                                              </option>`
                                                      )}
                                                  </select>

                                                  <div
                                                      class="right icon input"
                                                      ?hidden=${type.compType !== BonusCompType.FixedAmount}
                                                  >
                                                      <input
                                                          type="number"
                                                          step="0.01"
                                                          min="0"
                                                          max=${type.fixedAmountMax}
                                                          .value=${bonus.fixedAmount?.toFixed(2)}
                                                          name="bonusFixedAmount"
                                                          ?required=${type.compType === BonusCompType.FixedAmount}
                                                      />
                                                      <i class="euro-sign"></i>
                                                  </div>

                                                  <div
                                                      class="right icon input"
                                                      ?hidden=${type.compType !== BonusCompType.FixedHourlyRate}
                                                  >
                                                      <input
                                                          type="number"
                                                          step="0.01"
                                                          min="0"
                                                          max=${type.fixedHourlyRateMax}
                                                          .value=${bonus.hourlyRate?.toFixed(2)}
                                                          name="bonusHourlyRate"
                                                          ?required=${type.compType === BonusCompType.FixedHourlyRate}
                                                      />
                                                      <i class="euro-sign"></i>
                                                  </div>

                                                  <div
                                                      class="right icon input"
                                                      ?hidden=${type.compType !== BonusCompType.ModifiedHourlyRate}
                                                  >
                                                      <input
                                                          type="number"
                                                          step="1"
                                                          min="0"
                                                          max=${type.maxPercent}
                                                          .value=${bonus.percent.toFixed(0)}
                                                          name="bonusPercent"
                                                          ?required=${type.compType ===
                                                          BonusCompType.ModifiedHourlyRate}
                                                      />
                                                      <i class="percent"></i>
                                                  </div>

                                                  <div>
                                                      <button
                                                          type="button"
                                                          class="skinny transparent remove-bonus"
                                                          @click=${() => this._removeBonus(i)}
                                                          ?hidden=${this.readonly}
                                                      >
                                                          <i class="trash"></i>
                                                      </button>
                                                  </div>
                                              </div>
                                          `;
                                      })
                                    : html`
                                          <div class="subtle double-padded border-bottom">Keine Zuschläge Gewählt</div>
                                      `}

                                <div class="padded vertical layout" ?hidden=${this.readonly}>
                                    <button type="button" class="transparent">
                                        <i class="plus"></i> Zuschlag Hinzufügen
                                    </button>
                                </div>

                                <ptc-popover class="popover-menu" style="padding: 0" hide-on-click>
                                    <ptc-scroller style="max-height: 40em;">
                                        <div class="padded">
                                            ${availableBonusTypes.length
                                                ? availableBonusTypes.map(
                                                      (type) => html`
                                                          <button
                                                              type="button"
                                                              class="skinny"
                                                              @click=${() => this._addBonus(type)}
                                                          >
                                                              <ptc-bonus-type-item
                                                                  .bonusType=${type}
                                                                  class="small"
                                                                  style="padding: 0.5em;"
                                                              ></ptc-bonus-type-item>
                                                          </button>
                                                      `
                                                  )
                                                : html`
                                                      <div class="double-padded small subtle">
                                                          Keine weiteren Zuschlagsarten verfügbar.
                                                      </div>
                                                  `}
                                        </div>
                                    </ptc-scroller>
                                </ptc-popover>
                            </div>
                        </div>

                        <div class="padded fields">
                            <div class="field">
                                <label>
                                    Monatliche SFN-Pauschale
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#monatliche-sfn-pauschale">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>
                                <ptc-checkbox-button
                                    name="enableSFNLedger"
                                    label="... bezahlen"
                                    buttonClass="ghost"
                                ></ptc-checkbox-button>
                            </div>

                            <div class="field" ?hidden=${!data.enableSFNLedger}>
                                <label>SFN-Pauschale</label>
                                <div class="ui right icon input">
                                    <input type="number" name="sfnAdvance" step="0.01" required />
                                    <i class="euro-sign"></i>
                                </div>
                            </div>
                        </div>

                        <div class="padded fields" hidden>
                            <div class="field">
                                <label>Nachtarbeit (1)</label>
                                <div class="ui right icon input">
                                    <input type="number" min="0" max="200" name="bonusNight1" />
                                    <i class="percent"></i>
                                </div>
                                <ptc-popover class="tooltip" trigger="focus">
                                    Zuschlag für Nachtarbeit <strong>zwischen 20:00 und 06:00</strong> des Folgetages.
                                    (darf min="0" maximal 25% betragen)
                                </ptc-popover>
                            </div>

                            <div class="field">
                                <label>Nachtarbeit (2)</label>
                                <div class="ui right icon input">
                                    <input type="number" min="0" max="200" name="bonusNight2" />
                                    <i class="percent"></i>
                                </div>
                                <ptc-popover class="tooltip" trigger="focus">
                                    Zuschlag für Nachtarbeit <strong>zwischen 00:00 und 04:00</strong>. (darf maximal
                                    40% betragen)
                                </ptc-popover>
                            </div>

                            <div class="field">
                                <label>Sonntage</label>
                                <div class="ui right icon input">
                                    <input type="number" min="0" max="200" name="bonusSunday" />
                                    <i class="percent"></i>
                                </div>
                                <ptc-popover class="tooltip" trigger="focus">
                                    Zuschlag für Arbeit an Sonntagen. Bei Schichten die an einem Sonntag beginnen gelten
                                    Zuschläge bis 04:00 des Folgetages.
                                </ptc-popover>
                            </div>

                            <div class="field">
                                <label>Feiertage</label>
                                <div class="ui right icon input">
                                    <input type="number" min="0" max="200" name="bonusHoliday" />
                                    <i class="percent"></i>
                                </div>
                                <ptc-popover class="tooltip" trigger="focus">
                                    Zuschlag für Arbeit an Feiertagen. Bei Schichten die an einem Feiertag beginnen
                                    gelten Zuschläge bis 04:00 des Folgetages.
                                </ptc-popover>
                            </div>

                            <div class="field">
                                <label>Sondertage</label>
                                <div class="ui right icon input">
                                    <input type="number" min="0" max="200" name="bonusSpecial" />
                                    <i class="percent"></i>
                                </div>
                                <ptc-popover class="tooltip" trigger="focus">
                                    Zuschlag für Arbeit an <a href="#">besonderen Feiertagen</a>. Bei Schichten die an
                                    einem Feiertag beginnen gilt dieser bis 04:00 des Folgetages.
                                </ptc-popover>
                            </div>

                            <div class="field">
                                <label>
                                    Sonntags- & Feiertagszuschläge...
                                    <ptc-help-anchor page="handbuch/mitarbeiter/vertrag/#zuschläge-kumulieren">
                                        <i class="faded question-circle"></i>
                                    </ptc-help-anchor>
                                </label>
                                <ptc-checkbox-button
                                    name="stackBonuses"
                                    label="... mit Nachtzuschlägen kumulieren"
                                    buttonClass="ghost"
                                ></ptc-checkbox-button>
                            </div>
                        </div>
                    </ptc-drawer>
                </div>

                <div class="border-bottom">
                    <button
                        type="button"
                        class="larger small-caps fill-horizontally transparent text-left-aligning"
                        @click=${() => this._toggleSection("benefits")}
                    >
                        <div class="horizontal spacing center-aligning layout">
                            <div class="stretch"><i class="hand-holding-box"></i> Sonderleistungen</div>
                            <div class="tiny pills regular-style">
                                <div class="pill">
                                    ${data.benefits &&
                                    formatNumber(data.benefits?.reduce((total, benefit) => total + benefit.amount, 0))}
                                    €
                                </div>
                            </div>
                            <i
                                class="smaller ${this._expandedSections.has("benefits")
                                    ? "chevron-down"
                                    : "chevron-right"}"
                            ></i>
                        </div>
                    </button>

                    <ptc-drawer .collapsed=${!this._expandedSections.has("benefits")}>
                        <div class="padded">
                            <div class="margined box">
                                ${this._benefits.length
                                    ? repeat(
                                          this._benefits,
                                          (benefit) => benefit.id,
                                          (benefit, i) => {
                                              const type = app.company?.benefitTypes.find(
                                                  (t) => t.id === benefit.typeId
                                              );
                                              if (!type) {
                                                  return;
                                              }
                                              return html`
                                                  <input
                                                      type="hidden"
                                                      name="benefitId"
                                                      .value=${benefit.id?.toString()}
                                                  />
                                                  <input
                                                      type="hidden"
                                                      name="benefitTypeId"
                                                      .value=${benefit.typeId.toString()}
                                                  />
                                                  <div
                                                      class="right-padded border-bottom spacing horizontal center-aligning layout benefit"
                                                  >
                                                      <div class="double-padded stretch">${type.name}</div>
                                                      <div class="right icon input">
                                                          <input
                                                              type="number"
                                                              step="0.01"
                                                              .value=${benefit.amount.toFixed(2)}
                                                              name="benefitAmount"
                                                              required
                                                          />
                                                          <i class="euro"></i>
                                                      </div>
                                                      <div>
                                                          <button
                                                              type="button"
                                                              class="skinny transparent remove-benefit"
                                                              @click=${() => this._removeBenefit(i)}
                                                              ?hidden=${this.readonly}
                                                          >
                                                              <i class="trash"></i>
                                                          </button>
                                                      </div>
                                                  </div>
                                              `;
                                          }
                                      )
                                    : html`
                                          <div class="subtle double-padded border-bottom">
                                              Keine Sonderleistungen Gewählt
                                          </div>
                                      `}

                                <div class="padded vertical layout" ?hidden=${this.readonly}>
                                    <button type="button" class="transparent">
                                        <i class="plus"></i> Sonderleistung Hinzufügen
                                    </button>

                                    <ptc-popover class="popover-menu" hide-on-click>
                                        ${availableBenefitTypes.length
                                            ? availableBenefitTypes.map(
                                                  (type) => html`
                                                      <button type="button" @click=${() => this._addBenefit(type)}>
                                                          ${type.name}
                                                      </button>
                                                  `
                                              )
                                            : html`
                                                  <div class="double-padded small subtle">
                                                      Keine weiteren Sonderleistungen verfügbar.
                                                  </div>
                                              `}
                                    </ptc-popover>
                                </div>
                            </div>
                        </div>
                    </ptc-drawer>
                </div>
            </form>
        `;
    }
}
