import { LitElement, html, css } from "lit";
import { customElement, property, queryAll, state } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import {
    TimeEntry,
    TimeEntryType,
    timeEntryTypeColor,
    Position,
    Absence,
    AbsenceStatus,
    Availability,
    EmployeeStatus,
    employeeStatusColor,
    employeeStatusLabel,
    TimeLogEvent,
    TimeBalance,
} from "@pentacode/core/src/model";
import { getIssueMessage, getShiftsForIssue, Issue } from "@pentacode/core/src/issues";
import {
    toDateString,
    parseDateString,
    formatDate,
    toDurationString,
    formatTimeDistance,
    toTimeString,
    parseTimes,
    formatWeekDayShort,
    getRange,
    formatMonthShort,
} from "@pentacode/core/src/util";
import { app } from "../init";
import { colors, shared } from "../styles";
import type { TimeEntryForm } from "./time-entry-form";
import "./time-entry-form";
import "./scroller";
import "./avatar";
import "./drawer";
import type { RosterEntry } from "./roster-entry";
import "./roster-entry";
import "./progress";
import { Balance } from "./balance";
import { isCursorInInput } from "../lib/util";
import { DragData } from "./roster";
import { GetBalancesParams } from "@pentacode/core/src/api";
import { until } from "lit/directives/until.js";
import { DateString } from "@pentacode/openapi";
import { getVacationEntitlement, sumTimeBalances } from "@pentacode/core/src/time";

@customElement("ptc-employee-day")
export class EmployeeDay extends LitElement {
    @property({ attribute: false })
    entries: TimeEntry[] = [];

    @property({ attribute: false })
    absences: Absence[] = [];

    @property({ attribute: false })
    availabilities: Availability[] = [];

    @property({ attribute: false })
    activeDate: DateString;

    @property({ attribute: false })
    activeEmployee: number | null;

    @property({ type: Number })
    activeDepartment: number;

    @property()
    activeEntry: string | null = null;

    @property()
    activeField: string | null = null;

    @property({ attribute: false })
    issues: Issue[] = [];

    @property({ attribute: false })
    timeFilter?: { from?: string | null; to?: string | null };

    @property({ attribute: false })
    timeLogEvents?: TimeLogEvent[];

    @property()
    mode: "roster" | "time_sheet" | "time_logging" = "roster";

    @property({ type: Boolean })
    active = false;

    @property({ type: Boolean })
    hideAvailabilities = false;

    @property({ type: Boolean })
    hideStats = false;

    @property({ type: Boolean })
    hideDatePicker = false;

    @property({ type: Boolean })
    disableNewEntries = false;

    @state()
    private _timeBalancePromise: Promise<TimeBalance>;

    @state()
    private _vacationEntitlementPromise: Promise<ReturnType<typeof getVacationEntitlement>>;

    @queryAll("ptc-time-entry-form")
    private _forms: TimeEntryForm[];

    @queryAll("ptc-roster-entry")
    private _rosterEntries: RosterEntry[];

    private get _isPast() {
        return this.activeDate < toDateString(new Date());
    }

    private get _employee() {
        return app.getEmployee(this.activeEmployee);
    }

    private get _department() {
        const { department } = app.getDepartment(this.activeDepartment);
        return department;
    }

    private get _entries() {
        return this.entries.filter(
            (e) =>
                [TimeEntryType.Work, TimeEntryType.CompDay, TimeEntryType.Free].includes(e.type) &&
                e.date === this.activeDate &&
                e.employeeId === this.activeEmployee &&
                !e.deleted
        );
    }

    private get _activeForm() {
        return [...this._forms].find((f) => f.entry.id === this.activeEntry);
    }

    private get _activeRosterEntry() {
        return [...this._rosterEntries].find((f) => f.entry.id === this.activeEntry);
    }

    private get _suggestions() {
        const employee = this._employee;
        const suggestions = new Map<
            string,
            {
                position: Position;
                start: string;
                end: string;
                breakPlanned: number | null;
                score: number;
            }
        >();

        const weekDay = parseDateString(this.activeDate)!.getDay();

        const existing = this._entries;

        for (const e of this.entries) {
            const { date, employeeId, breakPlanned, position, deleted } = e;

            const startPlanned = toTimeString(e.startPlanned);
            const endPlanned = toTimeString(e.endPlanned);

            if (
                deleted ||
                !position ||
                !position.active ||
                (this.activeDepartment && position.departmentId !== this.activeDepartment) ||
                (employee && !employee.positions.some((p) => p.id === position.id)) ||
                !e.planned ||
                (this.timeFilter && !e.matchesTimeFilter(this.timeFilter)) ||
                (employee &&
                    existing.some(
                        (e) =>
                            !!e.position &&
                            e.position.id === position!.id &&
                            toTimeString(e.startPlanned) === startPlanned
                    ))
            ) {
                continue;
            }

            const key = `${position.id}_${startPlanned}_${endPlanned}_${breakPlanned}`;

            if (!suggestions.has(key)) {
                suggestions.set(key, {
                    position,
                    start: startPlanned,
                    end: endPlanned,
                    breakPlanned,
                    score: 0,
                });
            }

            const sameEmployee = employeeId === this.activeEmployee;
            const sameDay = parseDateString(date)!.getDay() === weekDay;
            const score = sameEmployee && sameDay ? 1000 : sameEmployee ? 100 : sameDay ? 10 : 1;

            suggestions.get(key)!.score += score;
        }

        return [...suggestions.values()].sort((a, b) => b.score - a.score).slice(0, 5);
    }

    private get _availablePositions() {
        return (
            this._employee?.positions.filter(
                (p) => p.active && (!this.activeDepartment || p.departmentId === this.activeDepartment)
            ) ||
            this._department?.positions ||
            []
        );
    }

    private get _isCommitted() {
        const commitBefore = app.company?.settings.commitTimeEntriesBefore;
        return !!commitBefore && this.activeDate < commitBefore;
    }

    private _keydownHandler = (e: KeyboardEvent) => {
        if (!this.active || this.activeEntry !== "new" || isCursorInInput()) {
            return;
        }

        if (e.key === "k") {
            void this._addEntry({ type: TimeEntryType.Sick });
        } else if (e.key === "u") {
            void this._addEntry({ type: TimeEntryType.Vacation });
        } else if (e.key === "g") {
            void this._addEntry({ type: TimeEntryType.CompDay });
        } else if (e.key === "f") {
            void this._addEntry({ type: TimeEntryType.Free });
        } else {
            const n = Number(e.key);
            if (!isNaN(n)) {
                const suggestion = n === 0 ? { position: this._availablePositions[0] } : this._suggestions[n - 1];
                if (suggestion) {
                    void this._addEntry(suggestion);
                }
            }
        }
    };

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("keydown", this._keydownHandler);
    }

    disconnectedCallback() {
        document.removeEventListener("keydown", this._keydownHandler);
    }

    updated(changes: Map<string, unknown>) {
        if (
            changes.has("activeDate") ||
            changes.has("activeEntry") ||
            changes.has("activeField") ||
            changes.has("activeEmployee") ||
            changes.has("activeDepartment")
        ) {
            const activeForm = this._activeForm;
            activeForm &&
                setTimeout(() => {
                    activeForm.requestUpdate();
                    activeForm.focusField(this.activeField || "");
                }, 500);
            const activeRosterEntry = this._activeRosterEntry;
            activeRosterEntry && activeRosterEntry.requestUpdate();
        }

        if (changes.has("activeEntry") && this.activeEntry === "new") {
            const firstOption = this.renderRoot!.querySelector(".suggestion") as HTMLButtonElement;
            firstOption && firstOption.focus();
        }

        if (changes.has("activeDate") || changes.has("activeEmployee")) {
            this.loadStats();
        }
    }

    loadStats() {
        if (!this.activeDate || !this.activeEmployee) {
            return;
        }
        this._timeBalancePromise = app.api
            .getTimeBalances(
                new GetBalancesParams({
                    ...getRange(this.activeDate, "month"),
                    resolution: "month",
                    filters: [{ type: "employeeId", value: this.activeEmployee as number }],
                    projected: this.mode === "roster",
                })
            )
            .then((balances) => sumTimeBalances(balances));
        this._vacationEntitlementPromise = app.api
            .getVacationBalances(
                new GetBalancesParams({
                    ...getRange(this.activeDate, "year"),
                    filters: [{ type: "employeeId", value: this.activeEmployee as number }],
                    resolution: "year",
                    projected: this.mode === "roster",
                })
            )
            .then((balances) => getVacationEntitlement(balances[0]));
    }

    updateForms() {
        this._forms.forEach((form) => form.updateFields());
    }

    private _formInput() {
        const activeRosterEntry = this._activeRosterEntry;
        activeRosterEntry && activeRosterEntry.requestUpdate();
        this.requestUpdate();
    }

    private async _addEntry({
        type = TimeEntryType.Work,
        start,
        end,
        position,
        breakPlanned,
    }: {
        type?: TimeEntryType;
        start?: string;
        end?: string;
        position?: Position;
        breakPlanned?: number | null;
    }) {
        const mode = this._isPast ? "Final" : "Planned";
        const [startTS, endTS] = parseTimes(this.activeDate, start, end);
        this.dispatchEvent(
            new CustomEvent("addentry", {
                detail: {
                    type,
                    employeeId: this.activeEmployee,
                    date: this.activeDate,
                    ["start" + mode]: startTS,
                    ["end" + mode]: endTS,
                    breakPlanned,
                    position,
                },
                bubbles: true,
                composed: true,
            })
        );
    }

    private _dragstart(e: DragEvent, data: DragData) {
        const dt = e.dataTransfer!;
        const el = e.target as HTMLElement;
        const imgEl = (el.shadowRoot && (el.shadowRoot.querySelector(".container") as HTMLElement)) || el;
        dt.setData("text/plain", "42");
        dt.effectAllowed = "copyLink";
        dt.setDragImage(imgEl, imgEl.offsetWidth / 2, imgEl.offsetHeight / 2);
        el.classList.add("dragging");
        this.dispatchEvent(new CustomEvent("begindrag", { detail: { data } }));
    }

    static styles = [
        shared,
        Balance.styles,
        css`
            :host {
                display: block;
                position: relative;
            }

            .entry-wrapper {
                font-size: var(--font-size-small);
                margin: 0.5em;
                border-radius: 0.5em;
                color: var(--color-highlight);
                overflow: hidden;
                transition: all 0.5em;
            }

            .entry-wrapper.selected {
                border: solid 1px;
            }

            .absence {
                padding: 0.7em;
                font-weight: normal;
            }

            .suggestion {
                padding: 0.4em 0.7em 0.2em 0.7em;
                text-align: left;
                font-weight: normal;
            }

            .absence,
            .suggestion {
                color: var(--color-highlight);
                background: transparent;
                border: solid 1px currentColor;
            }

            .new-entry {
                border: solid 1px var(--shade-2);
                margin: 0.5em;
                border-radius: 0.5em;
            }

            .separator {
                border-bottom: solid 1px var(--shade-2);
                margin: 0.5em;
            }

            .box {
                background: var(--color-bg);
            }

            [draggable="true"] {
                opacity: 0.999;
            }

            .employee-name {
                margin: -0.2em 0 0.2em 0;
            }

            ptc-date-picker {
                margin-bottom: 0.2em;
            }
        `,
    ];

    private _renderEntries() {
        return html`
            ${repeat(
                this._entries,
                (entry) => entry.id,
                (entry) => {
                    const issues = this.issues.filter((issue) =>
                        getShiftsForIssue(issue).some((shift) => shift.id === entry.id)
                    );
                    return html`
                        <div
                            class="entry-wrapper ${this.activeEntry === entry.id ? "selected" : ""}"
                            style="--color-highlight: ${app.getTimeEntryColor(entry)}"
                        >
                            <ptc-roster-entry
                                class="small ${this.activeEntry === entry.id ? "selected" : ""}"
                                .entry=${entry}
                                .error=${!!issues.length}
                                .readonly=${this._isCommitted}
                                .availabilities=${this.availabilities.filter(
                                    (av) => av.employeeId === this.activeEmployee && av.date === this.activeDate
                                )}
                                draggable="${!this._isCommitted ? "true" : "false"}"
                                @dragstart=${(e: DragEvent) => this._dragstart(e, { entry })}
                            ></ptc-roster-entry>
                            <ptc-drawer .collapsed=${this.activeEntry !== entry.id}>
                                <div class="padded">
                                    ${issues.map(
                                        (issue) => html`
                                            <div class="small padded bottom-margined text-centering red box">
                                                <i class="exclamation-triangle"></i>
                                                ${getIssueMessage(issue.type)}
                                            </div>
                                        `
                                    )}
                                    <ptc-time-entry-form
                                        .entry=${entry}
                                        @input=${this._formInput}
                                        @focus=${() =>
                                            this.dispatchEvent(
                                                new CustomEvent("select", {
                                                    detail: { entry: entry.id, field: null },
                                                    bubbles: true,
                                                    composed: true,
                                                })
                                            )}
                                        .hideMealsAndRevenue=${this.mode === "roster"}
                                        .positions=${this._availablePositions}
                                        .hideLogged=${this.mode === "roster"}
                                        .readonly=${this._isCommitted}
                                        .timeLogEvents=${this.timeLogEvents?.filter((e) => e.timeEntryId === entry.id)}
                                    ></ptc-time-entry-form>

                                    <div class="tiny subtle top-margined top-padded text-centering">
                                        ${entry.isPublished && entry.published
                                            ? html`
                                                  <div>
                                                      <i class="paper-plane"></i> <strong>veröffentlicht</strong>
                                                      ${formatTimeDistance(entry.published.time)}
                                                  </div>
                                              `
                                            : html`
                                                  <div>
                                                      <i class="pencil-alt"></i> <strong>bearbeitet</strong>
                                                      ${formatTimeDistance(entry.updated)}
                                                  </div>
                                              `}
                                        ${entry.offered &&
                                        entry.offered > entry.updated &&
                                        (!entry.taken || entry.offered > entry.taken)
                                            ? html`
                                                  <div>
                                                      <i class="hand-holding-magic"></i>
                                                      <strong>zum Tausch freigegeben</strong>
                                                      ${formatTimeDistance(entry.offered)}
                                                  </div>
                                              `
                                            : entry.offered &&
                                                entry.taken &&
                                                entry.taken > entry.offered &&
                                                entry.taken > entry.updated
                                              ? html`
                                                    <div>
                                                        <i class="people-arrows"></i> <strong>getauscht</strong>
                                                        ${formatTimeDistance(entry.taken)}
                                                    </div>
                                                `
                                              : entry.taken && entry.taken >= entry.updated
                                                ? html`
                                                      <div>
                                                          <i class="thumbs-up"></i> <strong>übernommen</strong>
                                                          ${formatTimeDistance(entry.taken)}
                                                      </div>
                                                  `
                                                : entry.isSeen && entry.seen
                                                  ? html`
                                                        <div>
                                                            <i class="eye"></i>
                                                            <strong>gesehen</strong> ${formatTimeDistance(
                                                                entry.seen.time
                                                            )}
                                                        </div>
                                                    `
                                                  : ""}
                                    </div>
                                </div>
                            </ptc-drawer>
                        </div>
                    `;
                }
            )}
        `;
    }

    private _renderNewEntryOptions() {
        if (this._isCommitted || this.disableNewEntries) {
            return;
        }

        const availableTypes = app.hasPermission("manage.employees.absences")
            ? [TimeEntryType.Sick, TimeEntryType.Vacation, TimeEntryType.CompDay, TimeEntryType.Free]
            : [TimeEntryType.CompDay, TimeEntryType.Free];

        return html`
            <div class="new-entry">
                <div class="vertical layout">
                    <button
                        class="transparent"
                        ?disabled=${this.activeEntry === "new"}
                        @focus=${() => this.dispatchEvent(new CustomEvent("select", { detail: { entry: "new" } }))}
                        @click=${() => this.dispatchEvent(new CustomEvent("select", { detail: { entry: "new" } }))}
                    >
                        <i class="plus"></i> Neuer Eintrag
                        <div class="key left-margined">n</div>
                    </button>
                </div>
                <ptc-drawer .collapsed=${this.activeEntry !== "new"}>
                    <div class="horizontally-padded spacing vertical layout">
                        ${this._suggestions.map(
                            ({ position, start, end, breakPlanned }, i) => html`
                                <button
                                    @click=${() =>
                                        this._addEntry({
                                            position,
                                            start,
                                            end,
                                            breakPlanned,
                                        })}
                                    class="suggestion"
                                    style="--color-highlight: ${app.getTimeEntryColor(
                                        new TimeEntry({
                                            position,
                                        })
                                    )}"
                                    draggable="true"
                                    @dragstart=${(e: DragEvent) =>
                                        this._dragstart(e, {
                                            shiftTemplate: {
                                                type: TimeEntryType.Work,
                                                positionId: position.id,
                                                start,
                                                end,
                                                breakPlanned,
                                            },
                                        })}
                                >
                                    <div class="horizontal center-aligning layout">
                                        <div class="stretch">
                                            <div class="tiny ellipsis">${position.name}</div>
                                            <div class="medium">
                                                ${start.slice(0, 5)} - ${end.slice(0, 5)}
                                                ${typeof breakPlanned === "number"
                                                    ? html` <span class="smaller">
                                                          <i class="smaller coffee"></i> ${toDurationString(
                                                              breakPlanned
                                                          )}
                                                      </span>`
                                                    : ""}
                                            </div>
                                        </div>
                                        <div class="key">${i + 1}</div>
                                    </div>
                                </button>
                            `
                        )}
                    </div>
                    <div class="separator"></div>
                    <div class="horizontally-padded spacing vertical layout">
                        ${this._availablePositions.map(
                            (position, i) => html`
                                <button
                                    @click=${() => this._addEntry({ position })}
                                    class="suggestion"
                                    style="--color-highlight: ${app.getPositionColor(position)}"
                                    draggable="true"
                                    @dragstart=${(e: DragEvent) => this._dragstart(e, { entry: { position } })}
                                >
                                    <div class="horizontal center-aligning layout">
                                        <div class="stretch">
                                            <div class="tiny ellipsis">${position.name}</div>
                                            <div class="medium">Neue Schicht</div>
                                        </div>
                                        ${i === 0 ? html` <div class="key">0</div> ` : ""}
                                    </div>
                                </button>
                            `
                        )}
                    </div>
                    ${this._employee
                        ? html`
                              <div class="separator"></div>
                              <div class="horizontally-padded bottom-padded spacing vertical layout">
                                  ${availableTypes.map(
                                      (type) => html`
                                          <button
                                              @click=${() => this._addEntry({ type })}
                                              class="absence"
                                              style="--color-highlight: ${timeEntryTypeColor(type)}"
                                              draggable="true"
                                              @dragstart=${(e: DragEvent) => this._dragstart(e, { entry: { type } })}
                                          >
                                              <div class="horizontal center-aligning layout">
                                                  <div class="text-left-aligning stretch">
                                                      ${app.localized.timeEntryTypeLabel(type)}
                                                  </div>
                                                  <div class="key">
                                                      ${app.localized.timeEntryTypeLabel(type).toLowerCase()[0]}
                                                  </div>
                                              </div>
                                          </button>
                                      `
                                  )}
                              </div>
                          `
                        : ""}
                </ptc-drawer>
            </div>
        `;
    }

    private _renderStats() {
        if (this.hideStats || this._employee?.status === EmployeeStatus.Probation || !this._timeBalancePromise) {
            return;
        }

        return html`
            <div class="text-centering border-bottom">
                <div class="spacing margined evenly stretching horizontal layout">
                    <div class="stretch">
                        <div class="tiny bottom-margined semibold blue colored-text">
                            <div class="ellipsis">
                                <i class="hourglass-half"></i>
                                Stunden
                                <span class="subtle">${formatMonthShort(this.activeDate)}</span>
                                ${this.mode === "roster" ? html`<sup>*</sup>` : ""}
                            </div>
                        </div>

                        <div>
                            ${until(
                                this._timeBalancePromise.then(
                                    (balances) => html`
                                        <ptc-progress
                                            .nominal=${balances.nominal}
                                            .actual=${balances.actual}
                                            .format=${(n: number) => toDurationString(n, true)}
                                            class="smaller"
                                        ></ptc-progress>
                                    `
                                ),
                                html`<div class="centering layout">
                                    <ptc-spinner active style="font-size: 0.53em"></ptc-spinner>
                                </div>`
                            )}
                        </div>
                    </div>
                    <div class="stretch">
                        <div class="tiny bottom-margined semibold blue colored-text">
                            <div class="ellipsis">
                                <i class="island-tropical"></i>
                                Urlaub
                                <span class="subtle">${new Date(this.activeDate).getFullYear()}</span>
                            </div>
                        </div>

                        <div>
                            ${until(
                                this._vacationEntitlementPromise.then(
                                    (entitlement) => html`
                                        <ptc-progress
                                            .nominal=${entitlement.available}
                                            .actual=${entitlement.taken}
                                            .threshold=${0.01}
                                            class="smaller vacation"
                                        ></ptc-progress>
                                    `
                                ),
                                html`<div class="centering layout">
                                    <ptc-spinner active style="font-size: 0.53em"></ptc-spinner>
                                </div>`
                            )}
                        </div>
                    </div>
                </div>

                ${this.mode === "roster"
                    ? html`<div class="smaller subtle margined">
                          <sup>*</sup> Projektion basierend auf geplanten Stunden
                      </div>`
                    : ""}
            </div>
        `;
    }

    private _renderHints() {
        return html`
            <div>
                <div class="margined spacing centering horizontal layout">
                    <div class="spacing horizontal layout" ?hidden=${!this.activeEntry || this.activeEntry === "new"}>
                        <div class="key">⇧</div>
                        <div class="key">⌫</div>
                        <div class="small semibold subtle">Löschen</div>
                    </div>
                </div>
                <div class="margined spacing centering horizontal layout">
                    <div class="key">w</div>
                    <i class="arrow-up small subtle"></i>
                    <div class="key">s</div>
                    <i class="arrow-down small subtle"></i>
                    <div class="key">a</div>
                    <i class="arrow-left small subtle"></i>
                    <div class="key">d</div>
                    <i class="arrow-right small subtle"></i>
                </div>
                <div class="margined spacing centering horizontal layout" ?hidden=${this.mode !== "roster"}>
                    <div class="spacing horizontal layout">
                        <div class="key">⇧</div>
                        <div class="key">a</div>
                        <div class="small semibold subtle"><i class="arrow-left subtle"></i>Wo</div>
                    </div>
                    <div class="left-margined spacing horizontal layout">
                        <div class="key">⇧</div>
                        <div class="key">d</div>
                        <div class="small semibold subtle"><i class="arrow-right subtle"></i>Wo</div>
                    </div>
                </div>
            </div>
        `;
    }

    private _renderAvailabilities() {
        if (this.hideAvailabilities) {
            return;
        }

        const avs = this.availabilities.filter(
            (av) => av.employeeId === this.activeEmployee && av.date === this.activeDate
        );

        const canEdit =
            this._employee && app.hasPermissionForEmployee(this._employee, "manage.employees.availabilities");

        if (!this._employee || (!avs.length && !canEdit)) {
            return;
        }

        return html`<div class="padded spacing vertical layout border-bottom">
            ${avs.map(
                (av) => html`
                    <div
                        class="smaller padded box horizontal center-aligning layout ${canEdit ? "click" : ""}"
                        style="--color-highlight: ${av.color}"
                        @click=${canEdit
                            ? () =>
                                  this.dispatchEvent(
                                      new CustomEvent("edit-availability", { detail: { availability: av } })
                                  )
                            : null}
                    >
                        <div class="stretch ellipsis">
                            <i class="${av.icon}"></i>
                            ${av.fullLabel}
                        </div>
                    </div>
                `
            )}
            ${canEdit
                ? html`
                      <button
                          class="smaller subtle"
                          @click=${() => this.dispatchEvent(new CustomEvent("new-availability"))}
                      >
                          <i class="comment-check"></i> Verfügbarkeit Eintragen
                      </button>
                  `
                : ""}
        </div>`;
    }

    render() {
        if (!this.active) {
            return html`
                <div class="fullbleed centering vertical layout">
                    <div class="subtle padded">Kein Eintrag gewählt.</div>
                    ${this._renderHints()}
                </div>
            `;
        }

        const emp = this._employee;
        const dep = this._department;
        const contract = emp?.getContractForDate(this.activeDate);
        const absence =
            emp &&
            this.absences.find(
                (a) =>
                    a.employeeId === emp.id &&
                    a.start <= this.activeDate &&
                    a.end > this.activeDate &&
                    a.status === AbsenceStatus.Approved
            );
        const hasEntries = !!this._entries.length;
        const commitBefore = app.company?.settings.commitTimeEntriesBefore;
        const commited = commitBefore && this.activeDate < commitBefore;

        return html`
            <div class="fullbleed vertical layout">
                <div class="small half-padded horizontal center-aligning spacing layout border-bottom">
                    <div class="padded stretch semibold">
                        ${formatWeekDayShort(this.activeDate)}, ${formatDate(this.activeDate)}
                    </div>
                    <button
                        class="skinny transparent text-left-aligning"
                        @click=${() => this.dispatchEvent(new CustomEvent("close"))}
                    >
                        <div class="horizontal spacing layout">
                            <div class="key">Esc</div>
                            <i class="times"></i>
                        </div>
                    </button>
                </div>

                ${this.mode === "time_sheet"
                    ? html``
                    : emp
                      ? html`
                            <div class="horizontal center-aligning layout border-bottom">
                                <ptc-avatar class="left-margined" .employee=${emp}></ptc-avatar>
                                <div class="margined stretch collapse">
                                    <div class="semibold ellipsis employee-name">${emp.name}</div>
                                    <div class="tiny pills">
                                        ${emp.status !== EmployeeStatus.Active
                                            ? html`
                                                  <div class="${employeeStatusColor(emp.status)} inverted pill">
                                                      ${employeeStatusLabel(emp.status)}
                                                  </div>
                                              `
                                            : ""}
                                        ${emp.positions
                                            .sort(
                                                (a, b) =>
                                                    (b.departmentId === dep?.id ? 1 : 0) -
                                                    (a.departmentId === dep?.id ? 1 : 0)
                                            )
                                            .slice(0, 2)
                                            .map(
                                                (position) => html`
                                                    <div
                                                        class="pill"
                                                        style="--color-highlight: ${app.getPositionColor(position)}"
                                                    >
                                                        ${position.name}
                                                    </div>
                                                `
                                            )}
                                        ${emp.positions.length > 2
                                            ? html`
                                                  <div class="pill">+ ${emp.positions.length - 2}</div>
                                                  <ptc-popover class="tooltip" trigger="hover">
                                                      <div class="smaller vertical spacing layout text-left-aligning">
                                                          ${emp.positions
                                                              .slice(2)
                                                              .map(
                                                                  (position) => html`
                                                                      <div
                                                                          class="pill"
                                                                          style="--color-highlight: ${app.getPositionColor(
                                                                              position
                                                                          )}"
                                                                      >
                                                                          ${position.name}
                                                                      </div>
                                                                  `
                                                              )}
                                                      </div>
                                                  </ptc-popover>
                                              `
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        `
                      : html`
                            <div
                                class="padded spacing horizontal center-aligning layout border-bottom colored-text"
                                style="--color-highlight: ${(dep && (colors[dep.color] || dep.color)) ||
                                "var(--color-primary)"}"
                            >
                                <i class="huge user-slash"></i>
                                <div class="stretch">
                                    <div class="bold">${dep?.name}</div>
                                    <div>Nicht Zugewiesen</div>
                                </div>
                            </div>
                        `}

                <ptc-scroller class="stretch">
                    <div class="fill vertical layout">
                        ${this._renderStats()} ${this._renderAvailabilities()}
                        ${commited
                            ? html`
                                  <div class="margined double-padded orange box">
                                      Dieser Tag liegt vor dem Festschreibungsdatum
                                      <strong>${formatDate(commitBefore!)}</strong> und ist nicht mehr bearbeitbar.
                                  </div>
                              `
                            : ""}
                        ${emp && (!contract || contract.blocked) && !hasEntries
                            ? html`<div class="stretch margined centering layout box">
                                  <div>${(contract && contract.comment) || "Inaktiv"}</div>
                              </div>`
                            : absence && !hasEntries
                              ? html`
                                    <div
                                        class="stretch margined centering layout box"
                                        style="--color-highlight: ${timeEntryTypeColor(absence.type)}"
                                    >
                                        <div>
                                            <i class="${app.localized.timeEntryTypeIcon(absence.type)}"></i>
                                            ${app.localized.timeEntryTypeLabel(absence.type)}
                                        </div>
                                    </div>
                                `
                              : html`
                                    <div>${this._renderEntries()} ${this._renderNewEntryOptions()}</div>
                                    <div class="stretch"></div>
                                `}
                        ${this._renderHints()}
                    </div>
                </ptc-scroller>
            </div>
        `;
    }
}
