import { LitElement, html, css } from "lit";
import { customElement, state } from "lit/decorators.js";
import { Invoice, InvoiceStatus } from "@pentacode/core/src/invoice";
import { formatDate, formatNumber } from "@pentacode/core/src/util";
import { GetInvoicesParams, UpdateBillingParams } from "@pentacode/core/src/api";
import { singleton } from "../lib/singleton";
import { app } from "../init";
import { StateMixin } from "../mixins/state";
import { Routing } from "../mixins/routing";
import { shared } from "../styles";
import { alert, confirm } from "./alert-dialog";
import { InvoiceDialog } from "./invoice-dialog";
import { BillingDialog } from "./billing-dialog";
import "./scroller";
import "./spinner";
import { BillingMode } from "@pentacode/core/src/model";

@customElement("ptc-settings-billing")
export class SettingsBilling extends Routing(StateMixin(LitElement)) {
    routePattern = /settings\/billing/;

    get routeTitle() {
        return "Rechnungsstellung | Einstellungen";
    }

    get helpPage() {
        return "handbuch/einstellungen/rechnungsstellung/";
    }

    @state()
    private _loading = false;

    @state()
    private _invoices: Invoice[] = [];

    @singleton("ptc-invoice-dialog")
    private _invoiceDialog: InvoiceDialog;

    @singleton("ptc-billing-dialog", true)
    private _billingDialog: BillingDialog;

    private get _infoData() {
        const form = this.renderRoot.querySelector("#infoForm") as HTMLFormElement | null;

        if (!form) {
            return {};
        }

        const data = new FormData(form);

        const email =
            app.company?.billing?.billingMode === BillingMode.Hogast
                ? "hogast@pentacode.app"
                : (data.get("email") as string);

        return {
            name: data.get("name") as string,
            addressLine1: data.get("addressLine1") as string,
            addressLine2: data.get("addressLine2") as string,
            postalCode: data.get("postalCode") as string,
            city: data.get("city") as string,
            phone: data.get("phone") as string,
            email,
            vatId: data.get("vatId") as string,
        };
    }

    private get _hasInfoChanged() {
        const info = app.company?.billing?.info;
        return !info || Object.entries(this._infoData).some(([key, value]) => info[key as keyof typeof info] !== value);
    }

    async handleRoute() {
        await this._load();
    }

    private async _load() {
        if (!app.company) {
            return;
        }

        this._loading = true;

        try {
            this._invoices = await app.api.getInvoices(new GetInvoicesParams({ id: app.company.id }));
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _submitInfo(e: Event) {
        e.preventDefault();

        this._loading = true;

        try {
            await app.updateCompany({
                billing: new UpdateBillingParams(this._infoData),
            });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _addPaymentMethod() {
        const complete = await this._billingDialog.show(app.company!);

        if (complete) {
            void alert("Zahlungsmittel erfolgreich hinzugefügt!", { type: "success" });
        }
    }

    private async _removePaymentMethod(removePaymentMethod: string) {
        if (!(await confirm("Sind sie sicher dass Sie dieses Zahlungsmittel entfernen möchten?"))) {
            return;
        }

        this._loading = true;

        try {
            await app.updateCompany({
                billing: new UpdateBillingParams({ removePaymentMethod }),
            });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private async _setDefaultPaymentMethod(defaultPaymentMethod: string) {
        if (!(await confirm("Sind sie sicher dass Sie dieses Zahlungsmittel als Standard festlegen möchten?"))) {
            return;
        }

        this._loading = true;

        try {
            await app.updateCompany({
                billing: new UpdateBillingParams({ defaultPaymentMethod }),
            });
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._loading = false;
    }

    private _renderPaymentMethods() {
        const billing = app.company?.billing;
        if (!billing || billing.billingMode === BillingMode.Hogast) {
            return;
        }
        return html`
            <h2>Zahlungsmittel</h2>

            <div class="box">
                ${billing &&
                billing.paymentMethods.map(
                    (pm) => html`
                        <div class="payment-method padded horizontal layout border-bottom">
                            <div class="stretch">
                                <div class="payment-method-detail">
                                    <div class="payment-method-detail-label">Kontoinhaber:</div>
                                    <div class="payment-method-detail-value">${pm.billing_details.name}</div>
                                </div>

                                <div class="payment-method-detail">
                                    <div class="payment-method-detail-label">IBAN:</div>
                                    <div class="payment-method-detail-value">
                                        ${pm.sepa_debit?.country} •••• •••• •••• •••• ${pm.sepa_debit?.last4}
                                    </div>
                                </div>

                                <div class="payment-method-detail">
                                    <div class="payment-method-detail-label">Hinzugefügt:</div>
                                    <div class="payment-method-detail-value">
                                        ${formatDate(new Date(pm.created * 1000))}
                                    </div>
                                </div>
                            </div>

                            <div class="vertical layout">
                                ${billing.customer!.invoice_settings!.default_payment_method === pm.id
                                    ? html` <div class="pill">Standard</div> `
                                    : html`
                                          <button @click=${() => this._setDefaultPaymentMethod(pm.id)}>
                                              Als Standard Festlegen
                                          </button>
                                          <button class="negative" @click=${() => this._removePaymentMethod(pm.id)}>
                                              Entfernen
                                          </button>
                                      `}
                            </div>
                        </div>
                    `
                )}

                <div class="padded vertical layout">
                    <button class="transparent" @click=${() => this._addPaymentMethod()}>
                        <i class="credit-card"></i>
                        Zahlungsmittel Hinzufügen
                    </button>
                </div>
            </div>
        `;
    }

    private _renderBillingHistory() {
        const billing = app.company?.billing;
        if (!billing) {
            return;
        }

        // Filter out invoices sent to hogast
        const invoices = this._invoices.filter((inv) => !inv.hogastXmlInvoice);

        return html`
            <h2>Zahlungsverlauf</h2>

            <div class="box">
                ${!invoices.length
                    ? html`
                          <div class="double-padded text-centering subtle">Es liegen noch keine Rechnungen vor.</div>
                      `
                    : ""}
                ${invoices
                    .filter((inv) => ![InvoiceStatus.Void, InvoiceStatus.Draft].includes(inv.status))
                    .map(
                        (invoice) => html`
                            <div
                                class="invoice horizontal center-aligning layout padded-medium pad-children click ${invoice.status}"
                                @click=${() => this._invoiceDialog.show(invoice)}
                            >
                                <i class="${invoice.statusIcon}"></i>
                                <div class="invoice-date">
                                    ${invoice.billingDate
                                        ? formatDate(invoice.billingDate)
                                        : formatDate(invoice.period.start)}
                                </div>
                                <div class="stretch ellipsis">
                                    ${invoice.items.map((item) => item.description).join(", ")}
                                </div>
                                <div class="invoice-amount">
                                    ${invoice.total !== invoice.adjustedTotal
                                        ? html`
                                              <div class="subtle line-through">${formatNumber(invoice.total)} €</div>
                                              <div>${formatNumber(invoice.adjustedTotal)} €</div>
                                          `
                                        : `${formatNumber(invoice.total)} €`}
                                </div>
                            </div>
                        `
                    )}
            </div>
        `;
    }

    static styles = [
        shared,
        css`
            .payment-method {
                position: relative;
            }

            .payment-method .pill {
                position: absolute;
                top: 0.5em;
                right: 0.5em;
            }

            .payment-method-detail {
                display: flex;
                align-items: center;
                margin: 0.5em 0;
            }

            .payment-method-detail-label {
                font-weight: bold;
                width: 8em;
                text-align: right;
                margin-right: 1em;
            }

            .payment-method button {
                margin-bottom: 0.5em;
                padding: 0.5em;
                font-size: var(--font-size-small);
            }

            .payment-method:not(:hover) button {
                display: none;
            }

            .invoice {
                font-size: var(--font-size-medium);
            }

            .invoice i::before {
                text-decoration: none;
            }

            .invoice:not(:last-child) {
                border-bottom: solid 1px var(--shade-2);
            }

            .invoice-date {
                font-weight: 600;
            }

            .invoice-amount {
                font-weight: bold;
            }

            .invoice.refunded {
                opacity: 0.5;
                text-decoration: line-through;
            }

            .upcoming {
                padding: 1em;
                font-size: var(--font-size-medium);
                border-radius: calc(2 * var(--border-radius));
                border: solid 1px var(--shade-2);
                margin-bottom: 1em;
            }

            .upcoming-date {
                font-weight: bold;
                margin-bottom: 0.5em;
            }

            .upcoming-amount {
                font-size: var(--font-size-large);
            }
        `,
    ];

    render() {
        if (!app.company) {
            return;
        }

        const billing = app.company.billing;
        const info = billing?.info;

        return html`
            <ptc-scroller class="fullbleed">
                <div class="double-padded" style="max-width: 50em; margin: 0 auto">
                    <form id="infoForm" @submit=${this._submitInfo} @change=${() => this.requestUpdate()}>
                        <h2>Rechnungsanschrift</h2>

                        <div class="field">
                            <label>Unternehmensname</label>
                            <input name="name" placeholder="Unternehmensname" .value=${info?.name || ""} />
                        </div>

                        <div class="field">
                            <label>Adresse</label>
                            <div class="spacing vertical layout">
                                <input
                                    type="text"
                                    name="addressLine1"
                                    placeholder="Strasse / Hausnummer (1. Zeile)"
                                    .value=${info?.addressLine1 || ""}
                                />
                                <input
                                    type="text"
                                    name="addressLine2"
                                    placeholder="Strasse / Hausnummer (2. Zeile)"
                                    .value=${info?.addressLine2 || ""}
                                />
                            </div>
                        </div>

                        <div class="fields horizontal spacing layout">
                            <div class="field stretch">
                                <label>Postleitzahl</label>
                                <input
                                    type="number"
                                    pattern="d*"
                                    name="postalCode"
                                    placeholder="PLZ"
                                    .value=${info?.postalCode || ""}
                                />
                            </div>

                            <div class="field stretch">
                                <label>Stadt</label>
                                <input type="text" name="city" placeholder="Stadt" .value=${info?.city || ""} />
                            </div>
                        </div>

                        <div class="fields horizontal spacing layout">
                            <div class="field stretch">
                                <label>Telefon</label>
                                <input type="text" name="phone" placeholder="Telefon" .value=${info?.phone || ""} />
                            </div>

                            <div
                                ?hidden=${app.company.billing?.billingMode === BillingMode.Hogast}
                                class="field stretch"
                            >
                                <label>Emailadresse</label>
                                <input type="email" name="email" placeholder="Email" .value=${info?.email || ""} />
                            </div>
                        </div>

                        <div class="field">
                            <label>USt-ID</label>
                            <input type="text" name="vatId" .value=${info?.vatId || ""} />
                        </div>

                        <button class="primary" ?hidden=${!this._hasInfoChanged}>Speichern</button>
                    </form>

                    ${this._renderPaymentMethods()} ${this._renderBillingHistory()}
                </div>
            </ptc-scroller>

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
