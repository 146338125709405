import "./avatar";
import "./date-picker";
import "./spinner";
import { cache } from "lit/directives/cache.js";
import { repeat } from "lit/directives/repeat.js";
import { LitElement, html, css, nothing } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import {
    Absence,
    EmployeeStatus,
    employeeStatusColor,
    employeeStatusLabel,
    EntityFilter,
    TimeEntry,
    TimeEntryType,
    timeLogActionIcon,
    timeLogActionLabel,
    TimeLogDevice,
    TimeLogEvent,
} from "@pentacode/core/src/model";
import {
    toDateString,
    parseDateString,
    toDurationString,
    getRange,
    toTimeString,
    formatDateShort,
    formatTimeDistance,
    dateAdd,
    formatDate,
} from "@pentacode/core/src/util";
import { StateMixin } from "../mixins/state";
import { Routing, routeProperty } from "../mixins/routing";
import { app } from "../init";
import { shared } from "../styles";
import "./scroller";
import "./date-input";
import { alert, confirm } from "./alert-dialog";
import "./popover";
import { Checkbox } from "./checkbox";
import { TimeInput } from "./time-input";
import { singleton } from "../lib/singleton";
import { TimeLogDeviceDialog } from "./time-log-device-dialog";
import { matchesFilters } from "@pentacode/core/src/filters";
import { EntityFiltersEl } from "./entity-filters";
import { GetAbsencesParams, GetTimeLogEventsParams } from "@pentacode/core/src/api";
import "./qr-code";
import { isCursorInInput } from "../lib/util";
import { EmployeeDay } from "./employee-day";
import { popover } from "../directives/popover";
import "./time-log-event-details";
import { live } from "lit/directives/live.js";
import { compareVersions } from "@pentacode/core/src/version";
import { DateString } from "@pentacode/openapi";
import { getStatutoryBreak, minute } from "@pentacode/core/src/time";

function deviceTypeIcon(type?: string) {
    switch (type) {
        case "mobile":
            return "mobile";
        case "tablet":
            return "tablet";
        default:
            return "laptop";
    }
}

@customElement("ptc-time-logging")
export class TimeLogging extends Routing(StateMixin(LitElement)) {
    readonly routePattern = /^time(?:\/(?<deviceId>[^/]+))?/;

    get routeTitle() {
        return `Zeiterfassung`;
    }

    get helpPage() {
        return "/handbuch/stempeluhr/tagesuebersicht";
    }

    @state()
    private _loading = false;

    @state()
    private _timeEntries: TimeEntry[] = [];

    @state()
    private _devices: TimeLogDevice[] = [];

    @state()
    private _timeLogEvents: TimeLogEvent[] = [];

    @query("#entityFilters")
    private _entityFilters: EntityFiltersEl;

    @state()
    private _activeEntry: string | null = null;

    @state()
    private _activeField: string | null = null;

    @state()
    private _activeEmployee: number | null = null;

    @state()
    private _absences: Absence[] = [];

    @routeProperty({ param: "date", default: () => toDateString(new Date()) })
    private _date: DateString;

    @routeProperty({ arg: "deviceId" })
    private _deviceId?: string;

    @singleton("ptc-time-log-device-dialog")
    private _deviceDialog: TimeLogDeviceDialog;

    @query("ptc-employee-day")
    private _employeeDayForm: EmployeeDay;

    private _fetching = false;

    private _lastSelectedDeviceId?: string;

    private get _selectedDevice() {
        return this._devices.find((d) => d.id === this._deviceId);
    }

    private get _connectDeviceUrl() {
        return this._selectedDevice ? `${process.env.PTC_TIME_URL}?cc=${this._selectedDevice.connectionCode}` : "";
    }

    private get _filters() {
        return this._entityFilters?.filters || this._selectedDevice?.filters || [];
    }

    private get _previousDevice() {
        return this._lastSelectedDeviceId ? this._devices.find((d) => d.id === this._lastSelectedDeviceId) : undefined;
    }

    private get _filteredTimeEntries() {
        const filters = this._filters;

        return this._timeEntries
            .filter(
                (timeEntry) =>
                    app.hasAccess({ timeEntry }) &&
                    matchesFilters(filters, {
                        company: app.company!,
                        employee: app.getEmployee(timeEntry.employeeId)!,
                        position: app.getPosition(timeEntry.positionId!)?.position,
                        date: timeEntry.date,
                    }) &&
                    ((!this._selectedDevice && app.settings.timeLogShowManual) ||
                        app.getTimeSettings({ timeEntry }).trackingEnabled)
            )
            .sort(
                (a, b) =>
                    ((a.startPlanned || a.startFinal)?.getTime() || 0) -
                    ((b.startPlanned || b.startFinal)?.getTime() || 0)
            );
    }

    async handleRoute() {
        //Invalid date
        if (!this._date || !parseDateString(this._date)) {
            this.go(null, { date: toDateString(new Date()) });
            return;
        }

        void this._filtersChanged();
    }

    updated(changes: Map<string, unknown>) {
        if ((changes.has("active") && this.active) || changes.has("date")) {
            void this.synchronize(true);
        }

        if (changes.has("_selectedDevice")) {
            void this._filtersChanged();
        }
    }

    pageFocused() {
        void this.synchronize(true);
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("keydown", (e: KeyboardEvent) => {
            if (
                !this.active ||
                e.ctrlKey ||
                e.metaKey ||
                (!this._employeeDayForm?.matches(":focus-within") && isCursorInInput())
            ) {
                return;
            }
            const entries = this._filteredTimeEntries;
            const direction = { w: "up", s: "down", a: "left", d: "right" }[e.key] as "up" | "down" | "left" | "right";
            if (direction === "left") {
                this._setActive({ date: dateAdd(this._date, { days: -1 }) });
            } else if (direction === "right") {
                this._setActive({ date: dateAdd(this._date, { days: 1 }) });
            } else if (direction === "down" || direction === "up") {
                e.preventDefault();
                const index =
                    this._activeEntry && this._activeEntry !== "new"
                        ? entries.findIndex((entry) => entry.id === this._activeEntry)
                        : direction === "down"
                          ? -1
                          : entries.length;
                const entry =
                    entries[index + (direction === "down" ? 1 : -1)] ||
                    (direction === "down" ? entries[0] : entries[entries.length - 1]);
                this._setActive({ date: entry?.date, entry: entry.id || null });
            } else if (e.key === "Escape") {
                this._setActive({ date: null, entry: null }, true);
            } else if (e.shiftKey && e.key === "Backspace") {
                const activeEntry = entries.find((e) => e.id === this._activeEntry);
                if (activeEntry) {
                    void this._removeTimeEntry(activeEntry);
                }
            }
        });
    }

    private async _filtersChanged() {
        await this.updateComplete;

        const containsFilter = (arr: EntityFilter[], a: EntityFilter) =>
            arr.find((b) => a.value === b.value && a.type === b.type);

        const previousDeviceFilters = this._previousDevice?.filters || [];
        this._entityFilters.filters =
            this._selectedDevice?.filters ||
            this._entityFilters.filters.filter((filter) => !containsFilter(previousDeviceFilters, filter));

        this._lastSelectedDeviceId = this._selectedDevice?.id;

        this.requestUpdate();
    }

    async synchronize(showSpinner = false, poll = 10 * minute) {
        if (!this._date || this._fetching) {
            return;
        }

        if (showSpinner) {
            this._loading = true;
        }

        this._fetching = true;

        const filters = this._filters;
        const employeeIds = app.accessibleEmployees
            .filter((employee) => matchesFilters(filters, { employee, company: app.company!, date: this._date! }))
            .map((e) => e.id);

        try {
            const [timeEntries, timelogDevices, timeLogEvents, absences] = await Promise.all([
                app.getTimeEntries({
                    ...getRange(this._date, "day"),
                    type: [TimeEntryType.Work],
                }),
                app.api.getTimeLogDevices(),
                app.api.getTimeLogEvents(
                    new GetTimeLogEventsParams({
                        fromTime: new Date(new Date(this._date).getTime() - 12 * 60 * 60 * 1000),
                        toTime: new Date(new Date(this._date).getTime() + 36 * 60 * 60 * 1000),
                    })
                ),
                app.api.getAbsences(
                    new GetAbsencesParams({
                        from: this._date,
                        to: dateAdd(this._date, { days: 1 }),
                        employee: employeeIds,
                    })
                ),
            ]);
            this._timeEntries = timeEntries;
            this._devices = timelogDevices;
            this._timeLogEvents = timeLogEvents;
            this._absences = absences;
        } catch (e) {
            void alert(e.message, { type: "warning" });
        }

        this._fetching = false;

        if (showSpinner) {
            this._loading = false;
        }

        void this._filtersChanged();

        if (poll && this.active) {
            setTimeout(() => this.synchronize(false, poll), poll);
        }
    }

    private async _newTimeLogDevice() {
        const created = await this._deviceDialog.show(new TimeLogDevice());

        if (created) {
            await this.synchronize();
            this.go(`time/${created.id}`);
        }
    }

    private async _editDevice(device: TimeLogDevice) {
        const edited = await this._deviceDialog.show(device);

        if (edited) {
            void this.synchronize();
        }
    }

    private async _deleteDevice(device: TimeLogDevice) {
        if (
            !(await confirm(
                "Sind Sie sicher, dass Sie diese Stempeluhr löschen möchten? Die Verbindung zum verbundenen Endgerät wird automatisch getrennt.",
                "Löschen",
                "Abbrechen",
                { type: "destructive", optionsLayout: "horizontal", icon: "trash", title: "Stempeluhr Löschen" }
            ))
        ) {
            return;
        }

        await app.api.deleteTimeLogDevice(device.id);
        await this.synchronize();
    }

    private _updateTimeEntry(a: TimeEntry, data: Partial<TimeEntry> = {}) {
        if (app.isRemoved(a)) {
            return;
        }

        Object.assign(a, data);

        app.createOrUpdateTimeEntries(a);
        this.requestUpdate();
    }

    private async _removeTimeEntry(entry: TimeEntry) {
        if (
            entry.isPast &&
            (entry.startFinal || entry.endFinal) &&
            !(await confirm("Sind Sie sicher dass Sie diesen Eintrag löschen möchten?", "Löschen", "Abbrechen", {
                title: "Eintrag Löschen",
                type: "destructive",
                icon: "trash",
            }))
        ) {
            return;
        }

        if (entry.id === this._activeEntry) {
            this._setActive({ entry: null });
        }
        void app.removeTimeEntries(entry);
        this._timeEntries = this._timeEntries.filter((e) => e.id !== entry.id);
        this.requestUpdate();
    }

    private _setActiveTimeout: any;
    private _setActive(
        {
            date = this._date,
            entry = this._activeEntry,
            field = this._activeField,
        }: {
            date?: string | null;
            entry?: string | null;
            field?: string | null;
        } = {},
        instant = false,
        e?: Event
    ) {
        e && e.stopPropagation();

        if (date && date !== this._date) {
            this._activeEmployee = null;
            this._activeField = null;
            this._activeEntry = null;
            this.go(null, { date });
            return;
        }

        const innerAsyncUpdate = async () => {
            this._activeEntry = entry;
            this._activeField = field;
            this._activeEmployee = (entry && this._timeEntries.find((e) => e.id === entry)?.employeeId) || null;
            await this.updateComplete;
            const row = this.renderRoot!.querySelector(".row.selected");
            if (row) {
                try {
                    // @ts-expect-error this is a non-standard method not yet supported by firefox
                    row.scrollIntoViewIfNeeded();
                } catch (e) {
                    row.scrollIntoView({ block: "center" });
                }
            }
        };

        clearTimeout(this._setActiveTimeout);

        if (entry || instant) {
            void innerAsyncUpdate();
        } else {
            this._setActiveTimeout = setTimeout(innerAsyncUpdate, 500);
        }
    }

    static styles = [
        shared,
        Checkbox.styles,
        TimeInput.styles,
        css`
            :host {
                position: relative;
                display: flex;
            }

            .venue-selector,
            .filter-departments-form,
            .filter-input {
                margin: 0.5em 1em 0 1em;
            }

            .main {
                flex: 1;
                min-width: 0;
            }

            .scroller {
                overflow: auto;
            }

            .entry {
                border-bottom: solid 1px var(--shade-2);
            }

            .entry > :not(:first-child) {
                margin-left: 0.5em;
            }

            .entry-inner > :not(:first-child) {
                margin-left: 1em;
            }

            .entry input {
                padding: 0.3em 0.5em;
                cursor: pointer;
                text-align: center;
            }

            .entry .input:hover input:not(:focus) {
                background: var(--shade-1);
            }

            .position {
                font-size: 0.8em;
            }

            .position .pill.vacation {
                background: var(--blue-bg);
                color: var(--blue);
            }

            .position .pill.sick {
                background: var(--orange-bg);
                color: var(--orange);
            }

            .entry .start,
            .entry .end {
                width: 5em;
            }

            .entry .break {
                width: 6em;
            }

            .entry .meals {
                width: 3.5em;
                flex: none;
            }

            .entry .meals {
                padding-top: 2.15em;
                padding-bottom: 0.5em;
            }

            .planned-time {
                font-weight: 600;
                text-align: center;
                margin-bottom: 0.3em;
            }

            .employee {
                cursor: pointer;
                width: 13em;
                flex: none;
                background: var(--color-bg);
                position: sticky;
                left: 0;
                z-index: 1;
                padding: 0.5em 0.8em;
            }

            .employee:hover {
                color: var(--color-primary);
            }

            .employee-info {
                margin-left: 0.7em;
            }

            .employee-name {
                font-weight: 600;
                margin-bottom: 4px;
            }

            .warn {
                color: var(--color-negative);
            }

            .warn input:not(:focus) {
                border-color: var(--color-negative);
            }

            .planned-logged-icons {
                text-align: center;
            }

            .planned-icon {
                font-size: 0.7em;
                margin-bottom: 0.8em;
            }

            .logged-icon {
                line-height: 2em;
            }

            .delete-button {
                color: var(--color-negative);
                margin-left: 0.5em;
            }

            .department-item {
                border: solid 1px;
                color: var(--color-highlight);
                border-radius: var(--border-radius);
                margin: 0.5em 0;
                font-size: var(--font-size-small);
            }

            .header {
                border-bottom: solid 1px;
            }

            .selected {
                color: var(--color-primary);
                box-shadow: var(--color-primary) inset -0.25em 0 0 0 !important;
            }

            @keyframes blink {
                70% {
                    opacity: 0.5;
                }
            }

            .connection-online {
                animation: blink 2s infinite;
            }

            .instructions-list {
                text-indent: -4.5em;
                padding-left: 4.5em;
            }

            .instructions-list li {
                margin-bottom: 0.5em;
            }

            @media print {
                * {
                    color: black !important;
                }

                .pill {
                    border: solid 1px;
                    background: none !important;
                }

                .entry {
                    min-width: 0 !important;
                    break-inside: avoid;
                }

                input {
                    border: none !important;
                }

                .employee {
                    flex: 1 !important;
                    width: 0 !important;
                }

                .employee-info {
                    margin: 0;
                }

                .main .vertical.layout {
                    position: static !important;
                    display: block !important;
                    height: auto !important;
                }

                ptc-scroller {
                    height: auto !important;
                }
            }
        `,
    ];

    private _renderTimeLogEvent(event: TimeLogEvent) {
        const employee = app.getEmployee(event.employeeId);
        const position = event.positionId && app.getPosition(event.positionId)?.position;
        if (!employee) {
            return;
        }
        return html`
            <div
                style="border-left: dashed 1px var(--shade-3); margin: 0 1em 0 1.1em; --color-highlight: var(--blue);"
                class="hover-highlight"
                @click=${() => this._setActive({ entry: event.timeEntryId })}
                ${event.image || event.comment || event.location || event.rejectedReason
                    ? popover(html` <ptc-time-log-event-details .event=${event}></ptc-time-log-event-details> `, {
                          trigger: "hover",
                          preferAlignment: ["left", "left-bottom", "left-top"],
                      })
                    : nothing}
            >
                <div class="horizontal spacing center-aligning layout" style="margin-left: -0.8em; padding-top: 1em;">
                    <ptc-avatar .employee=${employee} class="tinier"></ptc-avatar>

                    <div class="stretch ellipsis smaller">${employee.name}</div>

                    <div class="smaller">
                        ${event.status === "rejected"
                            ? html`<i class="exclamation-triangle red colored-text"></i>`
                            : ""}
                        ${event.source === "employee_app"
                            ? html`<i class="mobile" title="Über Mitarbeiter-App erfasst"></i>`
                            : ""}
                        ${event.location ? html`<i class="location-dot" title="Standort erfasst"></i>` : ""}
                        ${event.image ? html`<i class="image-polaroid-user" title="Beweisfoto erstellt"></i>` : ""}
                        ${event.comment ? html`<i class="comment" title="Kommentar hinterlassen"></i>` : ""}
                    </div>

                    <div class="smaller subtle">
                        ${formatDateShort(event.time, true)}
                        <strong>${toTimeString(event.time)}</strong>
                    </div>
                </div>

                <div class="horizontal center-aligning spacing layout" style="padding-left: 1em;">
                    ${position
                        ? html`<div
                              class="tinier pill ellipsis"
                              style="--color-highlight: ${app.getPositionColor(position)} ; max-width: 15em;"
                          >
                              ${position.name}
                          </div>`
                        : ""}

                    <div class="stretch"></div>

                    <div class="colored-text smaller ${event.status === "rejected" ? "red line-through" : "blue"}">
                        <i class="${timeLogActionIcon(event.action)}"></i>
                        ${timeLogActionLabel(event.action)}
                    </div>
                </div>
            </div>
        `;
    }

    private _renderDevice(device: TimeLogDevice) {
        const oldVersion = !!device.appVersion && compareVersions(device.appVersion, "1.25.0") < 0;

        return html`
            <div
                class="padded horizontal center-alinging layout border-bottom click ${this._selectedDevice?.id ===
                device.id
                    ? "selected"
                    : ""}"
                @click=${() => this.go(`time/${device.id}?date=${this._date}`)}
            >
                <i class="huger ${deviceTypeIcon(device.type)}"></i>
                <div class="stretch">
                    <div class="half-margined center-aligning horizontal layout">
                        <div class="stretch collapse ellipsis">${device.description}</div>
                        ${!device.lastActive
                            ? html`
                                  <div class="smaller orange colored-text" title="noch nicht verbunden">
                                      <i class="plug-circle-plus"></i>
                                  </div>
                              `
                            : Date.now() - device.lastActive.getTime() > 20 * 60 * 1000
                              ? html`
                                    <div class="smaller red colored-text" title="offline">
                                        <i class="plug-circle-exclamation"></i>
                                    </div>
                                `
                              : html`
                                    <div class="smaller green colored-text connection-online">
                                        <i class="plug-circle-check"></i>
                                    </div>
                                `}
                    </div>
                    <ptc-entity-filters
                        readonly
                        hideFiltericon
                        hideEmployeeCount
                        class="tiny"
                        .filters=${device.filters}
                    ></ptc-entity-filters>
                    ${oldVersion
                        ? html`
                              <div class="tinier red pill half-margined">
                                  <i class="exclamation-triangle"></i> Veraltete Version
                              </div>
                          `
                        : ""}
                </div>
            </div>
        `;
    }

    private _renderDeviceHeader(device: TimeLogDevice) {
        const oldVersion = !!device.appVersion && compareVersions(device.appVersion, "1.25.0") < 0;

        return html`
            <i class="huge ${deviceTypeIcon(device.type)}"></i>
            <div class="half-padded stretch collapse">
                <div class="large">${device.description}</div>
                ${!device.lastActive
                    ? html`
                          <div class="smaller orange colored-text" title="noch nicht verbunden">
                              <i class="plug-circle-plus"></i> Nicht Verbunden
                          </div>
                      `
                    : Date.now() - device.lastActive.getTime() > 20 * 60 * 1000
                      ? html`
                            <div class="smaller red colored-text" title="offline">
                                <i class="plug-circle-exclamation"></i> Offline (zuletzt online
                                ${formatTimeDistance(device.lastActive)})
                            </div>
                        `
                      : html`
                            <div class="smaller green colored-text connection-online">
                                <i class="plug-circle-check"></i> Online
                            </div>
                        `}
                ${oldVersion
                    ? html`
                          <div>
                              <div class="smaller top-margined red padded box">
                                  <strong><i class="exclamation-triangle"></i>Achtung:</strong> Diese Stempeluhr läuft
                                  mit einer stark veralteten Version und sollte dringend geupdated werden.
                                  <span
                                      class="underlined"
                                      ${popover(
                                          html`
                                              <div class="bottom-margined">So updaten Sie ihre Stempeluhr:</div>

                                              <ol class="numbered">
                                                  <li>
                                                      <strong>Laden Sie die Seite neu</strong> (falls im Browserfenster
                                                      geladen) oder
                                                      <strong>schließen und starten Sie die App neu</strong> (falls als
                                                      App installiert).
                                                  </li>
                                                  <li>
                                                      <strong
                                                          >Nach einigen Sekunden sollte ein Update-Dialog
                                                          erscheinen</strong
                                                      >. Bitte bestätigen Sie durch Klick auf
                                                      <strong>"Jetzt Installieren"</strong>.
                                                  </li>
                                              </ol>

                                              <div class="top-margined">
                                                  Sollte kein Update-Dialog erscheinen oder Sie sonstige Hilfe
                                                  benötigen, wenden Sie sich gerne an unseren Support.
                                              </div>
                                          `,
                                          { trigger: "hover", style: "max-width: 30em" }
                                      )}
                                  >
                                      Wie geht das?
                                  </span>
                              </div>
                          </div>
                      `
                    : ""}
            </div>
            <button
                class="slim transparent"
                ${popover(
                    html`
                        <button class="transparent" @click=${() => this._editDevice(device)}>
                            <i class="pencil"></i> Bearbeiten
                        </button>
                        <button class="transparent" @click=${() => this._deleteDevice(device)}>
                            <i class="trash"></i> Löschen
                        </button>
                    `,
                    { class: "popover-menu" }
                )}
            >
                <i class="ellipsis-h"></i>
            </button>
            <ptc-checkbox-button
                buttonClass="small skinny ghost"
                .label=${html`<i class="timeline-arrow"></i>`}
                .checked=${live(app.settings.timeLogShowEvents)}
                @click=${() => app.updateSettings({ timeLogShowEvents: !app.settings.timeLogShowEvents })}
                title="Manuell erfasste Schichten anzeigen"
                ?hidden=${!app.hasPermission("manage.time.events")}
            ></ptc-checkbox-button>
        `;
    }

    render() {
        if (!this._date) {
            return html`
                <div class="fullbleed center-aligning center-justifying vertical layout scrim">
                    <ptc-spinner ?active=${this._loading}></ptc-spinner>
                </div>
            `;
        }

        const selectedDevice = this._selectedDevice;

        const entries = this._filteredTimeEntries;

        const now = new Date();

        const timeLogEvents = this._timeLogEvents.filter((event) => {
            const timeEntry = (event.timeEntryId && entries.find((entry) => entry.id === event.timeEntryId)) || null;
            const employeeId = event.employeeId || timeEntry?.employeeId;
            const employee = employeeId && app.getEmployee(employeeId);
            return (
                (!this._selectedDevice || event.deviceId === this._selectedDevice.id || timeEntry) &&
                toDateString(event.time) === this._date &&
                timeEntry &&
                app.hasAccess({ timeEntry }) &&
                (!employee || app.hasAccess({ employee }))
            );
        });

        const devices = this._devices.filter((d) =>
            app.accessibleEmployees.some((employee) => matchesFilters(d.filters, { company: app.company!, employee }))
        );
        const newDevices = devices.filter((d) => !d.legacy);

        return html`
            <div class="vertical layout noprint border-right" style="width: 18em">
                <ptc-date-picker
                    class="small padded border-bottom"
                    mode="day"
                    .value=${this._date}
                    @change=${(e: CustomEvent) => this._setActive({ date: e.detail.date })}
                ></ptc-date-picker>
                <ptc-scroller class="stretch">
                    <div
                        class="padded horizontal center-alinging layout border-bottom click ${!selectedDevice
                            ? "selected"
                            : ""}"
                        @click=${() => this.go(`time?date=${this._date}`)}
                    >
                        <i class="huger globe"></i>
                        <div class="stretch">
                            <div class="half-margined">Global</div>
                            <ptc-entity-filters
                                readonly
                                hideFiltericon
                                class="tiny"
                                .filters=${[]}
                            ></ptc-entity-filters>
                        </div>
                    </div>

                    <div class="padded subtle center-aligning horizontal layout border-bottom">
                        <div class="stretch">Stempeluhr 2.0</div>
                    </div>

                    ${newDevices.map((device) => this._renderDevice(device))}

                    <div class="double-padded text-centering click" @click=${this._newTimeLogDevice}>
                        <i class="plus"></i> Neue Stempeluhr
                    </div>
                </ptc-scroller>
            </div>

            <div class="main vertical layout">
                <div class="stretch vertical layout">
                    <div class="padded printonly border-bottom">
                        ${app.company?.name} - Zeiterfassungsübersicht vom ${formatDate(this._date)}
                    </div>
                    <div class="padded spacing center-aligning horizontal layout border-bottom noprint">
                        ${selectedDevice
                            ? this._renderDeviceHeader(selectedDevice)
                            : html`
                                  <i class="huge globe"></i>
                                  <div class="half-padded stretch collapse ellipsis">
                                      <div class="large">Global</div>
                                  </div>
                                  <ptc-checkbox-button
                                      buttonClass="small skinny ghost"
                                      .label=${html`<i class="stopwatch strike-through"></i>`}
                                      .checked=${live(app.settings.timeLogShowManual)}
                                      @click=${() =>
                                          app.updateSettings({ timeLogShowManual: !app.settings.timeLogShowManual })}
                                      title="Manuell erfasste Schichten anzeigen"
                                  ></ptc-checkbox-button>
                                  <ptc-checkbox-button
                                      buttonClass="small skinny ghost"
                                      .label=${html`<i class="timeline-arrow"></i>`}
                                      .checked=${live(app.settings.timeLogShowEvents)}
                                      @click=${() =>
                                          app.updateSettings({ timeLogShowEvents: !app.settings.timeLogShowEvents })}
                                      title="Zeiterfassungsprotokoll anzeigen"
                                      ?hidden=${!app.hasPermission("manage.time.events")}
                                  ></ptc-checkbox-button>
                              `}
                    </div>
                    <div class="horizontal layout border-bottom noprint">
                        <ptc-entity-filters
                            class="stretch"
                            .hideFilterIcon=${true}
                            .filterTypes=${["venue", "department", "position", "employeeId"] as const}
                            ?readonly=${!!selectedDevice}
                            id="entityFilters"
                            @change=${() => this.requestUpdate()}
                        ></ptc-entity-filters>
                    </div>
                    <ptc-scroller class="stretch collapse">
                        ${selectedDevice && !selectedDevice.lastActive
                            ? html`
                                  <div class="fill-vertically centering vertical layout double-padded">
                                      <div class="big orange colored-text">
                                          <i class="plug-circle-plus"></i> Nicht Verbunden
                                      </div>
                                      <div class="double-margined subtle text-centering" style="max-width: 26em">
                                          Um Zeiten mit dieser Stempeluhr zu erfassen müssen Sie zunächst ein Endgerät
                                          verbinden. Wie Sie die Stempeluhr korrekt auf dem Endgerät installieren,
                                          erfahren Sie
                                          <a
                                              href="https://pentacode.app/hilfe/stempeluhr/#stempeluhr-app-installieren"
                                              target="_blank"
                                              >hier</a
                                          >.
                                      </div>
                                      <div class="padded text" style="max-width: 26em">
                                          <ol style="text-indent: -4.5em; padding-left: 4.5em;">
                                              <li>
                                                  <strong>Schritt 1:</strong> Öffnen Sie die Photoapp oder QR-Code
                                                  Reader auf dem Endgerät, dass Sie verbinden möchten.
                                              </li>
                                              <li><strong>Schritt 2:</strong> Scannen sie folgenden QR-Code:</li>
                                          </ol>
                                      </div>
                                      <ptc-qr-code
                                          .value=${this._connectDeviceUrl || ""}
                                          class="big margined padded box"
                                      ></ptc-qr-code>
                                      <div class="divider" style="width: 14em">oder</div>
                                      <div class="padded text" style="max-width: 26em">
                                          <ol class="instructions-list">
                                              <li>
                                                  <strong>Schritt 1:</strong> Öffnen Sie den Browser auf dem gewünschten
                                                  Endgerät und navigieren Sie zu folgender URL:
                                              </li>
                                          </ol>
                                          <div class="margined centering layout">
                                              <div class="padded box">
                                                  <a href="${process.env.PTC_TIME_URL}" target="_blank"
                                                      >${process.env.PTC_TIME_URL}</a
                                                  >
                                              </div>
                                          </div>
                                          <ol class="instructions-list">
                                              <li>
                                                  <strong>Schritt 2:</strong> Sie werden nun aufgefordert, einen
                                                  Verbindungs-Code einzugeben. Dieser lautet:
                                              </li>
                                          </ol>
                                      </div>
                                      <div class="centering layout">
                                          <div class="big padded box">${selectedDevice.connectionCode}</div>
                                      </div>
                                  </div>
                              `
                            : repeat(
                                  entries,
                                  (e) => e.id,
                                  (a) => {
                                      const employee = a.employeeId && app.getEmployee(a.employeeId)!;

                                      if (!a.position || !employee) {
                                          return;
                                      }
                                      const [startPlanned, endPlanned] = a.planned || [null, null];
                                      const lateStart = startPlanned && startPlanned < now && !a.startFinal;
                                      const lateFinish = !a.endFinal && a.endPlanned && a.endPlanned < now;
                                      const statutoryBreak = getStatutoryBreak(a.durationFinal, app.company!.settings);
                                      const finished = a.startFinal && a.endFinal;
                                      const shortBreak = finished && (a.break || 0) < statutoryBreak;
                                      const currBreak =
                                          a &&
                                          a.startBreak &&
                                          Math.ceil((new Date().getTime() - a.startBreak.getTime()) / 60000);
                                      const active = a.startFinal && !a.endFinal;
                                      const duration = a.duration - (a.break || 0);

                                      return html`
                                          <div class="small center-aligning horizontal layout entry" id="entry-${a.id}">
                                              <div
                                                  class="employee horizontal center-aligning layout"
                                                  @click=${() => this.go(`employees/${employee.id}/time`)}
                                              >
                                                  <ptc-avatar
                                                      class="mediumer noprint"
                                                      .employee=${employee}
                                                      .badge=${employee.isBirthDay(this._date)
                                                          ? { icon: "birthday-cake" }
                                                          : undefined}
                                                  ></ptc-avatar>

                                                  <div class="employee-info stretch collapse">
                                                      <div class="employee-name ellipsis">${employee.name}</div>

                                                      <div class="position pills">
                                                          ${employee.status !== EmployeeStatus.Active
                                                              ? html`
                                                                    <div
                                                                        class="${employeeStatusColor(
                                                                            employee.status
                                                                        )} inverted pill"
                                                                    >
                                                                        ${employeeStatusLabel(employee.status)}
                                                                    </div>
                                                                `
                                                              : ""}
                                                          <div
                                                              class="pill"
                                                              style="--color-highlight: ${app.getTimeEntryColor(a)}"
                                                          >
                                                              ${a.position
                                                                  ? a.position.name
                                                                  : app.localized.timeEntryTypeLabel(a.type)}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                              <div class="planned-logged-icons">
                                                  <div class="planned-icon">
                                                      <i class="calendar"></i>
                                                  </div>
                                                  <div class="logged-icon">
                                                      <i class="stopwatch"></i>
                                                  </div>
                                              </div>

                                              <div class="start ${lateStart ? "warn" : ""}">
                                                  <div class="planned-time">${toTimeString(a.startPlanned) || "?"}</div>
                                                  <ptc-time-input
                                                      name="startFinal"
                                                      .value=${toTimeString(a.startFinal)}
                                                      readonly
                                                      @click=${() =>
                                                          this._setActive({
                                                              date: a.date,
                                                              entry: a.id,
                                                              field: "start",
                                                          })}
                                                  >
                                                  </ptc-time-input>
                                              </div>

                                              <div class="end ${lateFinish ? "warn" : ""}">
                                                  <div class="planned-time">${toTimeString(a.endPlanned) || "?"}</div>
                                                  <ptc-time-input
                                                      name="endFinal"
                                                      .value=${toTimeString(a.endFinal)}
                                                      ?disabled=${!a.endFinal && endPlanned && endPlanned > now}
                                                      readonly
                                                      @click=${() =>
                                                          this._setActive({
                                                              date: a.date,
                                                              entry: a.id,
                                                              field: "end",
                                                          })}
                                                  >
                                                  </ptc-time-input>
                                              </div>

                                              <div class="break ${shortBreak ? "warn" : ""}">
                                                  <div class="planned-time">
                                                      ${typeof a.breakPlanned === "number"
                                                          ? html`<i class="smaller coffee"></i> ${toDurationString(
                                                                    a.breakPlanned
                                                                )}`
                                                          : html`&nbsp;`}
                                                  </div>
                                                  <ptc-time-input
                                                      name="break"
                                                      icon="coffee"
                                                      .value=${typeof a.break === "number"
                                                          ? toDurationString(a.break)
                                                          : ""}
                                                      readonly
                                                      @click=${() =>
                                                          this._setActive({
                                                              date: a.date,
                                                              entry: a.id,
                                                              field: "break",
                                                          })}
                                                  >
                                                  </ptc-time-input>
                                                  ${shortBreak
                                                      ? html`
                                                            <ptc-popover
                                                                trigger="hover"
                                                                class="red tooltip"
                                                                non-interactive
                                                            >
                                                                Ges. Pause von
                                                                <strong>${(statutoryBreak * 60).toFixed(0)} min</strong>
                                                                unterschritten!
                                                            </ptc-popover>
                                                        `
                                                      : ""}
                                              </div>

                                              <div class="meals">
                                                  <div class="left icon input">
                                                      <i class="utensils"></i>
                                                      <input
                                                          type="number"
                                                          min="0"
                                                          max="3"
                                                          step="1"
                                                          name="meals"
                                                          .value=${(
                                                              a.mealsBreakfast +
                                                              a.mealsLunch +
                                                              a.mealsDinner
                                                          ).toString()}
                                                          readonly
                                                          @click=${() =>
                                                              this._setActive({
                                                                  date: a.date,
                                                                  entry: a.id,
                                                                  field: "meals",
                                                              })}
                                                      />
                                                  </div>
                                              </div>

                                              <div class="collapse stretch noprint"></div>

                                              <div class="status right-margined">
                                                  <div class="smaller pills">
                                                      ${cache(
                                                          lateStart
                                                              ? html` <div class="red pill">verspätet</div> `
                                                              : currBreak
                                                                ? html`
                                                                      <div class="orange pill">
                                                                          pause
                                                                          <div class="detail">${currBreak}min</div>
                                                                      </div>
                                                                  `
                                                                : lateFinish
                                                                  ? html` <div class="red pill">nicht abgem.</div> `
                                                                  : finished
                                                                    ? html`
                                                                          <div class="green pill">
                                                                              <i class="check"></i>
                                                                              <strong>${Math.floor(duration)}</strong>
                                                                              Std
                                                                              <strong
                                                                                  >${((duration % 1) * 60).toFixed(
                                                                                      0
                                                                                  )}</strong
                                                                              >
                                                                              Min
                                                                          </div>
                                                                      `
                                                                    : active
                                                                      ? html` <div class="blue pill">aktiv</div> `
                                                                      : html` <div class="pill">ausstehend</div> `
                                                      )}
                                                  </div>
                                              </div>
                                          </div>
                                      `;
                                  }
                              )}
                    </ptc-scroller>
                </div>
            </div>

            ${this._activeEmployee
                ? html`
                      <ptc-employee-day
                          style="width: 20em;"
                          class="border-left noprint"
                          .entries=${this._timeEntries}
                          .absences=${this._absences}
                          .timeLogEvents=${this._timeLogEvents}
                          .activeDate=${this._date!}
                          .activeEmployee=${this._activeEmployee}
                          .activeEntry=${this._activeEntry}
                          .activeField=${this._activeField}
                          .active=${!!this.active && !!this._date}
                          hideAvailabilities
                          hideStats
                          hideDatePicker
                          disableNewEntries
                          mode="time_logging"
                          @select=${({
                              detail: { date, entry, field, instant },
                          }: CustomEvent<{
                              date?: string;
                              entry?: string | null;
                              field?: string | null;
                              instant?: boolean;
                          }>) =>
                              this._setActive(
                                  {
                                      date,
                                      entry,
                                      field,
                                  },
                                  instant
                              )}
                          @updateentry=${(e: CustomEvent<TimeEntry>) => this._updateTimeEntry(e.detail)}
                          @close=${() => this._setActive({ date: null, entry: null, field: null }, true)}
                          @remove=${(e: CustomEvent<{ entry: TimeEntry }>) => this._removeTimeEntry(e.detail.entry)}
                      ></ptc-employee-day>
                  `
                : html`
                      <div
                          style="width: 20em;"
                          class="vertical layout border-left noprint"
                          ?hidden=${!app.hasPermission("manage.time.events") || !app.settings.timeLogShowEvents}
                      >
                          <div class="padded border-bottom"><i class="timeline-arrow"></i> Zeiterfassungsprotokoll</div>
                          <ptc-scroller class="stretch">
                              ${!timeLogEvents.length
                                  ? html`
                                        <div class="double-padded subtle">
                                            Keine erfassten Ereignisse in diesem Zeitraum
                                        </div>
                                    `
                                  : repeat(
                                        timeLogEvents,
                                        (event) => event.id,
                                        (event) => this._renderTimeLogEvent(event)
                                    )}
                          </ptc-scroller>
                      </div>
                  `}

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>
        `;
    }
}
